var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accountBuild", attrs: { id: "accountBuild" } },
    [
      _c("div", { staticClass: "addTitle" }, [_vm._v("账号信息录入")]),
      _c("div", { staticClass: "blocker" }, [
        _c("div", { staticClass: "unit" }, [
          _vm._m(0),
          _c("div", { staticClass: "texter" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.submitMode.name,
                  expression: "submitMode.name"
                }
              ],
              attrs: { type: "text", placeholder: "请输入姓名" },
              domProps: { value: _vm.submitMode.name },
              on: {
                blur: _vm.trims,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.submitMode, "name", $event.target.value)
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "unit " }, [
          _vm._m(1),
          _c("div", { staticClass: "texter" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.submitMode.mobile,
                  expression: "submitMode.mobile"
                }
              ],
              attrs: { type: "number", placeholder: "请输入手机号码" },
              domProps: { value: _vm.submitMode.mobile },
              on: {
                blur: _vm.trims,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.submitMode, "mobile", $event.target.value)
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "addTitle" }, [_vm._v("选择身份信息")]),
      _c("div", { staticClass: "blocker" }, [
        _c("div", { staticClass: "unit " }, [
          _c("div", { staticClass: "labeler w200" }, [_vm._v("是否主账号")]),
          _c(
            "div",
            { staticClass: "texter alignRight" },
            [
              _c("van-switch", {
                model: {
                  value: _vm.submitMode.master,
                  callback: function($$v) {
                    _vm.$set(_vm.submitMode, "master", $$v)
                  },
                  expression: "submitMode.master"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "btnAreaBox" }, [
        _c("div", { staticClass: "btnDiv" }, [
          _c("button", { staticClass: "btn", on: { click: _vm.submit } }, [
            _vm._v("保存")
          ])
        ])
      ]),
      _c("div", {
        staticClass: "goIndex",
        on: {
          click: function($event) {
            return _vm.$router.push("/index")
          }
        }
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", overlay: true },
          model: {
            value: _vm.timePickVis,
            callback: function($$v) {
              _vm.timePickVis = $$v
            },
            expression: "timePickVis"
          }
        },
        [
          _c("van-datetime-picker", {
            attrs: { type: "date", "min-date": _vm.minDate },
            on: {
              cancel: function($event) {
                _vm.timePickVis = false
              },
              confirm: _vm.timeConfirm
            },
            model: {
              value: _vm.currentDate,
              callback: function($$v) {
                _vm.currentDate = $$v
              },
              expression: "currentDate"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "labeler" }, [
      _vm._v("姓"),
      _c("span", [_vm._v("名")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "labeler" }, [
      _vm._v("联"),
      _c("span", [_vm._v("系")]),
      _c("span", [_vm._v("电")]),
      _c("span", [_vm._v("话")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }