<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-04-24 14:31:21
 -->
<template>
    <div class="servicer" id="service">
        <div class="header">
            <div class="headImg"><img src="../../assets/images/icon_property.png"/></div>
            <div class="textInfo">{{dataMode.name}}</div>
        </div>

        <div class="titleInfo">
            物业服务合同信息
        </div>

        <div class="block">
            <div class="unit">客户状态<span class="right">{{contractStatusEnum[dataMode.status]}}</span></div>
            <div class="unit">合同结束时间<span class="right">{{dataMode.end}}</span></div>
            <div class="unit">已交押金<span class="right">￥{{dataMode.deposit | formatMoneyThousand}}</span></div>
        </div>

        <div class="propertyBox">
            <div class="propertyInfo">使用物业</div>
            <div class="propertySwiper">
                <div class="swiper-container" id="propertySwiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item,index) in customerList" :key="index">
                            <div class="areaUnit">
                                <p class="areas">{{item.areaName | areasFormat}}-{{item.buildName}}</p>
                                <p>{{item.floor}}楼-{{item.roomName}}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="meterBox">
            <div class="meterInfo">表计清单</div>
            <div class="meterSwiper">
                <div class="swiper-container" id="meterSwiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item,index) in electricLists" :key="index">
                            <div class="slideBox">
                                <div class="slideInfo">{{meterKindEnumer[item.meterKind]}} <span class="right" v-if="item.onlineStatus">正常</span><span class="right" v-else>离线</span></div>
                                <div class="slideText">
                                    <div class="infos">
                                        <p class="number">{{item.meterAddr}}</p> 
                                    </div>
                                    <div class="searchBtnArea" v-if="item.meterKind == 1">
                                        <searchSwitch :fid="item.meterAddr"></searchSwitch>
                                    </div>
                                </div>
                                <div class="addressBox">安装地址：{{item | installAdressFormat2}}</div>
                                <div class="opeartArea">
                                    <div class="off" v-if="item.meterKind == 1" ><button @click="updateStatus(item.meterAddr,false)" :disabled="switchBtnVis">拉闸</button></div>
                                    <div class="on"  v-if="item.meterKind == 1"><button @click="updateStatus(item.meterAddr,true)" :disabled="switchBtnVis">合闸</button></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
import searchSwitch from './components/searchSwitch'
export default {
    name: "service",
    components: {searchSwitch},
    data() {
        return {
            contractStatusEnum:null,
            dataMode:{
                "customerList": [],
                "deposit": '',
                "electricLists": [],
                "end": "",
                "name": "",
                "status": ""
            },
            customerList:[],
            electricLists:[],
            meterKindEnum:null,
            meterKindEnumer:null,
            switchBtnVis:false

        };
    },
    mounted() {
        
     
    },
    created(){
        this.getMainList()
        this.contractStatusEnum = this.contractStatusEnums()
        this.meterKindEnum = this.meterKindEnums()
        this.meterKindEnumer = this.meterKindEnumers()
    },
    methods: {
        //获取主列表
        getMainList(){
            this.$store.dispatch('property/contractInfo',{}).then((result) => {
                if (result.returnCode == 0) {
                    this.dataMode = result.data
                    this.customerList = result.data.customerList
                    this.electricLists = result.data.electricLists
                    this.$nextTick(()=>{
                    })
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        //拉/合闸
        updateStatus(fid,flag){
            this.switchBtnVis = true
            this.$store.dispatch('property/updateStatus',{fid:fid,status:flag}).then((result) => {
                this.switchBtnVis = false
                if (result.returnCode == 0) {
                    this.$toast.success('已下发指令');
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        }
    }
};
</script>
