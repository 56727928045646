import * as terminal from '@/api/terminal'

const actions = {
  getMeterList({commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
      terminal.getMeterList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  addElectric({commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
      terminal.addElectric(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteElectric({commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
      terminal.deleteElectric(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  refreshElectric({commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
      terminal.refreshElectric(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getWxJsapi({commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
      terminal.getWxJsapi(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,

  actions
}
