/*
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-10 14:44:27
 * @LastEditors: yql
 * @LastEditTime: 2020-09-10 17:57:20
 */
import Vue from 'vue'

const self = Vue.prototype;
export default{
  install(Vue,options){
    Vue.prototype.isExternal = (path) => {
      return /^(https?:|mailto:|tel:)/.test(path)
    },
    Vue.prototype.validUsername = (str) => {
      const valid_map = ['admin', 'editor']
      return valid_map.indexOf(str.trim()) >= 0
    },
    Vue.prototype.validMobile = (str) => {
      const reg = /^1\d{10}$/
      return reg.test(str)
    },
    Vue.prototype.validEmail = (str) => {
      const regEmail=/^[A-Za-z0-9][A-Za-z0-9_\.]*[A-Za-z0-9]@[A-Za-z0-9]([A-Za-z0-9\-]{0,61}[A-Za-z0-9])?\.([A-Za-z0-9]([A-Za-z0-9\-]{0,61}[A-Za-z0-9])?\.){0,10}[A-Za-z]{1,4}$/;
      return regEmail.test(str)
    },
    Vue.prototype.validCardId = (str) => {
      const reg = /^(([1-9]\d{7}((0[1-9])|(1[0-2]))((0[1-9])|(1[0-9])|(2[0-9])|(3[0-1]))\d{3})|([1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))((0[1-9])|(1[0-9])|(2[0-9])|(3[0-1]))\d{3}[0-9Xx]))$/
      return reg.test(str)
    },
    
    //清除字符串两端空格
    Vue.prototype.trims = (e) =>{
        let str = e.target.value.toString()
        e.target.value = str.replace(/(^\s*)|(\s*$)/g, "");
        e.target.dispatchEvent(new Event('input'));
    },
    //月份个位数补全
    Vue.prototype.doHandleMonth = (month) =>{
      let m = month;
      if(month.toString().length == 1){
          m = "0" + month;
      }
      return m;
    },
    //输入框限值输入2位小数
    Vue.prototype.valueLimit2 = (e) =>{
      // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]).replace('-','') || null
      e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
    },
    Vue.prototype.valueLimit = (e) =>{
      e.target.value = e.target.value.replace('-','')
    },
    //输入框限值输入5位小数
    Vue.prototype.valueLimit5 = (e) =>{
      e.target.value = (e.target.value.match(/^\d*(\.?\d{0,4})/g)[0]) || null
    },
    //获取日期
    Vue.prototype.getDay = (day) =>{
          let today = new Date();
          let targetday_milliseconds=today.getTime() + 1000*60*60*24*day;

          today.setTime(targetday_milliseconds); //注意，这行是关键代码

          let tYear = today.getFullYear();

          let tMonth = today.getMonth();

          let tDate = today.getDate();

          tMonth = self.doHandleMonth(tMonth + 1);

          tDate = self.doHandleMonth(tDate);

          return tYear+"-"+tMonth+"-"+tDate;
    },
    //获取当前月
    Vue.prototype.getMonthDay = (month) =>{
        let today = new Date();

        let tYear = today.getFullYear();

        let tMonth = today.getMonth();

        tMonth = tMonth - month%12 + 1

        if(tMonth < 0 || tMonth == 0){
          tMonth = tMonth+12
        }

        tMonth = self.doHandleMonth(tMonth);

        return tYear+"-"+tMonth;
    },
    //获取日期 Thu Mar 07 2019 12:00:00 GMT+0800 (中国标准时间) ==> 2019-03-07
    Vue.prototype.dateFormat = (date) =>{
      const resDate = date.getFullYear() + '-' + self.doHandleMonth((date.getMonth() + 1)) + '-' + self.doHandleMonth(date.getDate())
      return resDate;
    },
    Vue.prototype.dateToMonthFormat = (date) =>{
      const resDate = date.getFullYear() + '-' + self.doHandleMonth((date.getMonth() + 1))
      return resDate;
    },
    Vue.prototype.listAssign = (arrA,arrB) => {
      const listAssigner = (arrA, arrB) => Object.keys(arrA).forEach(key => { if(arrB.hasOwnProperty(key)){arrA[key] = arrB[key]}});  
      //   const listAssign = (arrA, arrB) => Object.keys(arrA).forEach(key => { arrA[key] = arrB[key] || arrA[key]});  
      listAssigner(arrA,arrB);
    }

  },
  initer:function(){
    
  }
}
