<template>
    <div>
      <van-popup v-model="showDialog" round :style=" { width: '75%','max-width':'374px' }" :close-on-click-overlay="false">
        <div class="dialog-head"></div>
        <van-field
          v-model="terminalNum"
          label="终端号" disabled
        />
        <van-field
          v-model="formData.fid"
          label="电表号"
          placeholder="请输入电表号"
        >
          <template #button>
            <van-icon name="scan" @click="scanHandle"/>
          </template>
        </van-field>
        <div>
          <van-button style="width: 50%" @click="closeDialog">取消</van-button>
          <van-button  style="width: 50%" type="info" @click="saveData">确认</van-button>
        </div>
      </van-popup>
    </div>
</template>

<script>
  import {Notify, Toast} from 'vant'
    export default {
        name: "ClientAdd",
        props:['terminalNum'],
        data(){
          return {
            showDialog:false,
            formData:{
              fid:''
            }
          }
        },
      created() {

      },
      methods:{
        saveData(){
          let self=this
          this.load.$emit('loading', true)
          self.$store
            .dispatch('terminal/addElectric',{
              "fids": [this.formData.fid],
              "terminalFid": this.terminalNum
            })
            .then(res => {
              this.load.$emit('loading', true)
              if(res.returnCode==0){
                self.$emit('update')
                self.closeDialog()
                return
              }
              Notify({ type: 'warning', message: `${res.returnMsg}`})
            }).catch(res=>{
            this.load.$emit('loading', false)
            Toast.fail('网络异常！')
          })
        },
        closeDialog(){
          this.showDialog=false
          this.formData.fid=''
        },
        scanHandle(){
          let self=this
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              let tmp=res.resultStr.split(',')[1]
              self.formData.fid= tmp
            }
          });
        },

      }

    }
</script>

<style scoped>
  .dialog-head{
    height: 151px;
    background-image: url("~@/assets/images/remark_head.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
</style>
