var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "electList", attrs: { id: "electList" } }, [
    _c("div", { staticClass: "title" }, [_vm._v("预付费电表")]),
    _c(
      "div",
      { staticClass: "meterList" },
      [
        _vm._l(_vm.metersList.prepaymentList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "meterUnit",
              on: {
                click: function($event) {
                  return _vm.goRecharge(item)
                }
              }
            },
            [
              _vm._m(0, true),
              _c("div", { staticClass: "time" }, [
                _vm._v("电表：" + _vm._s(item.meterAddr) + " ")
              ]),
              _c("div", { staticClass: "address" }, [
                _vm._v(_vm._s(item.roomName))
              ]),
              _c("div", { staticClass: "valueBox" }, [
                _c("div", { staticClass: "price" }, [
                  _vm._m(1, true),
                  _c(
                    "p",
                    {
                      staticClass: "number",
                      class: { reder: item.balance < 50 }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm._f("formatMoneyThousand")(item.balance))
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "value" }, [
                  _c("p", { staticClass: "info" }, [_vm._v("当前行度")]),
                  _c("p", { staticClass: "number" }, [
                    _vm._v(_vm._s(item.currentValue))
                  ])
                ]),
                _c("div", { staticClass: "value" }, [
                  _c("p", { staticClass: "info" }, [_vm._v("本月用电")]),
                  _c("p", { staticClass: "number" }, [
                    _vm._v(_vm._s(item.monthUseValue))
                  ])
                ])
              ])
            ]
          )
        }),
        (_vm.metersList.prepaymentList &&
          _vm.metersList.prepaymentList.length == 0) ||
        _vm.metersList.prepaymentList == null
          ? _c("div", { staticClass: "noMeter" }, [
              _vm._v(" 您没有需要充值的电表! ")
            ])
          : _vm._e()
      ],
      2
    ),
    _vm.metersList.postpaymentList.length > 0
      ? _c("div", { staticClass: "title" }, [_vm._v("后付费电表")])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "meterList" },
      _vm._l(_vm.metersList.postpaymentList, function(item, index) {
        return _c("div", { key: index, staticClass: "meterUnit" }, [
          _c("div", { staticClass: "time" }, [
            _vm._v("电表：" + _vm._s(item.meterAddr) + " "),
            item.currentReadTime !== null && item.currentReadTime !== ""
              ? _c("span", [
                  _vm._v("时间:" + _vm._s(item.currentReadTime.slice(0, -3)))
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "address" }, [
            _vm._v(_vm._s(item.roomName))
          ]),
          _c("div", { staticClass: "valueBox" }, [
            _c("div", { staticClass: "price" }, [
              _vm._m(2, true),
              _c(
                "p",
                { staticClass: "number", class: { reder: item.balance < 50 } },
                [_vm._v(_vm._s(_vm._f("formatMoneyThousand")(item.balance)))]
              )
            ]),
            _c("div", { staticClass: "value" }, [
              _c("p", { staticClass: "info" }, [_vm._v("当前行度")]),
              _c("p", { staticClass: "number" }, [
                _vm._v(_vm._s(item.currentValue))
              ])
            ]),
            _c("div", { staticClass: "value" }, [
              _c("p", { staticClass: "info" }, [_vm._v("本月用电")]),
              _c("p", { staticClass: "number" }, [
                _vm._v(_vm._s(item.monthUseValue))
              ])
            ])
          ])
        ])
      }),
      0
    ),
    _c("div", {
      staticClass: "goIndex",
      on: {
        click: function($event) {
          return _vm.$router.push("/index")
        }
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chargeArea" }, [
      _vm._v("立即"),
      _c("br"),
      _vm._v("充值")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "info" }, [_c("span", [_vm._v("当前余额")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "info" }, [_c("span", [_vm._v("当前余额")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }