<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-04-29 08:58:04
 -->
<template>
    <div class="changePhone" id="changeEmail">
        <div class="formBox">

            <div class="formUnit">
                <div class="label">电子邮件</div>
                <div class="inputBox">
                    <div class="inputer "><input type="text" class="gray"  @blur="trims" placeholder="请输入电子邮件地址" v-model="submitMode.email" value="" /></div>
                </div>
            </div>

            <div class="btnAreaBox">
                <div class="btnDiv"><button class="btn" @click="submitEvent()">保存</button></div>
            </div>


        </div>




        <view-footer></view-footer>
    </div>
</template>
<script>
export default {
    name: "changeEmail",
    data() {
        return {
            submitMode:{
                "email": ""
            },
        };
    },
    mounted() {  
    },
    created(){
        this.getMainList()
    },
    methods: {
        //获取主列表
        getMainList(){
            this.load.$emit('loading', true)
            this.$store.dispatch('property/userInfo',{}).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.submitMode.email = result.data.email
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        },
        //提交
        submitEvent(){
            if(this.submitMode.email === '' || this.submitMode.email == null){
                this.$confirm({
                    mesg: "请输入邮件地址",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }else if(!this.validEmail(this.submitMode.email)){
                this.$confirm({
                    mesg: "邮件地址格式不正确",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }
            this.$store.dispatch('property/editEmail',this.submitMode).then((result) => {
                if (result.returnCode == 0) {
                    this.$toast.success('邮箱绑定成功')
                    this.$router.push('/account')
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        }
    }
};
</script>
