var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c("div", { staticClass: "mack", attrs: { id: "confirmDom" } }, [
        _c("div", {
          staticClass: "mask",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleAction("close")
            }
          }
        }),
        _c("div", { staticClass: "mackWeb", style: _vm.options.mackStyle }, [
          _vm.options.title
            ? _c(
                "div",
                { staticClass: "title", style: _vm.options.titleStyle },
                [_vm._v(_vm._s(_vm.options.title.trim()))]
              )
            : _vm._e(),
          _c("div", { staticClass: "content" }, [
            _vm.options.mesg
              ? _c(
                  "div",
                  { staticClass: "mesg", style: _vm.options.mesgStyle },
                  [_vm._v(_vm._s(_vm.options.mesg.trim()))]
                )
              : _vm._e(),
            _c("div", {
              staticClass: "cntMsg",
              domProps: { innerHTML: _vm._s(_vm.options.cntMsg) }
            })
          ]),
          _c("div", { staticClass: "footer" }, [
            _vm.options.cancel
              ? _c(
                  "div",
                  {
                    staticClass: "cancleBtn",
                    style: _vm.options.cancelValStyle,
                    on: {
                      click: function($event) {
                        return _vm.handleAction("no")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.options.btn.cancelVal))]
                )
              : _vm._e(),
            _vm.options.confirm
              ? _c(
                  "div",
                  {
                    staticClass: "sureBtn",
                    style: _vm.options.confirmValStyle,
                    on: {
                      click: function($event) {
                        return _vm.handleAction("yes")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.options.btn.confirmVal))]
                )
              : _vm._e()
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }