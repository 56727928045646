<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-05-26 15:29:17
 -->
<template>
    <div class="recharge" id="allBill">
        <!-- 账单缴费 -->
        <div class="billContent">
            <div class="billTop">
                <div class="name">{{userInfos.propName}}</div>
                <div class="address">{{userInfos | installAdressFormat}}</div>
            </div>                      
        </div>
        <div class="titleBill">全部账单</div>
        <div v-if="Object.keys(billList).length>0">
            <div class="allBillBox" v-for="(item,key) in billList" :key="key">
                <div class="year">{{key}}年</div>
                <div class="month" v-for="(bill,k) in item" :key="k" @click="billDetail(bill,key)">
                    <span style="flex:1">
                        <p>{{bill.month}} 月账单<span v-if="bill.status" class="green">已结清</span><span v-else>未结清</span></p>
                    </span>
                    <!-- <span class="money">￥{{bill.amount}}</span>                 -->
                    <span class="rightArrow"></span>
                </div>         
            </div> 
        </div> 
        <div class="noData" v-else>
            <img src="../../assets/images/noDataPic.png"/>
            <p>暂无数据</p>
        </div>   

        <div class="goIndex" @click="$router.push('/index')"></div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
    export default {
        name: "allBill",
        data() {
            return {
                billList:{},
                userInfos:{}
            };
        },
        mounted() {

        },
        computed: {
            ...mapGetters([
                'propertyName',
                'roomName'
            ]),
        },
        created() {
            this.getAllBill()
            this.getUserInfo()
        },
        methods: {
            //获取用户信息
            getUserInfo(){
                this.$store.dispatch('property/userInfo',{}).then((result) => {
                    if (result.returnCode == 0) {
                        // this.userInfos = result.data;
                        this.$set(this,'userInfos',result.data)
                    } else {
                        this.$confirm({
                            mesg: result.returnMsg,
                            cancel:false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                }).catch(error => {
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '用户信息请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
            },
            getAllBill(){
                this.load.$emit('loading', true)
                this.$store.dispatch('property/getAllBill').then((result) => {
                    this.load.$emit('loading', false)
                    if(result.returnCode == 0){
                        // this.billList = result.data.billGroup
                        this.$set(this,'billList',result.data.billGroup)
                    }
                }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
            },
            billDetail(param,year){
                this.$router.push({
                    path: '/billDetail',
                    query: {
                        period: param.period,
                        month:param.month,
                        year:year
                    }
                })
            }
            
            
            
            
            
            
        }
    };
</script>
<style lang="scss" scoped>
#allBill{
    background: #fff url(~@/assets/images/bg_3.png) no-repeat top center;
    background-size: 100% auto;
    padding-top:40px
}
.titleBill{
    font-size: 40px;
    color:#333;
    font-weight: bold;
    padding-top:56px;
}
</style>