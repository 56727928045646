<!--
 * @Descripttion: 
 * @version: 
 * @Author: zengfq
 * @Date: 2019-10-29 11:19:49
 * @LastEditors: yql
 * @LastEditTime: 2020-04-28 16:49:16
 -->
<template>
    <div class="mack" v-if="isShow" id="confirmDom" >
        <div class="mask" @click.stop="handleAction('close')"></div>
        <div class="mackWeb" :style="options.mackStyle">
            <div class="title" v-if="options.title" :style="options.titleStyle" >{{options.title.trim()}}</div>
            <div class="content">
                <div class="mesg" v-if="options.mesg" :style="options.mesgStyle">{{options.mesg.trim()}}</div>
                <div v-html="options.cntMsg" class="cntMsg"></div>
            </div>
            <div class="footer">
                <div class="cancleBtn" @click="handleAction('no')" v-if="options.cancel" :style="options.cancelValStyle" >{{options.btn.cancelVal}}</div>
                <div class="sureBtn"  @click="handleAction('yes')" v-if="options.confirm" :style="options.confirmValStyle" >{{options.btn.confirmVal}}</div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name:"confirmDom",
    data() {
        return {
            isShow: true,
            closeOnHashChange:true,
            options: {
                title: "",
                mesg: "",
                cnTmesg: "",
                cancel: true,
                confirm: true,
                mackStyle: null,
                titleStyle: null,
                mesgStyle: null,
                cancelValStyle: null,
                confirmValStyle: null,
                btn: {
                    confirmVal: "确定",
                    cancelVal: "取消"
                }
            }
        };
    },
    created(){
        document.getElementsByTagName("body")[0].classList.add("van-overflow-hidden")
        let  dom = document.getElementById("confirmDom")

        if(dom){
        dom.parentNode.removeChild(dom);
        }
    },
    watch:{
        "isShow": {
            handler: function(val, oldval) {
                if(val){
                    document.getElementsByTagName("body")[0].classList.add("van-overflow-hidden")
                }else{
                    document.getElementsByTagName("body")[0].classList.remove("van-overflow-hidden")
                }
            },
            deep: true
        },
    },
    mounted() {
      this.$nextTick(() => {
        //根据设置的closeOnHashChange参数判断是否在 hashchange 时关闭 MessageBox
        if (this.closeOnHashChange) {
          //如果需要，则在元素挂载之后，给window添加hashchange事件
          window.addEventListener('hashchange', this.close);
        }
      });
    },
    methods: {
        handleAction(action) {
            this.isShow = false;
            if(action=='yes'){
                this.ok();
            }else{
                this.close();
            }
        },
        
    },
    beforeDestroy() {
        // 组件销毁时移除hashchange事件
        if (this.closeOnHashChange) {
            window.removeEventListener('hashchange', this.close);
        }
    }
};
</script>

<style scoped lang='scss'>
@import "~@/assets/styles//common.scss";
.mack {
        z-index: 3000;
        @extend .flexBox;
         @extend .centerCenter;
         position:fixed;
        width:100%;
        height:100%;
        top:0;
        left:0;
    .mask{
        background-color: rgba($color: #000000, $alpha: 0.3);
        z-index:99;
        position:fixed;
        width:100%;
        height:100%;
        top:0;
        left:0;
    }
   
    .mackWeb{
        position: relative;
        width:600px;
        background-color: #fff;
        border-radius:20px;
        padding-top: 10px;
        z-index:100;
        .title{
            padding-top:35px;
            text-align: center;
            font-size: 35px;
            padding:20px 10px;
        }
        .content{
            padding:65px;
            padding-top:55px;
            @include borderAfter;
            .mesg{
            
                text-align: center;
                font-size: 28px;
                color:$grayColor;
                line-height: 35px;
            }
            .cntMsg{
                text-align: center;
                font-size: 28px;
                color:$grayColor;
                line-height: 35px;
            }
            

        }
        .footer{
            position: relative;
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            font-size: 32px;
            text-align: center;
            height: 104px;
            line-height: 104px;
            .cancleBtn{
                -webkit-box-flex: 1;
                -webkit-flex: 1;
                flex: 1;
                color:$wordColor;
                @include borderRight($borderColor);
            }
            .sureBtn{
                -webkit-box-flex: 1;
                -webkit-flex: 1;
                flex: 1;
                color:$blue;
                
            }
        }
        
    }
}
</style>