/*
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-13 18:49:41
 * @LastEditors  : yql
 * @LastEditTime : 2019-12-25 11:21:46
 */

import request from '@/utils/request'


//发送登录验证码
export function sendVerifyCode(data) { 
  return request({
    url: '/gateway/prop/h5/sendVerifyCode',
    method: 'post',
    data
  })
}

//登录
export function login(data) { 
  return request({
    url: '/gateway/prop/h5/login',
    method: 'post',
    data
  })
}

//微信授权
export function isWechatClient(data) { 
  return request({
    url: '/gateway/prop/wx/isWechatClient',
    method: 'post',
    data
  })
}



