<!--
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-06-04 19:36:03
 -->
<template>
    <div class="notPayBills" id="unPayBill">
        <!-- 账单缴费 -->
        <div class="billsContentBox">
            <div class="billTitle" v-if="unpaidBills.length > 0">
                未缴账单<span :class="{'active':checkAllVis}" @click="checkAll" v-if="merVis()"><i v-if="checkAllVis">取消</i>全选</span>
            </div>
            <div v-if="unpaidBills.length > 0">
                <van-checkbox-group v-model="result"  @change="checkChange">
                    <div v-for="(item,key) in unpaidBills" :key="key">

                        <div class="billerBox ">
                            <div class="mainerBox"  :class="{'radius':item.showDetail,'water':item.billKind == 2,'elect':item.billKind == 1,'gray':(item.merFid == null || item.merFid === '') || (merFid !== null && merFid !== '' && item.merFid !== merFid)}" @click.stop="toggle(key,item)">
                                <div class="maBox" :class="{'noMer':item.merFid == null || item.merFid === ''}">
                                    <div class="name">
                                        <span class="month">账单月份：{{item.period}}</span>  <!-- disabledVis(item) -->
                                        <van-checkbox shape="square" :disabled="(item.merFid == null || item.merFid === '') || (merFid !== null && merFid !== '' && item.merFid !== merFid)" :name="item" ref="checkboxes" />

                                        <!-- <van-checkbox shape="square" :name="item" ref="checkboxes" /> -->
                                    </div>
                                    <div class="amounter">
                                        {{item.name}}￥{{item.amount}}
                                        <!-- <span class="label">未结清</span> -->
                                        <div class="downIcon"  @click.stop="item.showDetail=!item.showDetail">
                                            更多详情
                                            <span :class="{up:item.showDetail}"></span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div v-if="item.showDetail" class="detailerBoxs">
                                <div class="billDetail" >
                                    <div v-if="item.billKind == 1">
                                        <span>电表号：</span>
                                        <span>{{item.meterAddr}}</span>
                                    </div>
                                    <div v-if="item.billKind == 2">
                                        <span>水表号：</span>
                                        <span>{{item.meterAddr}}</span>
                                    </div>
                                    <div v-if="item.billKind == 1 || item.billKind == 2">
                                        <span>上期行度：</span>
                                        <span>{{item.startValue}} </span>
                                    </div>
                                    <div v-if="item.billKind == 1 || item.billKind == 2">
                                        <span>本期行度：</span>
                                        <span>{{item.endValue}}</span>
                                    </div>
                                    <div v-if="item.billKind == 1">
                                        <span>用电量：</span>
                                        <span>{{item.useValue}} 度</span>
                                    </div>
                                    <div v-if="item.billKind == 2">
                                        <span>用水量：</span>
                                        <span>{{item.useValue}} 吨</span>
                                    </div>
                                    <div>
                                        <span>账单周期：</span>
                                        <span>{{item.startDate}}至{{item.endDate}}</span>
                                    </div>
                                    <!-- <div>
                                        <span>服务费：</span>
                                        <span>￥{{item.serviceAmount}}</span>
                                    </div>     -->

                                </div>
                            </div>
                        </div>
                    </div>
                </van-checkbox-group>
                <div class="moneyMer bold">
                    <span>费用合计:<i>￥{{billTotal}}</i></span>
                    <span class="red" v-if="resulter.length>0">含服务费:<i class="red">￥{{billService}}</i></span>
                </div>
                <div class="btnAreas"><button class @click="paySubmit()" :disabled="resulter.length==0">合并支付</button></div>
            </div>

            <div class="noData" v-if="!$route.query.flag && unpaidBills.length == 0">
                <img src="../../assets/images/noDataPic.png"/>
                <p>当前账单所有费用已缴清</p>
            </div>


            <div class="billTitle" v-if="$route.query.flag && paidBills.length>0">
                已缴账单
            </div>

            <div class="noPayer" v-if="$route.query.flag && paidBills.length>0">
                <div class="billClassBox" v-for="(it,index) in paidBills" :key="index">
                    <div class="billClass">
                        <div class="payNo">
                            <span>支付单号:{{it.payNo}}</span>
                        </div>
                        <div class="amountS">
                            <span>缴费金额:￥{{it.payAmount}}</span>
                            <span class="serviceFee" v-if="it.serviceAmount !== null && it.serviceAmount !== ''">(含服务费:￥{{it.serviceAmount}})</span>
                        </div>
                        <!-- <div class="payFun">
                            <span>缴费方式:{{channelTypeEmnu[it.channelType]}}</span>
                            <span>缴费时间:{{it.payTime}}</span>
                        </div> -->

                    </div>
                    <div class="paider">
                        <div class="billerBox " v-for="(item,key) in it.bills" :key="key">
                            <div class="mainerBox noBg" :class="{'radius':item.showDetail}">
                                <div class="maBox"  @click="item.showDetail=!item.showDetail">
                                    <div class="name">
                                        <span class="month">账单月份：{{item.period}}</span>
                                    </div>
                                    <div class="amounter">
                                        {{item.name}}￥{{item.amount}}
                                        <!-- <span class="label no">已结清</span> -->
                                        <div class="downIcon" >
                                            更多详情
                                            <span :class="{up:item.showDetail}"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="item.showDetail" class="detailerBoxs">
                                <div class="billDetail" >
                                    <div v-if="item.billKind == 1">
                                        <span>电表号：</span>
                                        <span>{{item.meterAddr}}</span>
                                    </div>
                                    <div v-if="item.billKind == 2">
                                        <span>水表号：</span>
                                        <span>{{item.meterAddr}}</span>
                                    </div>
                                    <div v-if="item.billKind == 1 || item.billKind == 2">
                                        <span>上期行度：</span>
                                        <span>{{item.startValue}} </span>
                                    </div>
                                    <div v-if="item.billKind == 1 || item.billKind == 2">
                                        <span>本期行度：</span>
                                        <span>{{item.endValue}}</span>
                                    </div>
                                    <div v-if="item.billKind == 1">
                                        <span>用电量：</span>
                                        <span>{{item.useValue}} 度</span>
                                    </div>
                                    <div v-if="item.billKind == 2">
                                        <span>用水量：</span>
                                        <span>{{item.useValue}} 吨</span>
                                    </div>
                                    <div>
                                        <span>账单周期：</span>
                                        <span>{{item.startDate}}至{{item.endDate}}</span>
                                    </div>

                                    <div>
                                        <span>缴费方式：</span>
                                        <span>{{channelTypeEmnu[it.channelType]}}</span>
                                    </div>
                                    <div>
                                        <span>缴费时间：</span>
                                        <span>{{it.payTime}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <button class="billBtn">立即缴费</button> -->
        </div>
        <div class="goIndex" @click="$router.push('/index')"></div>
      <div ref="form"></div>
    </div>
</template>
<script>
    import request from '@/utils/request'
    import { mapGetters } from 'vuex'
    export default {
        name: "unPayBill",
        data() {
            return {
                paidBills:[],
                unpaidBills:[],
                mers:null,
                userInfos:{},
                list: ['a', 'b'],
                result: [],
                resulter:[],
                merFid:null,
                billTotal:0,
                billService:0,
                checkAllVis:false,
                channelTypeEmnu:null,
                merSameNum:null,
                form:''
            };
        },
        computed: {
            ...mapGetters([
                'propertyName',
                'roomName'
            ]),
        },
        watch:{
            resulter: {
                immediate: true,    // 这句重要
                handler (val) {
                    this.resulter=val
                    this.mersSameNum()
                    if(this.resulter.length>0 && this.merSameNum == this.resulter.length){
                        this.checkAllVis = true
                    }
                },
                deep:true
            }
        },
        mounted() {

        },
        created() {
            this.channelTypeEmnu = this.channelTypeEmnus()
            this.getMainData()
            this.getUserInfo()
        },
        methods: {
            toggle(index,item) {
                if(item.merFid == null || item.merFid === ''){
                    return false
                }
                if(this.resulter.length >0 && item.merFid !== this.resulter[0].merFid){
                    return false
                }
                this.$refs.checkboxes[index].toggle()
                if(this.getCheckStatus(item.fid)){
                    this.checkAllVis = false
                }
            },
            //全选按钮是否显示判断
            merVis(){
                let vis = false
                if(this.mers == null) return vis
                if(this.unpaidBills.length>1){
                    if(this.mers && this.mersSameNum()){
                        vis = true
                    }else if(!this.mers){
                        vis = true
                    }
                }
                return vis
            },
            mersSameNum(){
                if(this.resulter.length==0 || this.unpaidBills.length == 0 )return false
                let num = 0
                let merNo = this.resulter[0].merFid
                this.unpaidBills.map(v => {
                    if(v.merFid ==  merNo){
                       num = num*1+1
                    }
                })
                this.merSameNum = num
                if(num > 1){
                    return true
                }else{
                    return false
                }

            },
            // 检测选框状态
            getCheckStatus(fid){
                let result = false
                for(let val of this.resulter){
                    if(val.fid == fid){
                        result = true
                        break
                    }
                }
                return result
            },
            //全选
            checkAll(){
                if(this.checkAllVis){
                    this.resulter.splice(0)
                    this.result = [...this.resulter]
                }

                this.checkAllVis = !!!this.checkAllVis

                let currMerNo = null
                if(this.checkAllVis){

                    if(this.resulter.length == 0){
                        currMerNo = this.unpaidBills[0].merFid
                    }else{
                        currMerNo = this.resulter[0].merFid
                    }
                    this.result.splice(0)

                    for(let val of this.unpaidBills){
                        if(val.merFid == currMerNo){
                            this.resulter.push(val)
                        }
                    }
                    this.result = [...this.resulter]
                }

            },
            checkChange(data){
                if(data.length == 0){
                    this.merFid = null
                    this.billService = 0
                    this.billTotal = 0
                }else if(data.length == 1){
                    this.merFid = data[0].merFid
                }
                this.resulter = data

                this.costCalculate()
            },
            // 费用计算
            costCalculate(){
                if(this.resulter.length == 0)return false
                let mode = {
                        "fids": [],
                        "type": 1,


                    }
                for(let value of this.resulter){
                    mode.fids.push(value.fid)
                }

                this.load.$emit('loading', true)
                this.$store.dispatch('property/computeAmount',mode).then((result) => {
                   debugger
                    this.load.$emit('loading', false)
                    if (result.returnCode == 0) {
                            this.billService = result.data.serviceAmount
                            this.billTotal = result.data.payAmount
                    } else {
                        this.$confirm({
                            mesg: result.returnMsg,
                            cancel:false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                }).catch(error => {
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });;

                // let total = 0,totalS=0
                // for(let val of this.resulter){
                //     if(val.amount !== null || val.amount !== ''){
                //             total += val.amount*1
                //     }
                //     if(val.serviceAmount !== null || val.serviceAmount !== ''){
                //             totalS += val.serviceAmount*1
                //     }
                // }
                // this.billTotal = (total+totalS).toFixed(2)
                // this.billService = totalS.toFixed(2)

            },
            disabledVis(item){
                let label = false
                if(item.merFid == null || item.merFid === ''){
                    label = true
                }
                if(this.merFid !== null && this.merFid !== '' && item.merFid !== this.merFid){
                  label = true
                }
                return label
            },
            //获取用户信息
            getUserInfo(){
                this.$store.dispatch('property/userInfo',{}).then((result) => {
                    if (result.returnCode == 0) {
                        this.$set(this,'userInfos',result.data)
                    } else {
                        this.$confirm({
                            mesg: result.returnMsg,
                            cancel:false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                }).catch(error => {
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '用户信息请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
            },
            getMainData(){
                this.load.$emit('loading', true)
                this.$store.dispatch('property/billDetaileds',{fid:this.$route.query.fid}).then((result) => {
                    this.load.$emit('loading', false)
                   if(result.returnCode == 0){
                        this.mers = result.data.unpaidBill.mers
                        this.paidBills = result.data.paidBills.map(v => {
                            v.bills.map(vs => {
                                this.$set(vs, 'showDetail', false)
                                return vs
                            })
                            return v

                        })

                        this.unpaidBills = result.data.unpaidBill.bills.map(v => {
                            this.$set(v, 'showDetail', false)
                            return v

                        })
                    }else{
                        this.$confirm({
                            mesg: result.returnMsg,
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }

                }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
            },
            paySubmit(){
                let self=this
                let mode = {
                    ids: [],
	                redirectUrl:''
                }

                this.resulter.forEach(el => {
                    mode.ids.push(el.fid)
                });
                if(this.$route.query.flag){
                    mode.redirectUrl =window.location.origin + '/#/notPayBills?fid='+this.$route.query.fid+'&flag='+this.$route.query.flag
                }else{
                    mode.redirectUrl =window.location.origin + '/#/notPayBills?fid='+this.$route.query.fid
                }

                this.load.$emit('loading', true)
                this.$store.dispatch('property/billPayment',mode).then((result) => {

                    this.load.$emit('loading', false)
                    if (result.returnCode == 0) {
                        if(result.data.value1){
                            this.getMainData()
                            this.$confirm({
                                mesg: '缴费成功',
                                cancel:false,
                                btn: {
                                    confirmVal: "确 定"
                                },
                            }).then(() => {
                                window.location.href = result.data.value2
                                window.location.reload()
                            })
                            .catch(() => {
                            })
                        }
                        else{
                          debugger
                          if(result.data.value0==53){
                            this.load.$emit('loading', true)
                            request({
                              url: result.data.value2,
                              method: 'get'
                            }).then(res=>{
                              this.load.$emit('loading', false)
                              if(res.returnCode!=0){
                                this.$confirm({
                                  mesg: res.returnMsg,
                                  cancel: false,
                                  btn: {
                                    confirmVal: "确 定"
                                  }
                                })
                                return
                              }
                              let data=JSON.parse(res.data.body.payinfo)
                              self.setConfig(data)
                              wx.ready(function(){
                                self.chooseWXPay(data)
                              });
                            })
                            return
                          }
                          else if(result.data.value0==50){
                            window.location.href = result.data.value2
                          }
                          else if(result.data.value0==54){
                            request({
                              url: result.data.value2,
                              method: 'get'
                            }).then(res=>{
                              let eleScript=document.createElement("script")
                              eleScript.innerHTML= res
                              self.$refs.form.append(eleScript)

                            })

                          }
                          else if(result.data.value0==56){
                            request({
                              url: result.data.value2,
                              method: 'get'
                            }).then(res=>{
                              if(res.returnCode!=0){
                                this.$confirm({
                                  mesg: res.returnMsg,
                                  cancel: false,
                                  btn: {
                                    confirmVal: "确 定"
                                  }
                                })
                                return
                              }
                              window.location.href = res.data
                            })
                          }
                          else if(result.data.value0==57){
                            this.load.$emit('loading', true)
                            request({
                              url: result.data.value2,
                              method: 'get'
                            }).then(res=>{
                              this.load.$emit('loading', false)
                              if(res.returnCode!=0){
                                this.$confirm({
                                  mesg: res.returnMsg,
                                  cancel: false,
                                  btn: {
                                    confirmVal: "确 定"
                                  }
                                })
                                return
                              }
                              let data=res.data
                              data.package=data.pkg
                              self.setConfig(data)
                              wx.ready(function(){
                                self.chooseWXPay(data)
                              });
                            })
                          }
                          else if (result.data.value0 == 92) {
                            document.write(result.data.value)
                            this.load.$emit('loading', true)
                            request({
                              url: result.data.value2,
                              method: 'get'
                            }).then(res => {
                              this.load.$emit('loading', false)
                              if (res.returnCode == 0) {
                                document.write(res.data)
                              }
                            })
                          }
                        }
                    }
                    else {
                        this.$confirm({
                            mesg: result.returnMsg,
                            cancel:false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });

            },
          setConfig(data){
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: data.appId, // 必填，公众号的唯一标识
              timestamp: data.timeStamp, // 必填，生成签名的时间戳
              nonceStr: data.nonceStr, // 必填，生成签名的随机串
              signature:  data.paySign,// 必填，签名
              jsApiList: [
                'chooseWXPay'
              ] // 必填，需要使用的JS接口列表
            });
          },
          chooseWXPay(data){
            wx.chooseWXPay({
              timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
              package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign:  data.paySign, // 支付签名
              success: function (res) {
                // 支付成功后的回调函数
                console.log(res)
              }
            })
          },
        }
    };
</script>
