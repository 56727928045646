<!--
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-05-08 16:30:08
 -->
<template>
  <div class="property" id="property">
    <!-- <div class="titleInfo">请选择登录房号</div> -->
    <div class="propertyBox">
      <div
        class="roomUnit"
        :class="{ curr: item.contractId == contract }"
        v-for="(item, index) in dataMode"
        @click="propertyEvent(item)"
        :key="index"
      >
        <!-- <div class="imgBox"><img src="../../assets/images/icon_property.png"></div> -->
        <div class="text">
          <div class="name clearfix">
            <span class="namer left">{{ item.propName }}</span>
            <span v-if="item.contractId == contract" class="current"
              >(当前房号)</span
            >
          </div>
          <div class="detailShow">
            <div
              class="detailText"
              v-for="(name, indexs) in item.rooms"
              :key="indexs"
            >
              {{ name }}<span>进入</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <view-footer></view-footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "property",
  data() {
    return {
      dataMode: []
    };
  },
  created() {
    this.getMainList();
    this.$store.dispatch("user/setToken");
    this.$store.dispatch("property/setContract");
  },
  computed: {
    ...mapGetters(["contract"])
  },
  mounted() {},
  methods: {
    propertyEvent(info) {
      this.load.$emit("loading", true);
      let self = this;
      this.localStorageSet(info).then(() => {
        self.getUserInfo();

        if (
          info.propFid == "20210723145417197039114H4ZNQ45IX" ||
          info.propFid == "20210723145533810039118M920WN4B5"
        ) {
          self.$router.push("/zfrecharge");
        } else {
          self.$router.push("/index");
        }

        self.load.$emit("loading", false);
      });
    },
    localStorageSet(info) {
      return new Promise((resolve, reject) => {
        window.localStorage.setItem("contract", info.contractId);
        this.$store.dispatch("property/setContract", info.contractId);
        resolve();
      });
    },
    // 获取用户信息
    getUserInfo() {
      this.load.$emit("loading", true);
      this.$store
        .dispatch("property/userInfo", {})
        .then(result => {
          this.load.$emit("loading", false);
          if (result.returnCode == 0) {
            this.userInfos = result.data;
            document.title = result.data.propName;
          } else {
            this.$confirm({
              mesg: result.returnMsg,
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        })
        .catch(error => {
          this.load.$emit("loading", false);
          if (error.message.includes("timeout")) {
            this.$confirm({
              mesg: "用户信息请求超时",
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        });
    },
    // 获取主列表
    getMainList() {
      this.load.$emit("loading", true);
      this.$store
        .dispatch("property/getPropInfo", {})
        .then(result => {
          this.load.$emit("loading", false);
          if (result.returnCode == 0) {
            this.dataMode = result.data;
          } else {
            this.$confirm({
              mesg: result.returnMsg,
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        })
        .catch(error => {
          this.load.$emit("loading", false);
          if (error.message.includes("timeout")) {
            this.$confirm({
              mesg: "数据请求超时",
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
#property {
  padding: 55px 22px;
  background-color: #fff;
  .roomUnit {
    background: url(~@/assets/images/roomBg2.png) no-repeat center center;
    background-size: cover;
    margin-bottom: 35px;
    border-radius: 10px;
    padding: 30px 23px 30px 34px;
    &.curr {
      background: url(~@/assets/images/roomBg1.png) no-repeat center center;
      background-size: cover;
    }
    .text {
      .name {
        font-size: 30px;
        color: #fff;
        padding-bottom: 15px;
        span.current {
          font-size: 22px;
        }
      }
    }
    .detailShow {
      font-size: 24px;
      color: #fff;
      .detailText {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          display: inline-block;
          background-color: #fff;
          color: #1a97e9;
          font-size: 20px;
          height: 40px;
          line-height: 40px;
          border-radius: 5px;
          padding: 0 16px;
        }
      }
    }
  }
}
</style>
