<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors  : yql
 * @LastEditTime : 2019-12-23 15:26:41
 -->
<template>
    <div class="historyBill" id="historyBill">
        <!-- tab -->
        <div class="tabBox">
            <div class="tabUnit">
                <span class="date" :class="{'active':pickCur}">
                    <button class="btner" @click="timePickEvent">{{searchMode.period}}</button>
                </span>
            </div>
            <div class="tabUnit">
                <span :class="{'active':!pickCur}">
                    <button class="btner" @click="billPickEvent"><span v-if="searchMode.billKind == '' || searchMode.billKind == null">全部账单</span><span v-else>{{billKindEnum[searchMode.billKind]}}</span></button>
                </span>
            </div>
        </div>

        <div class="resultBox">
            <div class="resultTotal" v-if="!NoDataVis">
                <div class="billNum">共查询到{{billNum}}张账单，</div>
                <div class="amountNum">合计金额一共￥{{totalAmount | formatMoneyThousand}}</div>
            </div>
            <!-- 账单缴费 -->
            <div class="billContent" v-if="!NoDataVis">
                <div class="billList" v-for="(item,index) in billLists" :key="index">
                    <billDetail :dataMode='item'></billDetail>
                </div>    
            </div>

            <div class="noData" v-if="NoDataVis">
                <img src="../../assets/images/noDataPic.png"/>
                <p>暂无数据</p>
            </div>


        </div>

        <!-- 选择账单 -->
        <van-popup v-model="billPickVis" position="bottom" class="popupBox">
            <div class="title"><span class="lefter" @click="billPickCancle">取消</span>选择账单类型 <span class="righter" @click="billPickSure">确定</span></div>
            <div class="content">
                <span @click="billPick()" :class="{'active':billChecked == ''}">全部账单</span>
                <span :class="{'active':item.id == billChecked}" v-for="(item,index) in billKindList" @click="billPick(item.id)" :key="index">{{item.label}}</span>
            </div>
        </van-popup>

        <!-- 选择日期 -->
        <van-popup position="bottom" v-model="timePickVis" :overlay="true" >
            <van-datetime-picker v-model="currentDate" type="year-month" :formatter="formatter" :min-date="minDate"  @cancel="timePickVis = false" @confirm="timeConfirm"/>
        </van-popup>

    </div>
</template>
<script>
import billDetail from './components/billDetail'
export default {
    name: "historyBill",
    components: {billDetail},
    data() {
        return {
            billPickVis:false,
            timePickVis:false,
            minDate: new Date(1999, 10),
            currentDate: new Date(),
            maxDate: new Date(),
            searchMode:{
                "billKind": '',
                "period": this.getMonthDay(0)
            },
            billLists:[],
            totalAmount:'',
            billNum:'',
            billKindList:null,
            billKindEnum:null,
            billChecked:'',
            NoDataVis:false,
            pickCur:true
        }
    },
    mounted() {

    },
    created(){
        this.billKindList = this.billKindLists()
        this.billKindEnum = this.billKindEnums()
        this.getMainList()
    },
    methods: {
        // 时间选择器格式化
        formatter(type, value){
            if (type === 'year') {
                return `${value}年`;
            } else if (type === 'month') {
                return `${value}月`
            }
            return value;
        },
        //获取主列表
        getMainList(){
            let newSearchMode = {...this.searchMode}
            newSearchMode.period = newSearchMode.period.replace('-','')
            this.$store.dispatch('property/billList',newSearchMode).then((result) => {
                if (result.returnCode == 0) {
                    this.billLists = result.data.list;
                    this.totalAmount = result.data.totalActualAmount;
                    this.billNum = result.data.list.length;
                    if(result.data.list.length > 0){
                        this.NoDataVis = false
                    }else{
                        this.NoDataVis = true
                    }
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        //时间获取
        timePickEvent(){
            this.pickCur = true
            this.timePickVis = true
        },
        //全部账单Event
        billPickEvent(){
            this.pickCur = false
            this.billPickVis = true
        },
        //账单类型点击Event
        billPick(id){
            if(id){
                this.billChecked = id
            }else{
                this.billChecked = ''
            }
        },
        //选择账单取消Event
        billPickCancle(){
            this.billPickVis = false
        },
        //选择账单确定Event
        billPickSure(){
            this.searchMode.billKind = this.billChecked
            this.getMainList()
            this.billPickVis = false
        },
        //时间选择确认Event
        timeConfirm(picker){
            this.searchMode.period = this.dateToMonthFormat(picker)
            this.getMainList()
            this.timePickVis = false
            
        }
    }
};
</script>
