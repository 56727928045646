<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2020-04-25 11:10:48
 * @LastEditors: yql
 * @LastEditTime: 2020-05-09 16:05:24
 -->

<template>
    <div class="electList" id="electList">
        <div class="title">预付费电表</div>
        <div class="meterList">
            <div class="meterUnit" v-for="(item,index) in metersList.prepaymentList" :key="index" @click="goRecharge(item)">
                <div class="chargeArea">立即<br/>充值</div>
                <div class="time">电表：{{item.meterAddr}} </div>
                <div class="address">{{item.roomName}}</div>
                <div class="valueBox">
                    <div class="price">
                        <p class="info" ><span>当前余额</span></p>
                        <p class="number" :class="{'reder':item.balance<50}">{{item.balance | formatMoneyThousand}}</p>
                    </div>
                    <div class="value">
                        <p class="info">当前行度</p>
                        <p class="number">{{item.currentValue}}</p>
                        
                    </div>
                    <div class="value">
                        <p class="info">本月用电</p>
                        <p class="number">{{item.monthUseValue}}</p>
                    </div>
                </div>
            </div>

            <div class="noMeter" v-if="metersList.prepaymentList && metersList.prepaymentList.length == 0 || metersList.prepaymentList == null">
            <!-- <div class="noMeter"> -->
                您没有需要充值的电表!
            </div>

        </div>

        <div class="title" v-if="metersList.postpaymentList.length>0">后付费电表</div>
        <div class="meterList">
            <div class="meterUnit" v-for="(item,index) in metersList.postpaymentList" :key="index" >
                <!-- <div class="chargeArea">立即<br/>充值</div> -->
                <div class="time">电表：{{item.meterAddr}} <span v-if="item.currentReadTime !== null && item.currentReadTime !== ''">时间:{{item.currentReadTime.slice(0, -3)}}</span></div>
                <div class="address">{{item.roomName}}</div>
                <div class="valueBox">
                    <div class="price">
                        <p class="info" ><span>当前余额</span></p>
                        <p class="number" :class="{'reder':item.balance<50}">{{item.balance | formatMoneyThousand}}</p>
                    </div>
                    <div class="value">
                        <p class="info">当前行度</p>
                        <p class="number">{{item.currentValue}}</p>
                        
                    </div>
                    <div class="value">
                        <p class="info">本月用电</p>
                        <p class="number">{{item.monthUseValue}}</p>
                    </div>
                </div>
            </div>


        </div>

        <div class="goIndex" @click="$router.push('/index')"></div>
    </div>
</template>
<script>
    export default {
        name: "electList",
        components: {  },
        data() {
            return {
                metersList:{
                    postpaymentList:[],
                    
                }
            };
        },
        mounted() {

        },
        created() {
            this.getMeterList()
        },
        methods: {
            goRecharge(item){
                this.$router.push({
                    path:'/electRecharge',
                    query:{
                        meterAddr:item.meterAddr,
                        date:item.currentReadTime,
                        amount:item.balance,
                        maxRecharge:this.metersList.maxRecharge,
                        minRecharge:this.metersList.minRecharge
                    }
                })
            },
            //获取电表列表
            getMeterList(){
                this.load.$emit('loading', true)
                this.$store.dispatch('meter/rechargeMeters',{}).then((result) => {
                        this.load.$emit('loading', false)
                        if (result.returnCode == 0) {
                            this.$set(this,'metersList',result.data)
                            if(result.data.prepaymentList.length == 1){
                                this.$router.push({
                                    path:'/electRecharge',
                                    query:{
                                        meterAddr:result.data.prepaymentList[0].meterAddr,
                                        date:result.data.prepaymentList[0].currentReadTime,
                                        amount:result.data.prepaymentList[0].balance,
                                        maxRecharge:result.data.maxRecharge,
                                        minRecharge:result.data.minRecharge
                                    }
                                })
                            }
                        } else {
                            this.$confirm({
                                mesg: result.returnMsg,
                                cancel: false,
                                btn: {
                                    confirmVal: "确 定"
                                }
                            })
                        }
                    }).catch(error => {
                        if(error.message.includes('timeout')){
                            this.$confirm({
                                mesg: '表计列表请求超时',
                                cancel: false,
                                btn: {
                                    confirmVal: "确 定"
                                }
                            })
                        }
                    });
            },
        }
    };
</script>
