/*
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-04 17:27:36
 * @LastEditors: yql
 * @LastEditTime: 2019-12-13 19:16:16
 */
import Vue from 'vue'
import Vuex from 'vuex'
// import state from './state'
// import actions from './actions'
// import mutations from './mutations'
import getters from './getters'

Vue.use(Vuex)



const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  getters,
  // state,
  // mutations,
  // actions,
  modules
})
