var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "property", attrs: { id: "property" } },
    [
      _c(
        "div",
        { staticClass: "propertyBox" },
        _vm._l(_vm.dataMode, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "roomUnit",
              class: { curr: item.contractId == _vm.contract },
              on: {
                click: function($event) {
                  return _vm.propertyEvent(item)
                }
              }
            },
            [
              _c("div", { staticClass: "text" }, [
                _c("div", { staticClass: "name clearfix" }, [
                  _c("span", { staticClass: "namer left" }, [
                    _vm._v(_vm._s(item.propName))
                  ]),
                  item.contractId == _vm.contract
                    ? _c("span", { staticClass: "current" }, [
                        _vm._v("(当前房号)")
                      ])
                    : _vm._e()
                ]),
                _c(
                  "div",
                  { staticClass: "detailShow" },
                  _vm._l(item.rooms, function(name, indexs) {
                    return _c(
                      "div",
                      { key: indexs, staticClass: "detailText" },
                      [_vm._v(" " + _vm._s(name)), _c("span", [_vm._v("进入")])]
                    )
                  }),
                  0
                )
              ])
            ]
          )
        }),
        0
      ),
      _c("view-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }