<!--
 * @Descripttion:
 * @version:
 * @Author: zengfq
 * @Date: 2019-10-29 11:19:49
 * @LastEditors: yql
 * @LastEditTime: 2020-04-30 15:14:47
 -->
<template>
    <div class="footer" id="footers">
        技术支持：广东浩迪创新科技有限公司 </br>@{{dataTime}}
    </div>
</template>

<script>
  import m from "moment"
export default {
    name: "footers",
    data() {
        return {
          dataTime:m().year()
        }
    },
    created() {}
};
</script>
<style lang="scss" scoped>

</style>
