<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2020-09-09 11:41:06
 * @LastEditors: yql
 * @LastEditTime: 2020-09-17 17:49:40
-->

<template>
    <div class="messagePackage" id="messagePackage">
        <div class="cardTypeBox">
            <div class="cardType">
                <div class="cardName">
                    <span class="imgBox"><img src="../../assets/images/card_name.png" /></span>
                </div>
                <div class="cardEffectTime">
                    <span class="timer"></span>  <!-- 有效期至：2020.09.11 -->
                    <span class="record" @click="recordEv">购买记录</span>
                </div>
                
            </div>
        </div>

        <div class="cardSelect">
            <div class="cardUnit" :class="{'active':orderMode.fid == item.fid}" v-for="(item,index) in list" :key="index" @click="selectEv(item)">
                <div class="discount">
                    <span>{{item.discount}}折</span>
                </div>
                <div class="cardNamer">{{item.name}}</div>
                <div class="priceS">
                    <span>￥</span>{{item.amount}}
                </div>
                <div class="priceOriginal">￥{{item.totalAmount}}</div>
                <div class="priceSave">立省{{item.discountAmount}}元</div>
            </div>

 
            <!-- <div class="cardUnit">
                <div class="discount">
                    <span>8.8折</span>
                </div>
                <div class="cardNamer">SVIP季卡</div>
                <div class="priceS">
                    <span>￥</span>50
                </div>
                <div class="priceOriginal">￥15</div>
                <div class="priceSave">立省3元</div>
            </div>
            <div class="cardUnit">
                <div class="discount">
                    <span>8.8折</span>
                </div>
                <div class="cardNamer">SVIP季卡</div>
                <div class="priceS">
                    <span>￥</span>50
                </div>
                <div class="priceOriginal">￥15</div>
                <div class="priceSave">立省3元</div>
            </div>

            <div class="cardUnit">
                <div class="discount">
                    <span>8.8折</span>
                </div>
                <div class="cardNamer">SVIP季卡</div>
                <div class="priceS">
                    <span>￥</span>50
                </div>
                <div class="priceOriginal">￥15</div>
                <div class="priceSave">立省3元</div>
            </div>
            <div class="cardUnit">
                <div class="discount">
                    <span>8.8折</span>
                </div>
                <div class="cardNamer">SVIP季卡</div>
                <div class="priceS">
                    <span>￥</span>50
                </div>
                <div class="priceOriginal">￥15</div>
                <div class="priceSave">立省3元</div>
            </div> -->


        </div>

        <div class="agreement">
            <van-checkbox v-model="checked" checked-color="#0893ea">已阅读并同意</van-checkbox><span class="deal" @click="scanAgreementEv">《无忧短信卡服务协议》</span>
        </div>


        <div class="btnArea">
            <button class="btn" @click="buyEv" :disabled="orderMode.fid == null || orderMode.fid === '' || !checked">立即购买</button>
        </div>

        <div class="goIndex" @click="$router.push('/index')"></div>
        
    </div>
</template>
<script>
export default {
    name: "messagePackage",
    data() {
        return {
            checked:false,
            list:[],
            orderMode:{
                "fid": "",
                "redirectUrl": window.location.origin + '/#/message'
            }
        };
    },
    mounted() {  
    },
    created(){
        this.getList()
    },
    methods: {
        selectEv(item){
            this.orderMode.fid = item.fid
        },
        getList(){
            this.load.$emit('loading', true)
            this.$store.dispatch('property/packageList',{}).then((result) => {
                this.list = result.data
                this.load.$emit('loading', false)
            }).catch(error => {
                this.load.$emit('loading', false)
                if(error.message.includes('timeout')){
                    this.$confirm({
                        mesg: '数据请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });

        },
        buyEv(){
            this.load.$emit('loading', true)
                this.$store.dispatch('property/smsPay',this.orderMode).then((result) => {
                    this.load.$emit('loading', false)
                    if (result.returnCode == 0) {
                        // if(result.data.key){
                        //     this.$confirm({
                        //         mesg: '购买成功',
                        //         cancel:false,
                        //         btn: {
                        //             confirmVal: "确 定"
                        //         },
                        //     }).then(() => {
                        //         window.location.href = result.data.value
                        //         window.location.reload()
                        //     })
                        //     .catch(() => {
                        //         console.log("no");
                        //     });
                        // }else{
                            window.location.href = result.data
                        // }
                        
                       
                        
                    } else {
                        this.$confirm({
                            mesg: result.returnMsg,
                            cancel:false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        },
        recordEv(){
            this.$router.push({
                path:'/orderRecord',
                query:{
                    // meterAddr:item.meterAddr
                }
            })
        },
        scanAgreementEv(){
            this.$router.push({
                path:'/agreement'
            })
        }
    }
};
</script>
<style  scoped>
    /deep/ .van-checkbox__icon .van-icon{
        width:25px;
        height:25px;
    }
    /deep/ .van-checkbox__label{
        line-height: 30px;
    }
</style>
