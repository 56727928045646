<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-05-08 12:07:10
 -->
<template>
    <div class="changeAccount" id="changeAccount">
        <div v-if="!listNoData">
            <div class="titleInfos">主账号</div>
            <div class="accountBoxer">
                <div class="accountUnit" v-for="(item,index) in tenantLists.list" :key="index" v-show="item.master">
                    <div class="imgBox"><img src="../../assets/images/img_default2.png" v-if="item.head ==null || item.head == ''"><img :src="item.head" v-else></div>
                    <div class="textBox">
                        <h4>{{item.name}}</h4>
                        <p>{{item.mobile}}</p>
                    </div>
                    <div class="iconBox" v-if="tenantLists.master">
                        <!-- <span @click="transferEvent"><img src="../../assets/images/icon_edit.png"></span> -->
                        <span @click="delAccountEvent(item.tenantFid)"><img src="../../assets/images/icon_del.png"></span>
                    </div>
                </div>
            </div>

            <div class="titleInfos">其他账号</div>
            <div class="accountBoxer">
                <div class="accountUnit"  v-for="(item,index) in tenantLists.list" :key="index" v-show="!item.master">
                    <div class="imgBox"><img src="../../assets/images/img_default2.png" v-if="item.head ==null || item.head == ''"><img :src="item.head" v-else></div>
                    <div class="textBox">
                        <h4>{{item.name}}</h4>
                        <p>{{item.mobile}}</p>
                    </div>
                    <div class="iconBox" v-if="tenantLists.master">
                        <!-- <span><img src="../../assets/images/icon_edit.png"></span> -->
                        <span  @click="delAccountEvent(item.tenantFid)"><img src="../../assets/images/icon_del.png"></span>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="noData" v-if="listNoData">
            <img src="../../assets/images/noDataPic.png"/>
            <p>暂无数据</p>
        </div>

        <div class="btnAreaBox">
            <div class="btnDiv"><button class="btn" @click="addNewAccount">添加新账号</button></div>
        </div>

        <div class="goIndex" @click="$router.push('/index')"></div>
        <view-footer></view-footer>
    </div>
</template>
<script>
export default {
    name: "changeAccount",
    data() {
        return {
            tenantLists:[],
            listNoData:null,
            delFid:null
        };
    },
    mounted() {  
    },
    created(){
        this.getMainList()
    },
    methods: {
        //获取主列表
        getMainList(){
            this.load.$emit('loading', true)
            this.$store.dispatch('property/listTenant',{}).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    if(result.data.list.length > 0){
                        this.listNoData = false
                    }else{
                        this.listNoData = true
                    }
                    this.tenantLists = result.data;
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        },
        //删除租户
        delAccountEvent(fid){
            let self = this
            this.$confirm({
                mesg: "确定删除此账号吗？删除后该账号将不能管理此物业。",
                btn: {
                    confirmVal: "确 定",
                    cancelVal:"取 消"
                }
            }).then(() => {
                self.deleteTenant(fid)
            })
            .catch(() => {
                console.log("no");
            });
        },
        //删除租户
        deleteTenant(fid){
            let moder = []
                moder.push(fid)
            this.$store.dispatch('property/deleteTenant',{fids:moder}).then((result) => {
                if (result.returnCode == 0) {
                    this.$toast.success('删除成功')
                    this.getMainList()
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        },
        addNewAccount(){
            this.$router.push('/accountBuild')
        },
        transferEvent(){
            this.$router.push('/accountTransfer')
        }
    }
};
</script>
