/*
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-13 18:49:41
 * @LastEditors: yql
 * @LastEditTime: 2020-09-10 13:54:32
 */

import request from '@/utils/request'

//获取物业项目名称
export function propName(data) {
  return request({
    url: '/gateway/prop/h5/propName',
    method: 'post',
    data
  })
}

//获取用户入驻信息
export function getPropInfo(data) {
  return request({
    url: '/gateway/prop/h5/getPropInfo',
    method: 'post',
    data
  })
}

//获取用户信息
export function userInfo(data) {
  return request({
    url: '/gateway/prop/h5/userInfo',
    method: 'post',
    data
  })
}

// 获取本日告警总数与最新1条数据
export function electAlarmToday(data) {
  return request({
    url: '/gateway/prop/h5/electric/alarmToday',
    method: 'post',
    data
  })
}

// 获取客户本月账单类型缴金额汇总
export function billMonthPay(data) {
  return request({
    url: '/gateway/prop/h5/bill/monthPay',
    method: 'post',
    data
  })
}

//获取合同后付费/预付费电表当天数据
export function electToday(data) {
  return request({
    url: '/gateway/prop/h5/electric/today',
    method: 'post',
    data
  })
}

//实时获取充值电表数据
export function currentPrepayment(data) {
  return request({
    url: '/gateway/prop/h5/electric/currentPrepayment',
    method: 'post',
    data
  })
}

//获取充值电表列表
export function electPrepayment(data) {
  return request({
    url: '/gateway/prop/h5/electric/prepayment',
    method: 'post',
    data
  })
}

//账单缴费列表
export function billPayList(data) {
  return request({
    url: '/gateway/prop/h5/bill/payList',
    method: 'post',
    data
  })
}

//告警查询
export function electAlarmList(data) {
  return request({
    url: '/gateway/prop/h5/electric/alarmList',
    method: 'post',
    data
  })
}

//告警查询
export function billList(data) {
  return request({
    url: '/gateway/prop/h5/bill/list',
    method: 'post',
    data
  })
}

//我的服务
export function contractInfo(data) {
  return request({
    url: '/gateway/prop/h5/contract/info',
    method: 'post',
    data
  })
}

//拉/合闸
export function updateStatus(data) {
  return request({
    url: '/gateway/prop/h5/switchstatus/updateStatus',
    method: 'post',
    data
  })
}

//闸查询
export function switchstatusQuery(data) {
  return request({
    url: '/gateway/prop/h5/switchstatus/query',
    method: 'post',
    data
  })
}

//获取合同全部园区
export function getAreaList(data) {
  return request({
    url: '/gateway/prop/h5/getAreaList',
    method: 'post',
    data
  })
}

//获取合同园区下的建筑
export function getBuildingList(data) {
  return request({
    url: '/gateway/prop/h5/getBuildingList',
    method: 'post',
    data
  })
}

//获取合同园区下的单元
export function getRoomList(data) {
  return request({
    url: '/gateway/prop/h5/getRoomList',
    method: 'post',
    data
  })
}

//获取客户租客列表
export function listTenant(data) {
  return request({
    url: '/gateway/prop/h5/listTenant',
    method: 'post',
    data
  })
}

//删除租户账号
export function deleteTenant(data) {
  return request({
    url: '/gateway/prop/h5/deleteTenant',
    method: 'post',
    data
  })
}
//增加租户账号
export function addTenant(data) {
  return request({
    url: '/gateway/prop/h5/addTenant',
    method: 'post',
    data
  })
}
// 旧手机号发送验证码
export function oldMobileSend(data) {
  return request({
    url: '/gateway/prop/h5/oldMobileSend',
    method: 'post',
    data
  })
}
// 发送手机验证码
export function newMobileSend(data) {
  return request({
    url: '/gateway/prop/h5/newMobileSend',
    method: 'post',
    data
  })
}
// 验证手机短信验证码
export function verifyCode(data) {
  return request({
    url: '/gateway/prop/h5/verifyCode',
    method: 'post',
    data
  })
}
// 改绑手机号
export function editMobile(data) {
  return request({
    url: '/gateway/prop/h5/editMobile',
    method: 'post',
    data
  })
}
//修改邮箱
export function editEmail(data) {
  return request({
    url: '/gateway/prop/h5/editEmail',
    method: 'post',
    data
  })
}
//修改用户名
export function editName(data) {
  return request({
    url: '/gateway/prop/h5/editName',
    method: 'post',
    data
  })
}
//计算账单支付/电表充值金额
export function computeAmount(data) {
  return request({
    url: '/gateway/prop/h5/computeAmount',
    method: 'post',
    data
  })
}
//电费充值
export function electricRecharge(data) {
  return request({
    url: '/gateway/prop/h5/electricRecharge',
    method: 'post',
    data
  })
}
//账单缴费
export function billPay(data) {
  return request({
    url: '/gateway/prop/h5/billPay',
    method: 'post',

    data
  })
}
//多商户账单缴费列表
export function payMerList(data) {
  return request({
    url: '/gateway/prop/h5/bill/payMerList',
    method: 'post',

    data
  })
}
//线下支付登记
export function offlinePay(data) {
  return request({
    url: '/gateway/prop/h5/offlinePay',
    method: 'post',

    data
  })
}
//线下支付查询
export function offlinePayInfo(data) {
  return request({
    url: '/gateway/prop/h5/queryOfflinePay',
    method: 'post',
    data
  })
}
//获取电表年数据
export function getYearData(data) {
  return request({
    url: '/gateway/prop/h5/electric/getYearData',
    method: 'post',
    data
  })
}
//获取电表月数据
export function getMonthData(data) {
  return request({
    url: '/gateway/prop/h5/electric/getMonthData',
    method: 'post',
    data
  })
}
//获取全部账单
export function getAllBill(data) {
  return request({
    url: '/gateway/prop/h5/billInfo',
    method: 'post',
    data
  })
}
//账单详情
export function getBillInfo(data) {
  return request({
    url: '/gateway/prop/h5/monthBills',
    method: 'post',
    data
  })
}
//账单支付
export function billPayment(data) {
  return request({
    url: '/gateway/prop/h5/billPayment',
    method: 'post',
    data
  })
}
//获取当前告警列表
export function getCurrentAlarm(data) {
  return request({
    url: '/gateway/prop/h5/electric/getCurrentAlarm',
    method: 'post',
    data
  })
}
//获取告警历史列表
export function getAlarmHistory(data) {
  return request({
    url: '/gateway/prop/h5/electric/getAlarmHistory',
    method: 'post',
    data
  })
}

//充值记录
export function rechargeRecord(data) {
  return request({
    url: '/gateway/prop/h5/rechargeRecord',
    method: 'post',
    data
  })
}

//账单明细
export function billDetaileds(data) {
  return request({
    url: '/gateway/prop/h5/billDetaileds',
    method: 'post',
    data
  })
}

//修改用户短信配置
export function editUserSmsConfig(data) {
  return request({
    url: '/gateway/prop/h5/sms/editUserSmsConfig',
    method: 'post',
    data
  })
}

//获取短信购买套列表
export function packageList(data) {
  return request({
    url: '/gateway/prop/h5/sms/packageList',
    method: 'post',
    data
  })
}

//获取用户购买短信套餐记录
export function getUserSmsOrder(data) {
  return request({
    url: '/gateway/prop/h5/sms/getUserSmsOrder',
    method: 'post',
    data
  })
}

//获取用户短信配置
export function getUserSmsConfig(data) {
  return request({
    url: '/gateway/prop/h5/sms/getUserSmsConfig',
    method: 'post',
    data
  })
}

//短息购买
export function smsPay(data) {
  return request({
    url: '/gateway/prop/h5/sms/pay',
    method: 'post',
    data
  })
}
