/*
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-13 18:49:41
 * @LastEditors: yql
 * @LastEditTime: 2021-01-27 11:00:20
 */

import request from '@/utils/request'

//获取所有表计列表
export function getMeterList(data) {
  return request({
    url: '/gateway/prop/h5/meters',
    method: 'post',
    data
  })
}

//首页为出账账单统计
export function unpaidBill(data) {
  return request({
    url: '/gateway/prop/h5/unpaidBill',
    method: 'post',
    data
  })
}

//获取水表年数据
export function getYearData(data) {
  return request({
    url: '/gateway/prop/h5/water/getYearData',
    method: 'post',
    data
  })
}

//获取水表月数据
export function getMonthData(data) {
  return request({
    url: '/gateway/prop/h5/water/getMonthData',
    method: 'post',
    data
  })
}

//刷新电表余额
export function refreshBalance(data) {
  return request({
    url: '/gateway/prop/h5/refreshBalance',
    method: 'post',
    data
  })
}

//电表充值列表
export function rechargeMeters(data) {
  return request({
    url: '/gateway/prop/h5/rechargeMeters',
    method: 'post',
    data
  })
}

//电量报表
export function monthReport(data) {
    return request({
      url: '/gateway/prop/h5/monthReport',
      method: 'post',
      data
    })
  }

//电表充值页信息
export function electricRechargeInfo(data) {
  return request({
    url: '/gateway/prop/h5/electricRechargeInfo',
    method: 'post',
    data
  })
}

//查看账单pdf
export function billPdf(data) {
  return request({
    url: '/gateway/prop/h5/billPdf',
    method: 'GET',
    responseType: 'blob',
    params:data
  })
}
