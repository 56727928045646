import { render, staticRenderFns } from "./orderRecord.vue?vue&type=template&id=70b2058d&scoped=true&"
import script from "./orderRecord.vue?vue&type=script&lang=js&"
export * from "./orderRecord.vue?vue&type=script&lang=js&"
import style0 from "./orderRecord.vue?vue&type=style&index=0&id=70b2058d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b2058d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\hodi_work\\prop-h5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70b2058d')) {
      api.createRecord('70b2058d', component.options)
    } else {
      api.reload('70b2058d', component.options)
    }
    module.hot.accept("./orderRecord.vue?vue&type=template&id=70b2058d&scoped=true&", function () {
      api.rerender('70b2058d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/message/orderRecord.vue"
export default component.exports