<!--
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2020-04-26 11:04:32
 * @LastEditors: yql
 * @LastEditTime: 2020-09-14 16:24:08
 -->

<template>
    <div class="index" id="index" v-wechat-title="$route.meta.title=userInfos.propName">
      <template v-if="userInfos.propName">

        <!-- 物业信息 -->
        <div class="propertyBox">
            <div class="projectName" >
                <div class="headImg" @click="accountEvent">
                    <img src="../../assets/images/img_default1.png" v-if="userInfos.head ==null || userInfos.head == ''" />
                    <img :src="userInfos.head" v-else>
                    <p style="max-width: 10em;
                    word-break: break-word;">{{userInfos.name}}</p>
                </div>
                <div class="headInfo">
                    <h3>{{userInfos.propName}}</h3>
                    <p>{{userInfos | installAdressFormat}}</p>
                </div>

            </div>
            <div class="selectProperty" @click="switchProperty">切换房号</div>
        </div>

        <!-- icon 菜单 -->
        <div class="navBox">
            <div class="unitBox charge" @click="chargeEvent">
                <div class="imgBox">
                </div>
                <p>账单缴费</p>
            </div>

            <div class="unitBox bill" @click="historyBillEvent">
                <div class="imgBox">
                </div>
                <p>充值记录</p>
            </div>
            <div class="unitBox alarm" @click="alarmEvent">
                <div class="imgBox">
                    <span v-if="alarmLength >0">{{alarmLength}}</span>
                </div>
                <p>告警记录</p>
            </div>
            <div class="unitBox chart" @click="$router.push('/chart')">
                <div class="imgBox">
                </div>
                <p>用电情况</p>
            </div>
            <!-- <div class="unitBox service" @click="accountEvent">
                <div class="imgBox">
                </div>
                <p>用户管理</p>
            </div> -->
            <div class="unitBox message" @click="messageEvent">
                <div class="imgBox">
                </div>
                <p>短信设置</p>
            </div>
        </div>

        <!-- alarm 信息 -->
        <div class="alarmBox" v-if="alarmList.length>0" @click="alarmEvent" :class="{'marquee_top':animate}">
            <div class="marqueeBox">
                <ul class="marquee_list" :class="{'marquee_top':animate}">
                    <li v-for="item in alarmList"  @mouseover="stop" @mouseout="start" :key="item.index">
                        {{item.happenTime.slice(0, -3)}}<span class="inline">{{item.alarmName}}</span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="titleInfo"><span>表计列表</span></div>
        <div class="meterTab">
            <span :class="{'active':meterElectIs}" @click="meterElectIs = true">当前电表</span>
            <span :class="{'active':!meterElectIs}" @click="meterElectIs = false">当前水表</span>

        </div>
        <div class="meterListBox" v-if="meterElectIs">
                <div v-if="metersList.electricMeters.length>0">
                    <div class="swiper-slider" v-for="(item,index) in metersList.electricMeters" :key="index" >
                        <div class="time">电表：{{item.meterAddr}} <span v-if="item.currentReadTime !== null && item.currentReadTime !== ''">时间:{{item.currentReadTime.slice(0,-3)}}</span></div>
                        <div class="address">{{item.roomName}}</div>
                        <div class="valueBox">
                            <div class="price">
                                <p class="info" ><span>当前余额</span></p>
                                <p class="number" :class="{'reder':item.balance<item.balanceNotEnough}" v-if="item.chargeKind == 1">{{item.balance | formatMoneyThousand}}</p>
                                <p class="number" v-else>--</p>
                            </div>
                            <div class="value">
                                <p class="info">当前行度</p>
                                <p class="number">{{item.currentValue}}</p>

                            </div>
                            <div class="value">
                                <p class="info">本月用电</p>
                                <p class="number">{{item.monthUseValue}}</p>

                            </div>
                        </div>

                        <div class="btnArea">
                            <div class="btner" @click="electDetail(item)">
                                <span>查看详情 </span>
                            </div>
                            <div class="btner yellow" @click="chartEvent(item)">
                                <span>用电情况 </span>
                            </div>
                            <div class="btner green" v-if="item.chargeKind == 1" @click="electRecharge(item)">
                                <span class="green" >立即充值 </span>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="noMeter" v-else>
                    当前房间无电表!
                </div>

        </div>

        <div class="meterListBox" v-else>

                <div class="swiper-slider" v-for="(item,index) in metersList.waterMeters" :key="index">
                    <div class="time">水表：{{item.meterAddr}}<span v-if="item.currentReadTime !== null">时间:{{item.currentReadTime.slice(0, -3)}}</span></div>
                    <div class="address">{{item.roomName}}</div>
                    <div class="valueBox">

                        <div class="price">
                            <p class="info">当前行度</p>
                            <p class="number">{{item.currentValue}}</p>

                        </div>
                        <div class="value">
                            <p class="info">本月用水</p>
                            <p class="number">{{item.monthUseValue}}</p>
                        </div>

                    </div>

                    <div class="btnArea">
                        <div class="btner" @click="waterDetail(item)">
                            <span>查看详情 </span>
                        </div>
                        <div class="btner yellow" @click="chartWaterEvent(item)">
                            <span>用水情况 </span>
                        </div>

                    </div>
                </div>

                <div class="noMeter" v-if="metersList.waterMeters && metersList.waterMeters.length == 0 || metersList.waterMeters == null">
                    当前房间无水表!
                </div>

        </div>

        <div class="titleInfo pt43"><span>未缴账单</span></div>

        <div class="billTotalInfo" @click="chargeEvent">
            <div class="goCharge">
                前</br>往</br>缴</br>费
            </div>
            <h4>房号：{{userInfos | installAdressFormat}}</h4>
            <div class="billInfoS">
                <div class="billUn">
                    <p class="label">未缴账单</p>
                    <p class="number">{{unpaidBillMode.several}}张</p>
                </div>
                <div class="billUn">
                    <p class="label">已逾期合计</p>
                    <p class="number">￥{{unpaidBillMode.overdueAmount}}</p>
                </div>
                <div class="billUn">
                    <p class="label">未缴合计</p>
                    <p class="number">￥{{unpaidBillMode.notPaidAmount}}</p>
                </div>
            </div>
        </div>

        <van-action-sheet v-model="electDetailVis" class="indexDetail" title="电表详情" close-icon="cross">
            <div class="content">
                <div class="detailUnit">
                    <div class="label"> <span>表</span><span>计</span><span>表</span><span>号</span></div>
                    <div class="text"><span class="blue">{{electDetailMode.meterAddr}}</span><br>
                                    <p>{{electDetailMode.roomName}}</p>
                    </div>
                </div>
                <!-- <div class="meterValue">
                    <div class="valueUnit">
                        <div class="detailUnit">
                            <div class="label"><span>上</span><span>期</span><span>行</span><span>度</span></div>
                            <div class="text">:<span class="blue">{{electDetailMode.lastValue}}</span></div>
                        </div>
                        <div class="valueTime">({{electDetailMode.lastReadTime}})</div>
                    </div>
                    <div class="valueUnit borderL">
                        <div class="detailUnit">
                            <div class="label"><span>本</span><span>期</span><span>行</span><span>度</span></div>
                            <div class="text">:<span class="blue">{{electDetailMode.currentValue}}</span></div>
                        </div>
                        <div class="valueTime">({{electDetailMode.currentReadTime}})</div>
                    </div>
                </div> -->
                <!-- <div class="detailUnit">
                    <div class="label"><span>上</span><span>期</span><span>行</span><span>度</span></div>
                    <div class="text"><span class="blue">{{electDetailMode.lastValue}}</span>({{electDetailMode.lastReadTime}})</div>
                </div> -->
                <div class="detailUnit">
                    <div class="label"><span>当</span><span>前</span><span>行</span><span>度</span></div>
                    <div class="text"><span class="blue">{{electDetailMode.currentValue}}</span>({{electDetailMode.currentReadTime}})</div>
                </div>
                <div class="detailUnit">
                    <div class="label"><span>当</span><span>前</span><span>余</span><span>额</span></div>
                    <div class="text"><span class="blue">￥{{electDetailMode.balance}}</span></div>
                </div>
                <div class="detailUnit">
                    <div class="label"><span>计</span><span>价</span><span>模</span><span>式</span></div>
                    <div class="text"><span v-if="electDetailMode.electricPriceVO" class="blue">{{valuationElectEnum[electDetailMode.electricPriceVO.valuationKind]}}</span><span v-if="electDetailMode.electricPriceVO && electDetailMode.electricPriceVO.valuationKind == 1" class="blue">（￥{{electDetailMode.electricPriceVO.fixedPriceVO.price}}/度）</span></div>
                </div>
                <div class="detailUnit" v-if="electDetailMode.electricPriceVO && electDetailMode.electricPriceVO.valuationKind == 2">
                    <div class="text sp">
                        <div class="bold">尖:￥{{electDetailMode.electricPriceVO.timeSharingVO.tip}}; 峰:￥{{electDetailMode.electricPriceVO.timeSharingVO.peak}}; 平:￥{{electDetailMode.electricPriceVO.timeSharingVO.flat}}; 谷:￥{{electDetailMode.electricPriceVO.timeSharingVO.ancient}}</div>
                        <table v-if="electDetailMode.electricPriceVO.timeSharingVO.sharing.length<4" >
                            <tr>
                                <td v-for="(item,index) in electDetailMode.electricPriceVO.timeSharingVO.sharing" :key="index"><span class="blocker">{{item.start}}-{{item.end}}({{electTypeList[item.type]}})</span></td>
                            </tr>

                        </table>
                        <table v-if="electDetailMode.electricPriceVO.timeSharingVO.sharing.length>3 && electDetailMode.electricPriceVO.timeSharingVO.sharing.length<7">
                            <tr>
                                <td v-for="(item,index) in electDetailMode.electricPriceVO.timeSharingVO.sharing.slice(0, 3)" :key="index">
                                        <span class="blocker">{{item.start.slice(0,-3)}}-{{item.end.slice(0,-3)}}({{electTypeList[item.type]}})</span>
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(item,index) in electDetailMode.electricPriceVO.timeSharingVO.sharing.slice(3, 6)" :key="index">
                                        <span class="blocker">{{item.start.slice(0,-3)}}-{{item.end.slice(0,-3)}}({{electTypeList[item.type]}})</span>
                                </td>
                            </tr>
                        </table>
                        <table v-if="electDetailMode.electricPriceVO.timeSharingVO.sharing.length>6">
                            <tr>
                                <td v-for="(item,index) in electDetailMode.electricPriceVO.timeSharingVO.sharing.slice(0, 3)" :key="index">
                                        <span class="blocker">{{item.start.slice(0,-3)}}-{{item.end.slice(0,-3)}}({{electTypeList[item.type]}})</span>
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(item,index) in electDetailMode.electricPriceVO.timeSharingVO.sharing.slice(3, 6)" :key="index">
                                        <span class="blocker">{{item.start.slice(0,-3)}}-{{item.end.slice(0,-3)}}({{electTypeList[item.type]}})</span>
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(item,index) in electDetailMode.electricPriceVO.timeSharingVO.sharing.slice(6)" :key="index">
                                        <span class="blocker">{{item.start.slice(0,-3)}}-{{item.end.slice(0,-3)}}({{electTypeList[item.type]}})</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </van-action-sheet>

        <van-action-sheet v-model="waterDetailVis" class="indexDetail" title="水表详情" close-icon="cross">
            <div class="content">
                <div class="detailUnit">
                    <div class="label"> <span>表</span><span>计</span><span>表</span><span>号</span></div>
                    <div class="text"><span class="blue">{{waterDetailMode.meterAddr}}</span><p>{{waterDetailMode.roomName}}</p></p></div>
                </div>

                <!-- <div class="meterValue">
                    <div class="valueUnit">
                        <div class="detailUnit">
                            <div class="label"><span>上</span><span>期</span><span>行</span><span>度</span></div>
                            <div class="text">:<span class="blue">{{waterDetailMode.lastValue}}</span></div>
                        </div>
                        <div class="valueTime">({{waterDetailMode.lastReadTime}})</div>
                    </div>
                    <div class="valueUnit borderL">
                        <div class="detailUnit">
                            <div class="label"><span>本</span><span>期</span><span>行</span><span>度</span></div>
                            <div class="text">:<span class="blue">{{waterDetailMode.currentValue}}</span></div>
                        </div>
                        <div class="valueTime">({{waterDetailMode.currentReadTime}})</div>
                    </div>
                </div> -->
                <!-- <div class="detailUnit">
                    <div class="label"><span>上</span><span>期</span><span>行</span><span>度</span></div>
                    <div class="text"><span class="blue">{{waterDetailMode.lastValue}}</span>({{waterDetailMode.lastReadTime}})</div>
                </div> -->
                <div class="detailUnit">
                    <div class="label"><span>当</span><span>前</span><span>行</span><span>度</span></div>
                    <div class="text"><span class="blue">{{waterDetailMode.currentValue}}</span>({{waterDetailMode.currentReadTime}})</div>
                </div>
                <div class="detailUnit">
                    <div class="label"><span>计</span><span>价</span><span>模</span><span>式</span></div>
                    <div class="text"><span v-if="waterDetailMode.waterPriceVO" class="blue">{{valuationWaterEnum[waterDetailMode.waterPriceVO.valuationKind]}}</span><span v-if="waterDetailMode.waterPriceVO && waterDetailMode.waterPriceVO.valuationKind == 1" class="blue">（￥{{waterDetailMode.waterPriceVO.fixedPriceVO.price}}）</span></div>
                </div>
                <div class="detailUnit" v-if="waterDetailMode.waterPriceVO && waterDetailMode.waterPriceVO.valuationKind == 2">
                    <div class="text sp">

                        <table v-if="waterDetailMode.waterPriceVO.increasePriceVO.increase.length<4">
                            <tr>
                                <td v-for="(item,index) in waterDetailMode.waterPriceVO.increasePriceVO.increase" :key="index"><span class="blocker">{{item.start}}-<span v-if="index == waterDetailMode.waterPriceVO.increasePriceVO.increase.length-1">以上</span><span v-else>{{item.end}}</span><span class="blue">(￥{{item.increasePrice}})</span></span></td>
                            </tr>

                        </table>
                        <table v-if="waterDetailMode.waterPriceVO.increasePriceVO.increase.length>3 && waterDetailMode.waterPriceVO.increasePriceVO.increase.length<7">
                            <tr>
                                <td v-for="(item,index) in waterDetailMode.waterPriceVO.increasePriceVO.increase.slice(0, 3)" :key="index">
                                    <templete v-if="index == waterDetailMode.waterPriceVO.increasePriceVO.increase.length-1">
                                        <span class="blocker">{{item.start}}-以上<span class="blue">(￥{{item.increasePrice}})</span></span>
                                    </templete>
                                    <templete v-else>
                                        <span class="blocker">{{item.start}}-{{item.end}}<span class="blue">(￥{{item.increasePrice}})</span></span>
                                    </templete>
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(item,index) in waterDetailMode.waterPriceVO.increasePriceVO.increase.slice(3, 6)" :key="index">
                                    <templete v-if="index == waterDetailMode.waterPriceVO.increasePriceVO.increase.length-1">
                                        <span class="blocker">{{item.start}}-以上<span class="blue">(￥{{item.increasePrice}})</span></span>
                                    </templete>
                                    <templete v-else>
                                        <span class="blocker">{{item.start}}-{{item.end}}<span class="blue">(￥{{item.increasePrice}})</span></span>
                                    </templete>
                                </td>
                            </tr>
                        </table>
                        <table v-if="waterDetailMode.waterPriceVO.increasePriceVO.increase.length>6">
                            <tr>
                                <td v-for="(item,index) in waterDetailMode.waterPriceVO.increasePriceVO.increase.slice(0, 3)" :key="index">
                                    <templete v-if="index == waterDetailMode.waterPriceVO.increasePriceVO.increase.length-1">
                                        <span class="blocker">{{item.start}}-以上<span class="blue">(￥{{item.increasePrice}})</span></span>
                                    </templete>
                                    <templete v-else>
                                        <span class="blocker">{{item.start}}-{{item.end}}<span class="blue">(￥{{item.increasePrice}})</span></span>
                                    </templete>
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(item,index) in electDetailMode.waterPriceVO.increasePriceVO.increase.slice(3, 6)" :key="index">
                                    <templete v-if="index == waterDetailMode.waterPriceVO.increasePriceVO.increase.length-1">
                                        <span class="blocker">{{item.start}}-以上<span class="blue">(￥{{item.increasePrice}})</span></span>
                                    </templete>
                                    <templete v-else>
                                        <span class="blocker">{{item.start}}-{{item.end}}<span class="blue">(￥{{item.increasePrice}})</span></span>
                                    </templete>
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(item,index) in waterDetailMode.waterPriceVO.increasePriceVO.increase.slice(6)" :key="index">
                                    <templete v-if="index == waterDetailMode.waterPriceVO.increasePriceVO.increase.length-1">
                                        <span class="blocker">{{item.start}}-以上<span class="blue">(￥{{item.increasePrice}})</span></span>
                                    </templete>
                                    <templete v-else>
                                        <span class="blocker">{{item.start}}-{{item.end}}<span class="blue">(￥{{item.increasePrice}})</span></span>
                                    </templete>

                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </van-action-sheet>
      </template>
      <template v-else>
        <div style="font-size: 14px;position: fixed;top:200px;left: 0;right: 0;left: 0; display: flex;align-items:center;justify-content:center;">
          页面加载中...
        </div>
      </template>
    </div>
</template>
<script>
export default {
  name: 'index',
  // components: {verifyCode},
  data () {
    return {
      animate: false,
      userInfos: {
        'areaName': '',
        'buildName': '',
        'floorName': '',
        'head': '',
        'name': '',
        'propName': '',
        'roomName': '',
        'tenantFid': '',
        sms: ''
      },
      alarmList: [],
      metersList: {
        electricMeters: [],
        waterMeters: []
      },
      alarmTypeEnum: null,
      billInfos: {
        'apportion': '',
        'electric': '',
        'management': '',
        'other': '',
        'rent': '',
        'water': ''
      },
      electDetailVis: false,
      meterElectIs: true,
      electDetailMode: {},
      waterDetailMode: {},
      valuationElectEnum: null,
      valuationWaterEnum: null,
      waterDetailVis: false,
      getDataIs: {
        userDataIs: false,
        meterDataIs: false,
        alarmDataIs: false,
        billDataIs: false
      },
      unpaidBillMode: {},
      interval: null,
      alarmLength: null
    }
  },
  watch: {
    'userInfos.propName': {
      handler: function (val, oldval) {
        document.title = val
        this.$route.meta.title = val
      },
      deep: true
    },
    'getDataIs': {
      handler: function (val) {
        this.getDataIs = val
        if (this.getDataIs.userDataIs && this.getDataIs.meterDataIs && this.getDataIs.billDataIs && this.getDataIs.alarmDataIs) {
          this.load.$emit('loading', false)
        }
      },
      deep: true
    }
  },
  mounted () {
  },
  created () {
    this.valuationElectEnum = this.valuationElectEnums()
    this.valuationWaterEnum = this.valuationWaterEnums()
    this.electTypeList = this.electTypeLists()
    this.alarmTypeEnum = this.alarmTypeEnums()
    this.storageInit()

    this.alarmTypeEnum = this.alarmTypeEnums()
    this.getData()
  },

  methods: {
    getData () {
      this.load.$emit('loading', true)
      this.getUserInfo()
      this.getMeterList()
      this.getUnpaidBill()
      this.getCurrentAlarm()
    },
    // 告警暂停
    stop: function () {
      clearInterval(this.interval)
      clearTimeout(this.time)
    },
    // 告警滚动继续
    start: function () {
      this.interval = setInterval(this.showMarquee, 4000)
    },
    // 告警滚动
    showMarquee: function () {
      let self = this
      this.animate = true
      self.alarmList.push(self.alarmList[0])
      this.time = setTimeout(() => {
        self.alarmList.shift()
        self.animate = false
      }, 500)
    },
    // 电表详情
    electDetail (item) {
      this.electDetailVis = true
      this.$set(this, 'electDetailMode', item)
      // this.$set(this,'electDetailMode.electricPriceVO',item.electricPriceVO)
    },
    // 水表详情
    waterDetail (item) {
      this.waterDetailVis = true
      this.$set(this, 'waterDetailMode', item)
    },
    getCurrentAlarm () {
      this.$store.dispatch('property/getCurrentAlarm', {}).then((result) => {
        this.getDataIs.alarmDataIs = true
        if (result.returnCode == 0) {
          clearInterval(this.interval)
          this.animate = false
          if (result.data.length > 0) {
            this.$set(this, 'alarmList', result.data)
            this.$set(this, 'alarmLength', result.data.length)
            this.interval = setInterval(this.showMarquee, 4000)
          }
        } else {
          clearInterval(this.interval)
          this.animate = false
          this.$confirm({
            mesg: result.returnMsg,
            cancel: false,
            btn: {
              confirmVal: '确 定'
            }
          }).then(() => {
            this.$store.dispatch("user/logout");
            this.$store.dispatch("property/removeContract");
          })
        }
      }).catch(error => {
        this.getDataIs.alarmDataIs = true
        if (error.message.includes('timeout')) {
          this.$confirm({
            mesg: '告警信息请求超时',
            cancel: false,
            btn: {
              confirmVal: '确 定'
            }
          })
        }
      })
    },
    // 首页未缴账单信息
    getUnpaidBill () {
      this.$store.dispatch('meter/unpaidBill', {}).then((result) => {
        this.getDataIs.billDataIs = true
        if (result.returnCode == 0) {
          this.$set(this, 'unpaidBillMode', result.data)
        } else {
          this.$confirm({
            mesg: result.returnMsg,
            cancel: false,
            btn: {
              confirmVal: '确 定'
            }
          }).then(() => {
            this.$store.dispatch("user/logout");
            this.$store.dispatch("property/removeContract");
          })
        }
      }).catch(error => {
        this.getDataIs.billDataIs = true
        if (error.message.includes('timeout')) {
          this.$confirm({
            mesg: '未缴账单信息请求超时',
            cancel: false,
            btn: {
              confirmVal: '确 定'
            }
          })
        }
      })
    },
    // 获取电表列表
    getMeterList () {
      this.$store.dispatch('meter/getMeterList', {}).then((result) => {
        this.getDataIs.meterDataIs = true
        if (result.returnCode == 0) {
          this.$set(this, 'metersList', result.data)
        } else {
          this.$confirm({
            mesg: result.returnMsg,
            cancel: false,
            btn: {
              confirmVal: '确 定'
            }
          }).then(() => {
            this.$store.dispatch("user/logout");
            this.$store.dispatch("property/removeContract");
          })
        }
      }).catch(error => {
        this.getDataIs.meterDataIs = true
        if (error.message.includes('timeout')) {
          this.$confirm({
            mesg: '表计列表请求超时',
            cancel: false,
            btn: {
              confirmVal: '确 定'
            }
          })
        }
      })
    },
    // 电表充值跳转
    electRecharge (item) {
      console.log(item)
      this.$router.push({
        path: '/electRecharge',
        query: {
          meterAddr: item.meterAddr
        }
      })
    },
    // 缓存初始化
    storageInit () {
      this.$store.dispatch('user/setToken')
      this.$store.dispatch('property/setProp')
      this.$store.dispatch('property/setContract')
    },
    // 获取用户信息
    getUserInfo () {
      this.$store.dispatch('property/userInfo', {}).then((result) => {
        this.getDataIs.userDataIs = true
        if (result.returnCode == 0) {
          if (result.data.propName == '珠江国际纺织城-商场' || result.data.propName == '珠江国际纺织城-商场') {
            this.$router.replace('/zfrecharge')
            setTimeout(() => {
              this.userInfos = result.data
              document.title = result.data.propName
            }, 500)
          } else {
            this.userInfos = result.data
            document.title = result.data.propName
          }
        } else {
          this.$confirm({
            mesg: result.returnMsg,
            cancel: false,
            btn: {
              confirmVal: '确 定'
            }
          }).then(() => {
            this.$store.dispatch("user/logout");
            this.$store.dispatch("property/removeContract");
          })
        }
      }).catch(error => {
        this.getDataIs.userDataIs = true
        if (error.message.includes('timeout')) {
          this.$confirm({
            mesg: '用户信息请求超时',
            cancel: false,
            btn: {
              confirmVal: '确 定'
            }
          })
        }
      })
    },

    chargeEvent () {
      this.$router.push('/unPayBill')
    },
    chartWaterEvent (item) {
      this.$router.push({
        path: '/chartWater',
        query: {
          meterAddr: item.meterAddr
        }
      })
    },
    alarmEvent () {
      this.$router.push('/alarm')
    },
    serviceEvent () {
      this.$router.push('/account')
      // this.$router.push('/service')
    },
    historyBillEvent () {
      this.$router.push('/payRecord')
    },
    accountEvent () {
      this.$router.push('/account')
    },
    messageEvent () {
      if (this.userInfos.sms) {
        this.$router.push('/message')
      } else {
        this.$router.push('/messagePackage')
      }

      // this.$router.push('/message')
    },
    chartEvent (item) {
      this.$router.push({
        path: '/chart',
        query: {
          meterAddr: item.meterAddr
        }
      })
    },
    goRecharge (num) {
      if (num * 1 > 0) {
        this.$router.push({ name: 'recharge', params: { flag: true } })
      }
    },
    // 切换物业
    switchProperty () {
      this.$router.push('/property')
    }
  },
  beforeDestroy () {
    // console.log('beforeDestroy')
    clearInterval(this.alarmUpdateTime)
    clearInterval(this.interval)
    this.animate = false
  },
  destroyed () {
    // console.log('destroyed')
    clearInterval(this.alarmUpdateTime)
    clearInterval(this.interval)
    this.animate = false
  }
}
</script>
