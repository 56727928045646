<!--
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2020-09-09 11:41:06
 * @LastEditors: yql
 * @LastEditTime: 2020-10-10 16:54:23
-->

<template>
    <div class="messageBox" id="messageBox">
        <!-- 个人信息 -->
        <div class="infoBox">
            <div class="headImg">
                <img src="../../assets/images/img_default1.png" v-if="smsInfo.head ==null || smsInfo.head == ''" />
                <img :src="smsInfo.head" v-else>
            </div>
            <div class="headInfo">
                <h3>{{smsInfo.name}}</h3>
                <div class="telBox">{{smsInfo.mobile}}</div>
                <div class="cardStatus not" v-if="smsInfo.time == null || smsInfo.time === ''">未开通</div>
                <div class="cardStatus " v-else>将于{{smsInfo.time}}到期</div>
            </div>
        </div>

        <div class="cardDetailBox">

            <div class="cardStatusBox">

                <div class="cardStatus" @click="goPackage">
                    <div class="cardName">无忧短信卡</div>
                    <div class="cardAd">秒级到达 设备无忧</div>
                    <div class="cardBtn"><span>立即续费</span></div>
                </div>

            </div>

            <div class="cardSetBox">

                <div class="titleI">
                    <div class="titleName">通知设置</div>
                    <!-- <div class="titleSet"><van-switch v-model="smsInfo.checked" inactive-color="#eee" /></div> -->
                </div>

                <div class="cardLineUnit">
                    <div class="unitName">短信通知功能</div>
                    <div class="unitSet"><van-switch v-model="smsInfoSet.open" inactive-color="#eee"  @click.native="openSetEv" /></div>
                </div>

                <div class="cardLineUnit">
                    <div class="unitName">接收短信号码</div>
                    <div class="unitSet arrower" @click="changeMobile(smsInfoSet.smsMobile)"><span class="time">{{smsInfoSet.smsMobile}}</span></div>
                </div>

                <div class="cardLineUnit">
                    <div class="unitName">短信通知时间段</div>
                    <div class="unitSet arrower" @click="pickTimeEv"><span class="time">{{smsInfoSet.openStart}} -{{smsInfoSet.openEnd}} </span></div>
                </div>



            </div>


            <div class="cardSetBox">
                <div class="showMore" :class="{'up':foldIs}" @click="foldMoreEv">{{foldIs?'收起':'更多'}}类型 <span class="arrow"></span></div>

                <div class="titleI">
                    <div class="titleName">通知类型</div>
                    <!-- <div class="titleSet"><van-switch v-model="smsInfo.checked" inactive-color="#eee" /></div> -->
                </div>

                <div class="cardLineUnit">
                    <div class="unitName">余额不足提醒</div>
                    <div class="unitSet"><van-switch v-model="smsInfoSet.balance" inactive-color="#eee" @click.native="setEv"/></div>
                </div>


                <div class="cardLineUnit" v-show="1==2">
                    <div class="unitName">闸状态提醒</div>
                    <div class="unitSet"><van-switch v-model="smsInfoSet.swtich" inactive-color="#eee" @click.native="setEv" /></div>
                </div>


                <div class="cardLineUnit" v-show="1==2">
                    <div class="unitName">账单逾期提醒</div>
                    <div class="unitSet"><van-switch v-model="smsInfoSet.billOverdue" inactive-color="#eee" @click.native="setEv" /></div>
                </div>

                <div class="cardLineUnit" v-show="1==2">
                    <div class="unitName">账单缴费提醒</div>
                    <div class="unitSet"><van-switch v-model="smsInfoSet.billPay" inactive-color="#eee" @click.native="setEv" /></div>
                </div>

                <div class="cardLineUnit">
                    <div class="unitName">账单发布提醒</div>
                    <div class="unitSet"><van-switch v-model="smsInfoSet.billPush" inactive-color="#eee" @click.native="setEv" /></div>
                </div>

                <div class="cardLineUnit"  v-show="1==2">
                    <div class="unitName">充值到账提醒</div>
                    <div class="unitSet"><van-switch v-model="smsInfoSet.recharge" inactive-color="#eee" @click.native="setEv" /></div>
                </div>

                <div class="cardLineUnit" v-show="foldIs">
                    <div class="unitName">过载负荷告警</div>
                    <div class="unitSet"><van-switch v-model="smsInfoSet.overload" inactive-color="#eee" @click.native="setEv" /></div>
                </div>

                <div class="cardLineUnit">
                    <div class="unitName">恶性负载告警</div>
                    <div class="unitSet"><van-switch v-model="smsInfoSet.malignant" inactive-color="#eee" @click.native="setEv" /></div>
                </div>



            </div>



        </div>

        <div class="goIndex" @click="$router.push('/index')"></div>
        <!-- <view-footer></view-footer> -->

    <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar title="选择时间段" :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" ref="timePicker">
            <div slot="columns-top" class="timePickerCol">
                <div class="startTime">开始时间</div>
                <div class="endTime">结束时间</div>
            </div>
        </van-picker>
    </van-popup>

    <van-popup v-model="showMobile" class="mobile">
            <div class="popuperB" v-show="showMobile">
                <div class="title" >设置接收短信号码</div>
                <div class="content">
                    <div class="inputBoxer">
                        <input type="tel"   @blur="inputBlur" placeholder="请输入在物业登记的手机号码" v-model="mobileInit" class="mobInput"/>
                    </div>
                    <div class="errorBox" >{{errorInfo}}</div>
                </div>
                <div class="footerB">
                    <div class="cancleBtn" @click="showMobile = false">取消</div>
                    <div class="sureBtn"  @click="mobileMod()">确定</div>
                </div>
            </div>
    </van-popup>

    </div>
</template>
<script>
export default {
    name: "message",
    data() {
        return {
            smsInfo:{},
            config:{},
            smsInfoSet:{},
            columns: [
                // 第1列
                {
                values: ['00', '01', '02', '03', '04','05', '06', '07', '08', '09','10', '11', '12', '13', '14','15', '16', '17', '18', '19','20', '21', '22', '23'],
                defaultIndex: 2,
                },
                // 第2列
                {
                values: ['00', '01', '02', '03', '04','05', '06', '07', '08', '09','10', '11', '12', '13', '14','15', '16', '17', '18', '19','20', '21', '22', '23','24', '25', '26', '27', '28', '29', '30', '31', '32', '33','34', '35', '36', '37', '38', '39', '40', '41', '42', '43','44', '45', '46', '47', '48', '49','50', '51', '52', '53','54', '55', '56', '57', '58', '59'],
                defaultIndex: 1,
                },
                // 第3列
                {
                values: ['00', '01', '02', '03', '04','05', '06', '07', '08', '09','10', '11', '12', '13', '14','15', '16', '17', '18', '19','20', '21', '22', '23'],
                defaultIndex: 3,
                },
                // 第2列
                {
                values: ['00', '01', '02', '03', '04','05', '06', '07', '08', '09','10', '11', '12', '13', '14','15', '16', '17', '18', '19','20', '21', '22', '23','24', '25', '26', '27', '28', '29', '30', '31', '32', '33','34', '35', '36', '37', '38', '39', '40', '41', '42', '43','44', '45', '46', '47', '48', '49','50', '51', '52', '53','54', '55', '56', '57', '58', '59'],
                defaultIndex: 4,
                },
            ],
            defaultTime:['12','12','12','12'],
            showPicker: false,
            toast:null,
            foldIs:false,
            showMobile:false,
            mobileInit:'',
            errorInfo:''
        };
    },
    mounted() {
    },
    created(){
        this.load.$emit('loading', true)
        this.getInfoer()
    },
    watch:{
        "defaultTime": {
            handler: function(val, oldval) {
                this.defaultTime = val
                this.smsInfoSet.openStart = this.defaultTime[0]+':'+this.defaultTime[1]
                this.smsInfoSet.openEnd = this.defaultTime[2]+':'+this.defaultTime[3]

            },
            deep: true
        },
        "mobileInit": {
            handler: function(val, oldval) {
                this.errorInfo = ''
            },
            deep: true
        },
    },
    methods: {
        changeMobile(phone){
            this.showMobile = true
            this.mobileInit = phone
        },
        mobileMod(){
            if(this.mobileInit === ''  || this.mobileInit == null){
                // this.$confirm({
                //     mesg: "请输入手机号",
                //     cancel:false,
                //     btn: {
                //         confirmVal: "确 定"
                //     }
                // })

                this.errorInfo = "请输入手机号"
                return false
            }else if(!this.validMobile(this.mobileInit)){
                // this.$confirm({
                //     mesg: "手机号格式不正确",
                //     cancel:false,
                //     btn: {
                //         confirmVal: "确 定"
                //     }
                // })
                this.errorInfo = "手机号格式不正确"
                return false
            }

            this.smsInfoSet.smsMobile = this.mobileInit

            this.setEv()
        },
        inputBlur(e){
            let str = e.target.value.toString()
            e.target.value = str.replace(/(^\s*)|(\s*$)/g, "");
            e.target.dispatchEvent(new Event('input'));
             let scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
            window.scrollTo(0, Math.max(scrollHeight - 1, 0));
            window.scrollTo(0, 0);
        },
        foldMoreEv(){
            this.foldIs = !!!this.foldIs
        },
        openSetEv(){
            if(!this.smsInfoSet.open){
                for(let key in this.config){
                    this.config[key] = false
                }
                this.listAssign(this.smsInfoSet,this.config)
            }
            this.setEv()
        },
        setEv(){

            // this.listAssign(this.config,this.smsInfoSet)
            this.load.$emit('loading', true)
            this.$store.dispatch('property/editUserSmsConfig',this.smsInfoSet).then((result) => {

                    if (result.returnCode == 0) {
                        this.showMobile = false
                        this.getInfo()
                    }else{
                        this.errorInfo = result.returnMsg
                    }

                }).catch(error => {
                    this.getInfo()
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        },
        getInfoer(){
            this.$store.dispatch('property/userInfo',{}).then((result) => {
                if (result.returnCode == 0) {
                    if(result.data.sms){
                        this.getInfo()
                    }else{
                        this.$router.back(-1)
                    }
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                    this.load.$emit('loading', false)
                }
            }).catch(error => {
                this.load.$emit('loading', false)
                if(error.message.includes('timeout')){
                    this.$confirm({
                        mesg: '用户信息请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        getInfo(){
            this.$store.dispatch('property/getUserSmsConfig',{}).then((result) => {
                // this.getDataIs.meterDataIs = true
                    if (result.returnCode == 0) {
                        this.dataInit(result.data)
                    } else {
                        this.$confirm({
                            mesg: result.returnMsg,
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                    this.load.$emit('loading', false)
                }).catch(error => {
                    this.load.$emit('loading', false)
                    // this.getDataIs.meterDataIs = true
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        },
        dataInit(datas){

            let data = JSON.parse(JSON.stringify(datas))
            this.$set(this,'smsInfo',data)
            this.$set(this,'config',data.config)
            this.smsInfoSet = Object.assign(datas,datas.config);
            delete this.smsInfoSet.config
            delete this.smsInfoSet.head
            delete this.smsInfoSet.name
            delete this.smsInfoSet.mobile
            delete this.smsInfoSet.time
            let timeArr1 = this.smsInfoSet.openStart.split(":");
            let timeArr2 = this.smsInfoSet.openEnd.split(":");
            this.defaultTime = timeArr1.concat(timeArr2)

            for(let key in this.config){
                if(this.config[key] && !datas.open){
                    this.smsInfoSet.open = true
                    this.setEv()
                    break
                }
            }

        },
        goPackage(){
            this.$router.push({
                path:'/messagePackage'
            })
        },
        pickTimeEv(){
            let self = this
            this.showPicker = true
            setTimeout(()=>{
                self.$refs.timePicker.setValues(self.defaultTime)
            },100)


        },
        onConfirm(value){
            this.showPicker = false;
            this.defaultTime.splice(0)
            this.defaultTime = this.defaultTime.concat(value)
            this.smsInfoSet.openStart = this.defaultTime[0]+':'+this.defaultTime[1]
            this.smsInfoSet.openEnd = this.defaultTime[2]+':'+this.defaultTime[3]
            this.setEv()
        }
    }
};
</script>
