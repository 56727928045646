<!--
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-04 17:27:36
 * @LastEditors: yql
 * @LastEditTime: 2020-06-01 17:48:10
 -->
<template>
  <div id="app">
    <router-view />
    <loading></loading>
  </div>
</template>
<script>
  import loading from '@/components/loading/loading'

  export default {
    name: 'App',
    components:{loading},
    created(){
      this.localStorageInit()
      // alert(window.location)
    },
    methods:{
      localStorageInit(){
        if(!window.localStorage.getItem('hodiToken')){
          let proper = this.GetQueryString('prop')
          if(proper!== null && proper.toString().length>1){
            window.localStorage.setItem('prop',proper)
            this.$store.dispatch('property/setProp',proper)
          }
        }else{
          this.$store.dispatch('property/setRoom')
        }
      },
      GetQueryString(name){
        let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
        let r = window.location.search.substr(1).match(reg);
        if(r!=null)return  unescape(r[2]); return null;
      }
    }
  }
</script>
<style scoped>


</style>
