<template>
  <div class="page-zfrecharge">
    <div class="prop-info box-bg">
      <div class="box-row">
        <div class="title">收费单位</div>
        <div class="row-content">{{ userInfos.propName }}</div>
      </div>
      <div class="box-row">
        <div class="title">单元号</div>
        <div class="row-content">
          {{ userInfos | installAdressFormat }}
          <div class="s-btn" @click="switchProperty">切换</div>
        </div>
      </div>
      <div class="box-row">
        <div class="title">充值信息</div>
        <div class="row-content">充值人：{{ userInfos.name }}</div>
      </div>
      <div class="box-row">
        <div class="title"></div>
        <div class="row-content">手机：{{ userInfos.mobile }}</div>
      </div>
    </div>
    <div class=" box-bg">
      <div class="meter-select">
        <div
          class="select-item"
          v-for="item in metersList.electricMeters"
          :class="{ active: currentSelectMeter.meterAddr == item.meterAddr }"
          :key="item.meterAddr"
          @click="meterSelect(item)"
        >
          {{ item.meterAddr }}
        </div>
      </div>
      <!-- <div class="box-row">
        <div class="title">电表倍率</div>
        <div class="row-content">{{ currentSelectMeter.ct}}</div>
      </div> -->
      <div class="box-row">
        <div class="title">电表行度</div>
        <div class="row-content">{{ currentSelectMeter.currentValue }}</div>
      </div>
      <div class="box-row">
        <div class="title">电表余额</div>
        <div class="row-content" style="color: #f00;">
          ￥{{ currentSelectMeter.balance }}
        </div>
      </div>
      <div class="box-row">
        <div class="title">数据时间</div>
        <div class="row-content">
          {{ currentSelectMeter.currentReadTime }}
          <div class="s-btn" @click="refresh">刷新</div>
        </div>
      </div>
    </div>
    <div class="charge-info box-bg">
      <div style="margin-bottom: 10px;">选择充值金额</div>
      <div class="prices">
        <div
          class="select-item"
          v-for="item in chargePrices"
          :key="item"
          :class="{ active: selectPrice == item }"
          @click="selectPrice = item"
        >
          <span>{{ item != "自定义" ? "￥" : "" }}{{ item }}</span>
        </div>
        <!-- <div class="select-item ">￥500
        </div>
        <div class="select-item ">￥1000
        </div>
        <div class="select-item">自定义
        </div> -->
      </div>
      <div class="custom" v-if="selectPrice == '自定义'">
        <div>自定义金额</div>
        <div class="custom-input">
          ￥
          <input v-model="customPrice" type="text" />
        </div>
      </div>
      <div class="recharge-btn">
        <van-button type="info" block @click="confirmCharge"
          >立即充值</van-button
        >
      </div>
    </div>
    <view-footer></view-footer>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "zfrecharge",
  data() {
    return {
      metersList: {
        electricMeters: [],
        waterMeters: []
      },
      customPrice: "",
      currentSelectMeter: {
        meterAddr: "",
        fid: ""
      },
      selectPrice: "100",
      chargePrices: ["100", "500", "1000", "自定义"],
      userInfos: {
        areaName: "",
        buildName: "",
        floorName: "",
        head: "",
        name: "",
        mobile: "",
        propName: "",
        roomName: "",
        tenantFid: "",
        sms: ""
      },
      msg: "Welcome to Your Vue.js App"
    };
  },
  computed: {
    rechargeAmount() {
      return this.selectPrice == "自定义" ? this.customPrice : this.selectPrice;
    }
  },
  methods: {
    // 切换物业
    switchProperty() {
      this.$router.push("/property");
    },
    meterSelect(item) {
      this.currentSelectMeter = item;
    },
    // 获取用户信息
    getUserInfo() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("property/userInfo", {})
          .then(result => {
            if (result.returnCode == 0) {
              this.userInfos = result.data;
              document.title = result.data.propName;
              resolve(result.data);
            } else {
              this.$confirm({
                mesg: result.returnMsg,
                cancel: false,
                btn: {
                  confirmVal: "确 定"
                }
              });
              reject(result.returnMsg);
            }
          })
          .catch(error => {
            reject(error.message);
            if (error.message.includes("timeout")) {
              this.$confirm({
                mesg: "用户信息请求超时",
                cancel: false,
                btn: {
                  confirmVal: "确 定"
                }
              });
            }
          });
      });
    },
    // 获取电表列表
    getMeterList() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("meter/getMeterList", {})
          .then(result => {
            if (result.returnCode == 0) {
              this.$set(this, "metersList", result.data);
              this.currentSelectMeter =
                result.data && result.data.electricMeters
                  ? result.data.electricMeters[0]
                  : {};
              resolve(result.data);
            } else {
              this.$confirm({
                mesg: result.returnMsg,
                cancel: false,
                btn: {
                  confirmVal: "确 定"
                }
              });
              resolve(result.returnMsg);
            }
          })
          .catch(error => {
            resolve(error.message);
            if (error.message.includes("timeout")) {
              this.$confirm({
                mesg: "表计列表请求超时",
                cancel: false,
                btn: {
                  confirmVal: "确 定"
                }
              });
            }
          });
      });
    },

    isNonNegativeAmount(value) {
      // 匹配正整数或零开头，可选带有小数点及小数部分的正数
      const regex = /^\d+(\.\d+)?$/;
      return regex.test(value);
    },

    confirmCharge() {
      if (this.selectPrice == "自定义") {
        if (!this.isNonNegativeAmount(this.rechargeAmount)) {
          this.$confirm({
            mesg: "请输入正确的金额",
            cancel: false,
            btn: {
              confirmVal: "确 定"
            }
          });
          this.load.$emit("loading", false);
          return;
        }
        if (Number(this.rechargeAmount) <= 0) {
          this.$confirm({
            mesg: "金额需大于0",
            cancel: false,
            btn: {
              confirmVal: "确 定"
            }
          });
          this.load.$emit("loading", false);
          return;
        }
      }
      this.$confirm({
        mesg: "是否确认充值" + this.rechargeAmount + "元？",
        btn: {
          confirmVal: "确 定",
          cancelVal: "取 消"
        },
        success: () => {
          this.chargeSubmit();
        }
      }).then(() => {
        this.chargeSubmit();
        // on confirm
      });
    },
    // 充值提交
    chargeSubmit() {
      let self = this;
      this.load.$emit("loading", true);

      let params = {
        meterAddr: this.currentSelectMeter.meterAddr,
        rechargeAmount: this.rechargeAmount,
        redirectUrl: window.location.origin + "/#/zfrecharge"
      };
      this.$store
        .dispatch("property/electricRecharge", params)
        .then(result => {
          this.load.$emit("loading", false);
          if (result.returnCode == 0) {
            if (result.data.value0 == 53) {
              this.load.$emit("loading", true);
              request({
                url: result.data.value2,
                method: "get"
              }).then(res => {
                this.load.$emit("loading", false);
                if (res.returnCode != 0) {
                  this.$confirm({
                    mesg: res.returnMsg,
                    cancel: false,
                    btn: {
                      confirmVal: "确 定"
                    }
                  });
                  return;
                }
                let data = JSON.parse(res.data.body.payinfo);
                self.setConfig(data);
                wx.ready(function() {
                  self.chooseWXPay(data);
                });
              });
            } else if (result.data.value0 == 50) {
              window.location.href = result.data.value2;
            } else if (result.data.value0 == 54) {
              request({
                url: result.data.value2,
                method: "get"
              }).then(res => {
                let eleScript = document.createElement("script");
                eleScript.innerHTML = res;
                self.$refs.form.append(eleScript);
              });
            } else if (result.data.value0 == 56) {
              request({
                url: result.data.value2,
                method: "get"
              }).then(res => {
                if (res.returnCode != 0) {
                  this.$confirm({
                    mesg: res.returnMsg,
                    cancel: false,
                    btn: {
                      confirmVal: "确 定"
                    }
                  });
                  return;
                }
                window.location.href = res.data;
              });
            } else if (result.data.value0 == 57) {
              this.load.$emit("loading", true);
              request({
                url: result.data.value2,
                method: "get"
              }).then(res => {
                this.load.$emit("loading", false);
                if (res.returnCode != 0) {
                  this.$confirm({
                    mesg: res.returnMsg,
                    cancel: false,
                    btn: {
                      confirmVal: "确 定"
                    }
                  });
                  return;
                }
                let data = res.data;
                data.package = data.pkg;
                self.setConfig(data);
                wx.ready(function() {
                  self.chooseWXPay(data);
                });
              });
            } else if (result.data.value0 == 92) {
              console.log(result.data.value2);
              this.load.$emit("loading", true);
              request({
                url: result.data.value2,
                method: "get"
              }).then(res => {
                this.load.$emit("loading", false);
                if (res.returnCode == 0) {
                  document.write(res.data);
                }
              });
            }
          } else {
            this.$confirm({
              mesg: result.returnMsg,
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        })
        .catch(error => {
          this.load.$emit("loading", false);
          if (error.message.includes("timeout")) {
            this.$confirm({
              mesg: "请求超时",
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        });
    },
    refresh() {
      this.load.$emit("loading", true);
      this.$store
        .dispatch("meter/refreshBalance", {
          fid: this.currentSelectMeter.meterAddr
        })
        .then(result => {
          this.load.$emit("loading", false);
          if (result.returnCode == 0) {
            this.currentSelectMeter.currentReadTime =
              result.data.currentReadTime;
            this.currentSelectMeter.balance = result.data.balance;
          } else {
            this.$confirm({
              mesg: result.returnMsg,
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        })
        .catch(error => {
          if (error.message.includes("timeout")) {
            this.$confirm({
              mesg: "数据请求超时",
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        });
    },
    setConfig(data) {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timeStamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.paySign, // 必填，签名
        jsApiList: ["chooseWXPay"] // 必填，需要使用的JS接口列表
      });
    },
    chooseWXPay(data) {
      wx.chooseWXPay({
        timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
        package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        paySign: data.paySign, // 支付签名
        success: function(res) {
          // 支付成功后的回调函数
          console.log(res);
        }
      });
    }
  },
  mounted() {
    this.load.$emit("loading", true);
    Promise.all([this.getUserInfo(), this.getMeterList()])
      .then(res => {
        console.log("🚀 ~ Promise.all ~ res:", res);
        this.load.$emit("loading", false);
      })
      .catch(err => {
        this.load.$emit("loading", false);
      });
  }
};
</script>

<style scoped lang="scss">
.page-zfrecharge {
  padding: 20px;
  background-color: #e4e4e4;
  font-size: 32px;
  padding-bottom: 170px;
}
.box-bg {
  background: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 20px;
}

.box-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  margin-bottom: 30px;
  .title {
  }
  .row-content {
    flex: 1;
    text-align: right;
  }
}

.meter-select {
  display: flex;
  flex-wrap: wrap;
}

.select-item {
  font-size: 32px;
  text-align: center;
  margin-right: 10px;
  padding: 16px 24px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 2px solid #bbbbbb;
  position: relative;
  overflow: hidden;
  &.active {
    border: 2px solid #09a2e7;
    background-color: #fff;
    color: #09a2e7;
    background: url("~@/assets/images/icon_gou.png") no-repeat bottom right;
    background-size: 33px 33px;
  }
}

.s-btn {
  color: #09a2e7;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 24px;
  border: 2px solid #09a2e7;
  background-color: #fff;
  display: inline-block;
  &:focus {
    outline: none;
  }
}

.custom-input {
  border: 2px solid #bbbbbb;
  border-radius: 8px;
  height: 70px;
  width: 160px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 12px 0;
  input {
    width: 100%;
    padding: 8px;
  }
}

.prices {
  display: flex;
  flex-wrap: wrap;
  .price-item {
    width: 30%;
  }
}
</style>
