/*
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-10-18 11:44:27
 * @LastEditors: yql
 * @LastEditTime: 2020-05-09 14:35:36
 */
import Vue from 'vue'
let formatMoney = (value) => {
  if (value) {
    value = Number(value);
    return '￥ ' + value.toFixed(2);
  }
};

//过滤时间eg 12:00:00 ==> 12：00
let formatTime = (value) => {
  
  if (value) {
    value = value.split(":");
    return value[0] + ":" + value[1];
  }
};

//过滤时间eg 201911 ==> 2019年11月
let formatDate = (value) => {
  // value = toString(value)
  return value.substring(0,4) + '年' + value.substring(4,6) + '月'
};

//金额千分位-- s:金额  n:小数点位数
let formatMoneyThousand = (s, n) => {
  let minus = false
  if (s == '' || s == null) {
    return 0
  }
  if (s < 0) {
    minus = true
    s = Math.abs(s);
  }
  if (n) {
    n = n > 0 && n < 21 ? n : 2;
    s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  } else {
    s = parseFloat((s + "").replace(/[^\d\.-]/g, "")) + "";
  }
  let l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1];
  let t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
  }
  if (minus) {
    if (r) {
      return '-' + t.split("").reverse().join("") + "." + r;
    } else {
      return '-' + t.split("").reverse().join("")
    }
  } else {
    if (r) {
      return t.split("").reverse().join("") + "." + r;
    } else {
      return t.split("").reverse().join("")
    }
  }

};

let areasFormat = (areas) => {
  return areas.replace('/', '-')
};

let installAdressFormat = (datas) => {
  let str = ''
  // if (datas.areaName !== null) {
  //   str += datas.areaName.replace('/', '-')
  // }
  if (datas.buildName !== null) {
    str += datas.buildName
  }
  if (datas.floorName !== null) {
    str += '-' + datas.floorName
  }
  if (datas.roomName !== null) {
    str += '-' + datas.roomName
  }
  return str
};

let installAdressFormat2 = (datas) => {
  let str = ''
  if (datas.areaName !== null) {
    str += datas.areaName.replace('/', '-')
  }
  if (datas.buildName !== null) {
    str += '-' + datas.buildName
  }
  if (datas.floor !== null) {
    str += '-' + datas.floor
  }
  if (datas.roomName !== null) {
    str += '-' + datas.roomName
  }
  return str
};



//去掉千分位
function removeMoneyThousand(s) {
  return parseFloat(s.replace(/[^\d\.-]/g, ""));
}

//日期过滤 201911 --> 2019-11
let dateSwitch = (val) => {
  if (val == '' || val == null) return
  return val.substring(0, 4) + '-' + val.substring(4, 6);
};


export { formatMoney, formatTime, formatMoneyThousand, removeMoneyThousand, dateSwitch, installAdressFormat, installAdressFormat2, areasFormat,formatDate };
