/*
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-04 17:27:36
 * @LastEditors: yql
 * @LastEditTime: 2020-09-10 18:10:30
 * @LastEditors: zengfq
 * @LastEditTime: 2020-04-28 10:04:12
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Picker,Popup,DatetimePicker,Switch,Toast,DropdownMenu,
  DropdownItem ,ActionSheet,List,Overlay,CheckboxGroup,Checkbox,
  CellGroup,Cell,Icon,Swipe,SwipeItem,ImagePreview,Search,NavBar,Tag,
  Button,Empty,Field,Notify
} from 'vant';


// css
import '@/assets/styles/swiper.min.css';
import '@/assets/styles/vant.scss';
import '@/assets/styles/style.scss' // reset css
import '@/permission' // permission control

import 'amfe-flexible'
import utils from './utils'
import * as filters from './utils/filters' // public filters
import messagejs from '@/components/message/message'
import pubVar from './utils/pubVar' // public variables

// common components
import footerCom from "@/components/footerCom/footerCom"




Toast.setDefaultOptions({ duration: 1000 });
Vue.use(utils)
Vue.use(Picker);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Switch);
Vue.use(Toast);
Vue.use(pubVar)
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ActionSheet);
Vue.use(List)
Vue.use(Overlay)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Icon)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(ImagePreview)
Vue.use(Search)
Vue.use(NavBar)
Vue.use(Tag)
Vue.use(Button)
Vue.use(Empty)
Vue.use(Field)
Vue.use(Notify)

//页面title动态改变
Vue.use(require('vue-wechat-title'));

Vue.config.productionTip = false

//loading component
Vue.prototype.load = new Vue;

//公共弹框
Vue.prototype.$confirm= messagejs

//公共组件footer
Vue.component("view-footer",footerCom);

//echarts挂载
// Vue.prototype.$echarts = echarts

Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));

new Vue({
  router,
  store,
  pubVar,
  render: h => h(App)
}).$mount('#app')
