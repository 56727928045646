/*
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-13 19:26:47
 * @LastEditors: yql
 * @LastEditTime: 2020-06-01 17:47:52
 */
import Vue from "vue";
import router from "./router";
import store from "./store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/login", "/test", "/terminal"]; // no redirect whitelist

router.beforeEach((to, from, next) => {
  Vue.prototype.load.$emit("loading", true);

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  NProgress.start(); // start progress bar

  const hasToken = window.localStorage.getItem("hodiToken");
  const hasProp = window.localStorage.getItem("prop");
  const hasContract = window.localStorage.getItem("contract");

  if (hasToken) {
    // NProgress.done()
    if (to.path === "/login" || to.path === "/") {
      console.log("to.path==" + to.path);
      if (hasContract) {
        next({ path: "/index" });
        // next()
      } else {
        next({ path: "/property" });
      }
      NProgress.done();
    } else if (to.path === "/property") {
      next();
      NProgress.done();
    } else {
      if (hasContract) {
        next();
      } else {
        next({ path: "/property" });
      }
    }
  } else {
    console.log("hasToken== nono");
    // alert(8)
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next(`/login?redirect=${to.path}`);
      // next({ path: '/login', query: { redirect: to.fullPath } })
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }

  Vue.prototype.load.$emit("loading", false);
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});
