var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "verifyCode", attrs: { id: "billDetail" } }, [
    _c("div", { staticClass: "billInfos" }, [
      _c("div", {
        staticClass: "iconImg tan",
        class: {
          dian: _vm.dataMode.billKind == 1,
          shui: _vm.dataMode.billKind == 2,
          shui: _vm.dataMode.billKind == 2,
          zu: _vm.dataMode.billKind == 3,
          guan: _vm.dataMode.billKind == 4,
          tan: _vm.dataMode.billKind == 5,
          tiao: _vm.dataMode.billKind == 98,
          zhang: _vm.dataMode.billKind == 99
        }
      }),
      _c("div", { staticClass: "text" }, [
        _c("h4", [_vm._v(_vm._s(_vm.billKindEnum[_vm.dataMode.billKind]))]),
        _c("p", [
          _vm._v(
            "￥" + _vm._s(_vm._f("formatMoneyThousand")(_vm.dataMode.amount))
          )
        ])
      ]),
      _vm.dataMode.billStatus == 1
        ? _c("div", { staticClass: "paidBox" }, [
            _c("img", {
              attrs: { src: require("../../../assets/images/paid.png") }
            })
          ])
        : _vm._e(),
      _vm.dataMode.billStatus == 2
        ? _c("div", { staticClass: "paidBox" }, [
            _c("img", {
              attrs: { src: require("../../../assets/images/dzf.png") }
            })
          ])
        : _vm._e(),
      _vm.dataMode.billStatus == 3
        ? _c("div", { staticClass: "paidBox" }, [
            _c("img", {
              attrs: { src: require("../../../assets/images/yqdzf.png") }
            })
          ])
        : _vm._e(),
      _vm.dataMode.billStatus == 4
        ? _c("div", { staticClass: "paidBox" }, [
            _c("img", {
              attrs: { src: require("../../../assets/images/payd.png") }
            })
          ])
        : _vm._e(),
      _vm.dataMode.billStatus == 5
        ? _c("div", { staticClass: "paidBox" }, [
            _c("img", {
              attrs: { src: require("../../../assets/images/wxzf.png") }
            })
          ])
        : _vm._e(),
      _vm.dataMode.billStatus == 6
        ? _c("div", { staticClass: "paidBox" }, [
            _c("img", {
              attrs: { src: require("../../../assets/images/dtk.png") }
            })
          ])
        : _vm._e(),
      _vm.dataMode.billStatus == 7
        ? _c("div", { staticClass: "paidBox" }, [
            _c("img", {
              attrs: { src: require("../../../assets/images/ytk.png") }
            })
          ])
        : _vm._e(),
      _c("div", { staticClass: "opeart" }, [
        _c("span", { on: { click: _vm.detaiEvent } }, [
          _vm.billDetaiVis
            ? _c("b", [_vm._v("收起")])
            : _c("b", [_vm._v("明细")])
        ])
      ])
    ]),
    _vm.billDetaiVis
      ? _c("div", { staticClass: "detailBox" }, [
          _c("div", { staticClass: "detailUnit" }, [
            _c("div", { staticClass: "label" }, [_vm._v("账单编号")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.dataMode.billNo))
            ])
          ]),
          _c("div", { staticClass: "detailUnit" }, [
            _c("div", { staticClass: "label" }, [_vm._v("账单类型")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.billKindEnum[_vm.dataMode.billKind]))
            ])
          ]),
          _c("div", { staticClass: "detailUnit" }, [
            _c("div", { staticClass: "label" }, [_vm._v("缴费状态")]),
            _vm.dataMode.payStatus === 0
              ? _c("div", { staticClass: "text " })
              : _vm._e(),
            _vm.dataMode.payStatus === 1
              ? _c("div", { staticClass: "text " }, [_vm._v("无需收款")])
              : _vm.dataMode.payStatus === 2
              ? _c("div", { staticClass: "text yellow" }, [_vm._v("待支付")])
              : _vm.dataMode.payStatus === 3
              ? _c("div", { staticClass: "text yellow" }, [_vm._v("待退款")])
              : _vm.dataMode.payStatus === 4
              ? _c("div", { staticClass: "text green" }, [_vm._v("已支付")])
              : _vm.dataMode.payStatus === 5
              ? _c("div", { staticClass: "text green" }, [_vm._v("已退款")])
              : _vm._e()
          ]),
          _c("div", { staticClass: "detailUnit" }, [
            _c("div", { staticClass: "label" }, [_vm._v("逾期状态")]),
            _vm.dataMode.deadline
              ? _c("div", { staticClass: "text red" }, [_vm._v("已逾期")])
              : _c("div", { staticClass: "text green" }, [_vm._v("未逾期")])
          ]),
          _c("div", { staticClass: "detailUnit" }, [
            _c("div", { staticClass: "label" }, [_vm._v("表计表号")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.dataMode.meterAddr))
            ])
          ]),
          _c("div", { staticClass: "detailUnit" }, [
            _c("div", { staticClass: "label" }, [_vm._v("结算月份")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.dataMode.period))
            ])
          ]),
          _c("div", { staticClass: "detailUnit" }, [
            _c("div", { staticClass: "label" }, [_vm._v("结算周期")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                _vm._s(_vm.dataMode.startDate) +
                  "至" +
                  _vm._s(_vm.dataMode.endDate)
              )
            ])
          ]),
          _vm.dataMode.billKind == 1
            ? _c("div", [
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("期初行度")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.dataMode.startValue))
                  ])
                ]),
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("期末行度")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.dataMode.endValue))
                  ])
                ]),
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("用电量")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.dataMode.useValue))
                  ])
                ]),
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("电费")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("formatMoneyThousand")(_vm.dataMode.amount)
                        )
                    )
                  ])
                ])
              ])
            : _vm.dataMode.billKind == 2
            ? _c("div", [
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("期初行度")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.dataMode.startValue))
                  ])
                ]),
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("期末行度")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.dataMode.endValue))
                  ])
                ]),
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("用水量")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.dataMode.useValue))
                  ])
                ]),
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("水费")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("formatMoneyThousand")(_vm.dataMode.amount)
                        )
                    )
                  ])
                ])
              ])
            : _vm.dataMode.billKind == 3
            ? _c("div", [
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("租金金额")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("formatMoneyThousand")(_vm.dataMode.amount)
                        )
                    )
                  ])
                ])
              ])
            : _vm.dataMode.billKind == 4
            ? _c("div", [
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("管理费")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("formatMoneyThousand")(_vm.dataMode.amount)
                        )
                    )
                  ])
                ])
              ])
            : _vm.dataMode.billKind == 5
            ? _c("div", [
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("账单金额")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("formatMoneyThousand")(_vm.dataMode.amount)
                        )
                    )
                  ])
                ]),
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("备注")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.dataMode.remark))
                  ])
                ])
              ])
            : _vm.dataMode.billKind == 98
            ? _c("div", [
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("原账单编号")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.dataMode.originalBillNo))
                  ])
                ]),
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("原账单类型")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(_vm.billKindEnum[_vm.dataMode.originalBillKind])
                    )
                  ])
                ])
              ])
            : _c("div", [
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("账单金额")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm._f("formatMoneyThousand")(_vm.dataMode.amount)
                        )
                    )
                  ])
                ]),
                _c("div", { staticClass: "detailUnit" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("备注")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.dataMode.remark))
                  ])
                ])
              ]),
          _c("div", { staticClass: "detailUnit" }, [
            _c("div", { staticClass: "label" }, [_vm._v("调整金额")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                "￥" +
                  _vm._s(
                    _vm._f("formatMoneyThousand")(_vm.dataMode.adjustAmount)
                  )
              )
            ])
          ]),
          _c("div", { staticClass: "detailUnit" }, [
            _c("div", { staticClass: "label" }, [_vm._v("调整原因")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.dataMode.adjustRemark))
            ])
          ]),
          _vm.dataMode.billKind == 1 || _vm.dataMode.billKind == 2
            ? _c("div", { staticClass: "detailUnit" }, [
                _c("div", { staticClass: "label" }, [_vm._v("安装地址")]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm._f("installAdressFormat2")(_vm.dataMode)))
                ])
              ])
            : _vm._e(),
          _vm.dataMode.billKind == 1
            ? _c("div", { staticClass: "detailUnit" }, [
                _c("div", { staticClass: "label" }, [_vm._v("应付电费")]),
                _c("div", { staticClass: "text bold red" }, [
                  _vm._v(
                    "￥" +
                      _vm._s(
                        _vm._f("formatMoneyThousand")(_vm.dataMode.actualAmount)
                      )
                  )
                ])
              ])
            : _vm.dataMode.billKind == 2
            ? _c("div", { staticClass: "detailUnit" }, [
                _c("div", { staticClass: "label" }, [_vm._v("应付水费")]),
                _c("div", { staticClass: "text bold red" }, [
                  _vm._v(
                    "￥" +
                      _vm._s(
                        _vm._f("formatMoneyThousand")(_vm.dataMode.actualAmount)
                      )
                  )
                ])
              ])
            : _c("div", { staticClass: "detailUnit" }, [
                _c("div", { staticClass: "label" }, [_vm._v("应付金额")]),
                _c("div", { staticClass: "text bold red" }, [
                  _vm._v(
                    "￥" +
                      _vm._s(
                        _vm._f("formatMoneyThousand")(_vm.dataMode.actualAmount)
                      )
                  )
                ])
              ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }