<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-04-29 09:00:03
 -->
<template>
    <div class="changePhone" id="changePhone">
        <div class="formBox">
            <div class="formUnit">
                <div class="label">手机号码</div>
                <div class="inputBox">
                    <div class="inputer"><input type="number"   @blur="trims" placeholder="请输入手机号码" v-model="submitMode.mobile"/></div>
                    <div class="coder">
                        <button :disabled="codeBtnVis" @click="getCode" v-if="!codeSecondVis">获取验证码</button>
                        <button disabled v-else><span>{{seconds}}秒后重发</span></button>
                    </div>
                </div>
            </div>

            <div class="formUnit">
                <div class="label">短信验证码</div>
                <div class="inputBox">
                    <div class="inputer "><input type="number" class="gray"  v-model="submitMode.verifyCode" @blur="trims" placeholder="请输入短信验证码" /></div>
                </div>
            </div>

            <div class="btnAreaBox">
                <div class="btnDiv"><button class="btn" @click="bindPhoneEvent">绑定</button></div>
            </div>


        </div>



        <view-footer></view-footer>
    </div>
</template>
<script>
export default {
    name: "changePhone",
    data() {
        return {
            submitMode:{
                "mobile": "",
                "verifyCode": ""
            },
            seconds:60,
            codeBtnVis:false,
            codeSecondVis:false,
            timer:null
        };
    },
    mounted() {  
    },
    created(){
        // this.getMainList()
    },
    methods: {
        inputBlur(){
            // window.scrollTo(0, 0);
             let scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
            window.scrollTo(0, Math.max(scrollHeight - 1, 0));
        },
        //获取主列表
        getMainList(){
            this.load.$emit('loading', true)
            this.$store.dispatch('property/userInfo',{}).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.submitMode.mobile = result.data.mobile
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        },
        //获取验证码
        getCode(){
            if(this.submitMode.mobile === ''  || this.submitMode.mobile == null){
                this.$confirm({
                    mesg: "请输入手机号",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }else if(!this.validMobile(this.submitMode.mobile)){
                this.$confirm({
                    mesg: "手机号格式不正确",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }
            this.codeBtnVis = true
            this.$store.dispatch('property/newMobileSend',{mobile:this.submitMode.mobile}).then((result) => {
                this.codeBtnVis = false
                if (result.returnCode == 0) {
                    this.codeSecondVis = true
                    this.secondsShow()
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        },
        //验证码倒计时
        secondsShow(){
            let self = this           
            this.timer = setInterval(()=>{
                self.seconds--
                if(self.seconds == 0){
                   clearInterval(self.timer);
                   this.codeSecondVis = false
                   self.seconds = 60
                }
            },1000)
        },
        bindPhoneEvent(){
            if(this.submitMode.verifyCode === '' || this.submitMode.mobile == null){
                this.$confirm({
                    mesg: "请输入短信验证码",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }
            this.load.$emit('loading', true)
            this.$store.dispatch('property/editMobile',this.submitMode).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.$toast.success('手机号绑定成功')
                    this.$store.dispatch('user/logout')
                    this.$store.dispatch('property/removeContract')
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        }
    }
};
</script>
