<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2020-04-24 11:07:51
 * @LastEditors: yql
 * @LastEditTime: 2020-05-26 19:00:30
 -->

<template>
    <div class="login">
        <div class="content">
            <div class="title">您好，</div>
            <div class="text">欢迎使用{{propName}}</div>
            <div class="phoneBox">
                <div class="inputBox userName">
                    <!-- <input type="number" v-model.trim="submitMode.mobile" @blur="inputBlur" placeholder="请输入在物业登记的手机号码"  /> -->
                    <div class="inputer"><input type="tel"   @blur="inputBlur" placeholder="请输入在物业登记的手机号码" v-model="submitMode.mobile"/></div>
                </div>
                <div class="formUnit">
                    <!-- <div class="label"><img src="../../assets/images/icon_password.png"/></div> -->
                    <div class="inputBox">
                        <div class="inputer "><input type="tel" class="gray" @blur="inputBlur"  v-model="submitMode.verifyCode" placeholder="请输入短信验证码" /></div>
                        <div class="coder">
                            <button :disabled="codeBtnVis" @click="getCode" v-if="!codeSecondVis" class="getCodeBtn">获取验证码</button>
                            <button disabled v-else><span>{{seconds}}秒后重发</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="getCode">
                <button class="btn" @click="login()" :disabled="loginBtnIs">登录</button>
            </div>
        </div>
        <!-- <verifyCode ref="verifyCode" :verifyCodeDialog="verifyCodeDialog" :submitMode="submitMode" @codeBtnDis="codeBtnDis"></verifyCode> -->
        <view-footer></view-footer>
    </div>
</template>

<script>
import verifyCode from './components/verifyCode'
export default {
    name: "login",
    components: {verifyCode},
    data(){
        return{
            phoneNum:'',
            codeBtnVis:false,
            verifyCodeDialog:{
                dialogVis:false
            },
            propName:'',
            codeSecondVis:false,
            seconds:60,
            submitMode:{
                'mobile':'',
                'verifyCode':''
            },
            loginBtnIs:false,
            redirect:null
        }
    },
    watch: {
      $route: {
        handler: function (route) {
          this.redirect = route.query && route.query.redirect
        },
        immediate: true
      }
    },
    created(){
        this.getPropName()
        this.windowResize()
    },
    methods:{
        inputBlur(e){
            let str = e.target.value.toString()
            e.target.value = str.replace(/(^\s*)|(\s*$)/g, "");
            e.target.dispatchEvent(new Event('input'));
             let scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
            window.scrollTo(0, Math.max(scrollHeight - 1, 0));
            window.scrollTo(0, 0);
        },
        //浏览器窗口resize
        windowResize(){
            let self = this
            let focusElem = document.getElementById('#inputLabel')
            window.addEventListener("resize", function() {
                if(focusElem && document.body.clientHeight < this.clientHeight && this.verifyCodeDialog.dialogVis) {
                    //使用scrollIntoView方法来控制输入框
                    focusElem.scrollIntoView(false);
                }
                    
            })
        },
        //获取验证码按钮disabled
        codeBtnDis(){
            this.codeBtnVis = true
        },
        //input失去焦点Event
        inputBlur(){
            let scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
            window.scrollTo(0, Math.max(scrollHeight - 1, 0));
            window.scrollTo(0, 0);
        },
        // 获取物业名称
        getPropName(){
            this.load.$emit('loading', true)
            this.$store.dispatch('property/propName', {}).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.propName = result.data
                } else {
                    this.$confirm({
                    mesg: result.returnMsg,
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                }
            });
        },
        //获取验证码
        getCode(){
            if(this.submitMode.mobile === ''  || this.submitMode.mobile == null){
                this.$confirm({
                    mesg: "请输入手机号",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }else if(!this.validMobile(this.submitMode.mobile)){
                this.$confirm({
                    mesg: "手机号格式不正确",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }
            this.codeBtnVis = true
            this.load.$emit('loading', true)
            this.$store.dispatch('user/sendVerifyCode',{mobile:this.submitMode.mobile}).then((result) => {
                this.load.$emit('loading', false)
                this.codeBtnVis = false
                if (result.returnCode == 0) {
                    this.codeSecondVis = true
                    this.secondsShow()
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        //验证码倒计时
        secondsShow(){
            let self = this           
            this.timer = setInterval(()=>{
                self.seconds--
                if(self.seconds == 0){
                   clearInterval(self.timer);
                   this.codeSecondVis = false
                   self.seconds = 60
                }
            },1000)
        },
        //登录
        login(){

            if(this.submitMode.mobile === ''  || this.submitMode.mobile == null){
                this.$confirm({
                    mesg: "请输入手机号",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }else if(!this.validMobile(this.submitMode.mobile)){
                this.$confirm({
                    mesg: "手机号格式不正确",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }else if(this.submitMode.verifyCode === '' || this.submitMode.mobile == null){
                this.$confirm({
                    mesg: "请输入短信验证码",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }

            this.load.$emit('loading', true)
            this.$store.dispatch('user/login', this.submitMode).then((result) => {
                if (result.returnCode == 0) {
                    this.loginBtnIs = true
                    this.codeBtnVis = true
                    this.$emit('codeBtnDis')
                    window.localStorage.setItem('hodiToken', result.data.token)
                    this.$store.dispatch('user/setToken',result.data.token)

                    if (!window.location.origin || window.location.origin == undefined) {
                        window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
                    }
                    let redirecter = window.location.origin + '/#/property'
                                       
                    if(result.data.needWechatAuthorization){
                        let url
                        if(window.localStorage.getItem('prop')){
                            
                            url = result.data.wechatAuthorizationUrl + '&prop='+window.localStorage.getItem('prop')+'&redirect=' + encodeURIComponent(redirecter)
                        }else{
                            url = result.data.wechatAuthorizationUrl +'&redirect='  + encodeURIComponent(redirecter)
                        }
                        // alert('aaa=='+url)
                        // alert(result.data.wechatAuthorizationUrl)
                        window.location.href = url  //decodeURIComponent
                    }else{
                        // alert('bbb=='+redirecter)
                        window.location.href = redirecter
                        window.location.reload()
                    }
                     this.load.$emit('loading', false)
                } else {
                     this.load.$emit('loading', false)
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });

        }
    }
};
</script>
