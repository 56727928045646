var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "mask",
      attrs: { id: "loading" }
    },
    [
      _c("div", { staticClass: "box" }, [
        _c("img", {
          attrs: { src: require("../../assets/images/loading2.gif") }
        }),
        _c("div", {
          staticClass: "infosBox",
          domProps: { innerHTML: _vm._s(_vm.infos) }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }