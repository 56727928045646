var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accountTransfer", attrs: { id: "accountTransfer" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "formBox" }, [
        _c("div", { staticClass: "formUnit" }, [
          _c("div", { staticClass: "label" }, [_vm._v("当前手机号")]),
          _c("div", { staticClass: "inputBox" }, [
            _c("div", { staticClass: "inputer" }, [
              _c("input", {
                attrs: { type: "number", placeholder: "请输入手机号码" },
                on: { blur: _vm.trims }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "formUnit" }, [
          _c("div", { staticClass: "label" }, [_vm._v("输入验证码")]),
          _c("div", { staticClass: "inputBox" }, [
            _c("div", { staticClass: "inputer " }, [
              _c("input", {
                staticClass: "gray",
                attrs: { type: "number", placeholder: "请输入验证码" },
                on: { blur: _vm.trims }
              })
            ]),
            _c("div", { staticClass: "coder" }, [
              _c("button", { attrs: { disabled: true } }, [
                _vm._v("45秒后重发")
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "formUnit" }, [
          _c("div", { staticClass: "label" }, [_vm._v("输入新号码")]),
          _c("div", { staticClass: "inputBox" }, [
            _c("div", { staticClass: "inputer " }, [
              _c("input", {
                staticClass: "gray",
                attrs: { type: "number", placeholder: "请输入新号码" },
                on: { blur: _vm.trims }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "formUnit" }, [
          _c("div", { staticClass: "label" }, [_vm._v("账号名称")]),
          _c("div", { staticClass: "inputBox" }, [
            _c("div", { staticClass: "inputer " }, [
              _c("input", {
                staticClass: "gray",
                attrs: { type: "number", placeholder: "请输入账号名称" },
                on: { blur: _vm.trims }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "btnAreaBox" }, [
          _c("div", { staticClass: "btnDiv" }, [
            _c(
              "button",
              { staticClass: "btn", on: { click: _vm.tranferSubmit } },
              [_vm._v("保存")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "infoBox" }, [
      _c("h2", [_vm._v("主账号转移")]),
      _c("p", [_vm._v("转移后，当前手机号码将被注销退出。")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }