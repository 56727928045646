<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-04 17:27:36
 * @LastEditors: yal
 * @LastEditTime: 2020-03-03 16:21:11
 -->
<template>
    <div class="verifyCode" id="verifyCode" v-if="verifyCodeDialog.dialogVis">
        <div class="mackWeb"  id="inputLabel">
            <!-- <div class="title" >标题</div> -->
            <div class="content">
                <div class="mesg">
                    <p class="info">请输入收到的验证码</p>
                    <p class="error"><span  v-if="errorIs">{{errorInfo}}</span></p>
                    <!-- <div class="timeShow">({{second}}秒)</div> -->
                </div>
                <div class="codeInput">
                    <label for="inputBox"  >
                        <div class="valueShow" @click="labelEvent">
                            <div class="field-wrap">{{submitMode.verifyCode[0]}}</div>
                            <div class="field-wrap">{{submitMode.verifyCode[1]}}</div>
                            <div class="field-wrap">{{submitMode.verifyCode[2]}}</div>
                            <div class="field-wrap">{{submitMode.verifyCode[3]}}</div>
                        </div>
                    </label>
                    <input ref="input" class="input-code" v-model.trim="submitMode.verifyCode" id="inputBox" name="inputBox" type="number" maxlength="4" autocorrect="off" autocomplete="off" autocapitalize="off" @input="limitEvent" @blur="inputBlur">
                </div>
                <!-- <div v-html="options.cntMsg" class="cntMsg"></div> -->
            </div>
            <!-- <input  class="hiddenInput" type="number"   name=""  :maxlength="verCodeNumber"  v-model="inputValue"  bindinput="Focus" :focus="isFocus"  @input="!Focus" /> -->
            <div class="footer">
                <div class="cancleBtn" @click="cancel" >取 消</div>
                <div class="sureBtn"  @click="confirm" >确 定</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "verifyCode",
    components: {},
    data(){
        return{
          clientHeight:0,
          timer:null,
          seconds:60,
          errorIs:false,
          errorInfo:'',
          cancleVis:true
        }
    },
    props:{
        verifyCodeDialog:Object,
        submitMode:Object
    },
    watch:{
    },
    mounted(){
        this.clientHeight = document.body.clientHeight
    },
    created(){
        this.windowResize()
        //this.countDown()
    },
    methods:{
        inputBlur(){
            
             let scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
            window.scrollTo(0, Math.max(scrollHeight - 1, 0));
            window.scrollTo(0, 0);
        },
        // 验证码过时倒计时
        countDown(){
            let self = this
            clearInterval(self.timer)
            this.seconds = 60
            this.timer = setInterval(() => {
                self.seconds--;
                if(self.seconds == 0){
                    this.cancleVis = false
                    clearInterval(self.timer)
                    return 
                }
            }, 1000);
        },
        //验证码限制4位
        limitEvent(e){
            this.errorIs = false;
            let str = e.target.value.toString()
            if(str.length>4){
                e.target.value = str.slice(0,4).replace(/(^\s*)|(\s*$)/g, "");
                e.target.dispatchEvent(new Event('input'));
            }
        },
        //浏览器窗口resize
        windowResize(){
            let self = this
            let focusElem = document.getElementById('#inputLabel')
            window.addEventListener("resize", function() {
                if(focusElem && document.body.clientHeight < this.clientHeight && this.verifyCodeDialog.dialogVis) {
                    //使用scrollIntoView方法来控制输入框
                    focusElem.scrollIntoView(false);
                }
                    
            })
        },
        //键盘隐藏
        hideKeyboard () {
            // 输入完成隐藏键盘
            document.activeElement.blur() // ios隐藏键盘
            this.$refs.input.blur() // android隐藏键盘

        },
        //弹框一如可视区域
        labelEvent(){
            document.querySelector('#inputLabel').scrollIntoView();
        },
        //登录提交
        confirm(){
            this.load.$emit('loading', true)
            this.$store.dispatch('user/login', this.submitMode).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    
                    this.$emit('codeBtnDis')
                    window.localStorage.setItem('token', result.data.token)
                    this.$store.dispatch('user/setToken',result.data.token)

                    let redirecter = window.location.origin+'/#/property'

                    if(result.data.needWechatAuthorization){
                        let url
                        if(window.localStorage.getItem('prop')){
                            url = result.data.wechatAuthorizationUrl + '&prop='+window.localStorage.getItem('prop')+'&redirect=' + encodeURIComponent(redirecter)
                        }else{
                            url = result.data.wechatAuthorizationUrl +'&redirect='  + encodeURIComponent(redirecter)
                        }
                        window.location.href = url  //decodeURIComponent
                    }else{
                        window.location.href = redirecter
                        window.location.reload()
                    }
                    this.verifyCodeDialog.dialogVis = false
                } else {
                    this.errorIs = true;
                    this.errorInfo = result.returnMsg
                }
            });

            
        },
        // 登录权限
        author(data){
            this.$store.dispatch('user/isWechatClient', {}).then((result) => {
                if (result.returnCode == 0) {
                     if(result.data){
                         let url = window.origin + '/gateway/prop/authorization?userCode=' + data.userCode+' &prop='+window.localStorage.getItem('prop')+'&redirect=/#/property'
                         window.location.href = url
                     }else{
                          window.location.href = window.origin+'/property'
                          window.location.reload()
                     }   
                } else {

                }
            });
        },
        //取消登录
        cancel(){
            // if(this.seconds === 0){
                this.cancleVis = true
                this.errorIs = false
                this.errorInfo = ''
                this.submitMode.verifyCode = ''
                // this.seconds = 60
                this.verifyCodeDialog.dialogVis = false
                this.$emit('codeBtnDisTure')
            // }else{
            //     return false
            // }
            
        },
        beforeDestroy(){
            console.log('beforeDestroy')
            clearInterval(this.timer)
        },
        destroyed(){
            console.log('destroyed')
            clearInterval(this.timer)
        }
    }
}
</script>

<style scoped lang='scss'>
@import "~@/assets/styles//common.scss";
.verifyCode {
   @extend .fixedBody;
   @extend .centerCenter;
   position: absolute;
   background-color: rgba($color: #000000, $alpha: 0.3);
   z-index:100;
    .mackWeb{

        width:600px;
        background-color: #fff;
        border-radius:20px;
        padding-top: 10px;
        .title{
            padding-top:35px;
            text-align: center;
            font-size: 35px;
            padding:20px 10px;
        }
        .content{
            padding:65px;
            padding-top:55px;
            @include borderAfter;
            .mesg{
            
                text-align: center;
                font-size: 28px;
                color:$grayColor;
                line-height: 35px;
                font-size:32px;
                padding-bottom: 15px;
                p{
                    &.info{
                        padding-bottom: 30px;
                    }
                    &.error{
                        color:$red;
                        font-size:26px;
                         height: 30px;
                         line-height: 30px;
                    } 
                }
            }
            .cntMsg{
                text-align: center;
                font-size: 28px;
                color:$grayColor;
                line-height: 35px;
            }
            .codeInput{
                overflow: hidden;
                font-size: 22px;
                .valueShow{
                    position: relative;
                    background-color: #fff;
                    @extend .flexBox;
                    padding-top:45px;
                
                    .field-wrap{               
                        @extend .flex;
                        position: relative;
                        width: 75px;
                        // padding-top: 30px;
                        height: 75px;
                        margin-right: 25px;
                        font-size: 46px;
                        color: $wordColor;
                        line-height: 75px;
                        text-align: center;
                        &:nth-of-type(4){
                            margin-right: 0
                        }
                        &:after{
                            content: " ";
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            height: 1px;
                            border-bottom: 4px solid $borderColor2;
                            color: $borderColor;
                            -webkit-transform-origin: 0 100%;
                            transform-origin: 0 100%;
                            -webkit-transform: scaleY(0.5);
                            transform: scaleY(0.5);
                        }

                    }
                }
                .input-code {
                    position: absolute;
                    left: -9999px;
                    top: -9999px;
                }
            }

        }
        .footer{
            position: relative;
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            font-size: 32px;
            text-align: center;
            height: 104px;
            line-height: 104px;
            
            .cancleBtn{
                border-bottom-left-radius: 20px;
                -webkit-box-flex: 1;
                -webkit-flex: 1;
                flex: 1;
                color:$wordColor;
                @include borderRight;
                &.disableVis{
                    background-color:#ecebeb;
                    color:#999;
                }
            }
            .sureBtn{
                -webkit-box-flex: 1;
                -webkit-flex: 1;
                flex: 1;
                color:$blue;
                
            }
        }
        
    }
}
</style>
