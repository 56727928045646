<!--
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2021-01-27 10:24:25
 * @LastEditors: yql
 * @LastEditTime: 2021-02-05 11:49:31
-->

 <template>
    <div class="chartReport">
        <div class="repdate">
            <div class="dateInfobox">
                <div class="dateInfobox-pre" @click="setDay('-')">
                    <van-icon name="arrow-left" size="0.4rem" />
                </div>
                <div class="dateInfobox-content" @click="showYearPicker = true">
                    <van-icon name="calendar-o" size="0.4rem" />&nbsp;&nbsp; <span class="dateInfobox-content__content">{{dateFormat('yyyy-MM',now)}}</span>
                </div>
                <div class="dateInfobox-next" @click="setDay('+')">
                    <van-icon name="arrow" size="0.4rem" :color="nextColor"/>
                </div>
            </div>
        </div>
        <div class="rephead">
          <div>
            <div class="rephead-item">
                <p>总电费</p>
                <p class="rephead-item__num">
                  <span class="ecletval">{{sumData.amount  | formatMoneyThousand}}</span>
                  <span>元</span> </p>
            </div>
            <div class="rephead-item">
              <p>月总用电量</p>
              <p class="rephead-item__num"><span class="ecletval">{{sumData.usage | setNull}}</span> <span>度</span></p>
            </div>
          </div>

          <div>
            <div class="rephead-item">
              <p>平均电价</p>
              <p class="rephead-item__num">
                <span class="ecletval">{{sumData.avgPrice  | formatMoneyThousand}}</span> <span>元</span>
              </p>
            </div>
            <div class="rephead-item">
              <p>倍率</p>
              <p class="rephead-item__num"><span class="ecletval">{{sumData.ct | setNull}}</span> </p>
            </div>
          </div>

        </div>
      <van-swipe >
        <van-swipe-item v-for="repData in repDataList">
      <div class="repcontent">
        <div class="repcontent-head">
          <h3>用电详细</h3>
        </div>
        <div class="repcontent-item">
          <div>开始时间：</div>
          <div>{{repData.startTime}}</div>
        </div>
        <div class="repcontent-item">
          <div>结束时间：</div>
          <div>{{repData.endTime}}</div>
        </div>
        <div class="repcontent-item">
          <div>起始行度：</div>
          <div> <strong>{{repData.startValue | setNull}}kWh</strong> </div>
        </div>
        <div class="repcontent-item">
          <div>结束行度：</div>
          <div><strong>{{repData.endValue | setNull}}kWh</strong> </div>
        </div>
        <div v-show="repData.valuationKind==1">
          <div class="repcontent-item">
            <div> <strong class="cblack">用电金额：</strong> </div>
            <div> <strong class="cbule">￥{{repData.amount | formatMoneyThousand}}元</strong> </div>
          </div>
        </div>
        <div v-show="repData.valuationKind==2">
          <div class="repcontent-item">
            <div> <strong class="cblack">尖用电金额：</strong> </div>
            <div> <strong class="cbule">￥{{repData.tipAmount | formatMoneyThousand}}元/度</strong> </div>
          </div>
          <div class="repcontent-item">
            <div>电价：<strong>￥{{repData.tipPrice | setNull}}元/度</strong></div>
            <div>用电量：<strong>{{repData.tipUsage | setNull}}kWh</strong> </div>
          </div>
          <div class="repcontent-item">
            <div> <strong class="cblack">峰用电金额：</strong></div>
            <div> <strong class="cbule">￥{{repData.peakAmount | formatMoneyThousand}}元/度</strong> </div>
          </div>
          <div class="repcontent-item">
            <div>电价：<strong>￥{{repData.peakPrice | setNull}}元/度</strong></div>
            <div>用电量：<strong>{{repData.peakUsage | setNull}}kWh</strong> </div>
          </div>
          <div class="repcontent-item">
            <div> <strong class="cblack">平用电金额：</strong> </div>
            <div> <strong class="cbule">￥{{repData.flatAmount | formatMoneyThousand}}元/度</strong> </div>
          </div>
          <div class="repcontent-item">
            <div>电价：<strong>￥{{repData.flatPrice | setNull}}元/度</strong> </div>
            <div>用电量：<strong>{{repData.flatUsage | setNull}}kWh</strong> </div>
          </div>
          <div class="repcontent-item">
            <div><strong class="cblack">谷用电金额：</strong> </div>
            <div> <strong class="cbule">￥{{repData.ancientAmount | formatMoneyThousand}}元/度</strong> </div>
          </div>
          <div class="repcontent-item">
            <div>电价：<strong>￥{{repData.ancientPrice | setNull}}元/度</strong> </div>
            <div>用电量：<strong>{{repData.ancientUsage | setNull}}kWh</strong> </div>
          </div>
        </div>

      </div>
        </van-swipe-item>
      </van-swipe>
        <div class="goIndex" @click="$router.push('/index')" style="top:0"></div>
        <van-popup v-model="showYearPicker" round position="bottom">
            <van-datetime-picker v-model="nowTmp" type="year-month" @confirm="onYearConfirm" @cancel="showYearPicker = false" :max-date="maxDate"/>
        </van-popup>
    </div>
</template>
 <script>
   import m from 'moment'
export default {
  data() {
    return {
      showYearPicker: false,
      now: m().toDate(),
      nowTmp: m().toDate(),
      maxDate:m().toDate(),
      meterAddr: this.$route.query.meterAddr,
      sumData:{},
      repDataList:[]
    }
  },
  computed: {
    nextColor(){
       if(m(this.nowTmp).year()==m().year()){
          if(m(this.nowTmp).month()>=m().month()){
            return '#f7f8fa'
          }
       }
    }
  },
  created() {
    this.getData()
  },
  mounted() {},
  filters: {
    setNull: function(v) {
      return v || '--'
    },
    setZero: function(v) {
      return v || 0
    }
  },
  methods: {
    setDay(type) {
      if (type=='-'){
        this.now = m(this.now).subtract(1,'month').toDate()
      }else{
        if(this.nextColor){
          return
        }
        this.now = m(this.now).add(1,'month').toDate()
      }
      this.nowTmp=m(this.now).toDate()
      this.getData()
    },
    getData() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$store
        .dispatch('meter/monthReport', {
          meterAddr: this.meterAddr,
          yearMonth: m(this.now).format('yyyy-MM')
        })
        .then(result => {
          this.$toast.clear()
          if(result.returnCode==0){
            this.sumData=result.data
            if(result.data && Object.hasOwnProperty.call(result.data,'datas'))
            {
              this.repDataList=result.data.datas
            }
          }else {
              this.$toast.fail("查询失败")
            this.sumData={}
            this.repDataList=[]
          }
        })
        .catch(error => {
          this.$toast.clear()
        })
    },
    onYearConfirm(value) {
      this.now =m(value).toDate()
      this.showYearPicker = false
      this.getData()
    },
    dateFormat(fmt, date) {
      return m(date).format(fmt)
    },

  }
}
</script>
 <style lang="scss" scoped>
.chartReport {
    padding: 0 20px;
    overflow: hidden;
}
.repdate {
    display: flex;
    justify-content: center;
    margin: 20px;
}
.dateInfobox-pre {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 100px;
}
.dateInfobox-next {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 100px;
}
.dateInfobox-content {
    display: inline-block;
    vertical-align: middle;
}
.dateInfobox-content__content {
    font-size: 42px;
}
.rephead {
    border-radius: 20px;
    margin-top: 22px;
    padding: 32px 0;
    display: flex;

    justify-content: space-evenly;
    font-size: 24px;
    background-image: url("../../assets/images/report_back.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
}

.rephead-item {
    .ecletval {
        font-size: 50px;
        font-weight: bold;
    }
}

.rephead-item__num {
    text-align: right;
    font-size: 12px;
}

.repcontent {
    margin: 32px 12px;
    padding: 12px 35px;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px rgb(0 0 0 / 10%);
}
.repcontent-head {
    font-size: 32px;
    line-height: 2.5;
}
.repcontent-item {
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    line-height: 2;
}
.repcontent-item:nth-of-type(odd) {
    padding-bottom: 10px;
}

.cbule {
    color: #28a2eb;
    display: inline-block;
    line-height: 1;
    font-size: 32px;
}
.cblack {
    color: #666666;
}

</style>
