<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-04 17:27:36
 * @LastEditors: yql
 * @LastEditTime: 2019-12-16 15:50:07
 -->
<template>
    <div id="searchSwitch">
        
        <span @click="switchstatusEvent" v-if="btnVis" class="ls">查询</span>
        <span class="ls" v-if="searchingVis"><span class="van-loading__spinner van-loading__spinner--spinner"><svg viewBox="25 25 50 50" class="van-loading__circular"><circle cx="50" cy="50" r="20" fill="none"></circle></svg></span></span>
        <span class="ls borderNone"  v-if="searchresultVis">{{switchStatus}}</span>
    </div>
</template>

<script>

export default {
    name: "searchSwitch",
    components: {},
    data(){
        return{
            btnVis:true,
            switchStatus:'',
            searchingVis:false,
            searchresultVis:false
        }
    },
    props:{
        fid:''
    },
    watch:{
    },
    mounted(){
    },
    created(){
        this.billKindEnum = this.billKindEnums()
        this.originalBillKindEnum = this.originalBillKindEnums()
        this.billStatusEnum = this.billStatusEnums()
    },
    methods:{
        //拉/合闸
        switchstatusEvent(){
            this.btnVis = false
            this.searchingVis = true
            this.$store.dispatch('property/switchstatusQuery',{fid:this.fid}).then((result) => {
                this.searchingVis = false
                if (result.returnCode == 0) {
                    this.btnVis = false
                    this.searchresultVis = true
                    if(result.data){
                        this.switchStatus = '已合闸'
                    }else{
                        this.switchStatus = '已拉闸'
                    }
                    
                } else {
                    this.btnVis = true;
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        }
    }
}
</script>
