/*
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-13 18:49:21
 * @LastEditors: yql
 * @LastEditTime: 2020-09-10 13:57:23
 */

import { propName,getPropInfo,userInfo,electAlarmToday,billMonthPay,electToday,billPayList,electPrepayment,currentPrepayment,electAlarmList,billList,contractInfo,updateStatus,switchstatusQuery,getAreaList,getBuildingList,getRoomList,listTenant,deleteTenant,addTenant,newMobileSend,oldMobileSend,verifyCode,editEmail,editName,computeAmount,electricRecharge,billPay,payMerList,editMobile,offlinePay,offlinePayInfo,
  getYearData,getMonthData,getAllBill,getBillInfo,getAlarmHistory,getCurrentAlarm,rechargeRecord,billPayment,billDetaileds,editUserSmsConfig,packageList,getUserSmsOrder,getUserSmsConfig,smsPay} from '@/api/property'


const state = {
  prop: '',
  contract: '',
  propertyName:'',
  roomName:''
}

const mutations = {
  SET_PROP: (state, data) => {
    state.prop = data
  },
  SET_CONTRACT: (state, data) => {
    state.contract = data
  },
  SET_PROPERTYNAME: (state, data) => {
    state.propertyName = data
  },
  SET_ROOMNAME: (state, data) => {
    state.roomName = data
  }
}

const actions = {
  //短息购买
  smsPay({ commit }, data) {
    return new Promise((resolve, reject) => {
      smsPay(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  //获取用户短信配置
  getUserSmsConfig({ commit }, data) {
    return new Promise((resolve, reject) => {
      getUserSmsConfig(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  //获取用户购买短信套餐记录
  getUserSmsOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      getUserSmsOrder(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  //获取短信购买套列表
  packageList({ commit }, data) {
    return new Promise((resolve, reject) => {
      packageList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  //修改用户短信配置
  editUserSmsConfig({ commit }, data) {
    return new Promise((resolve, reject) => {
      editUserSmsConfig(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  //设置物业ID
  setProp({ commit }, data) {
    return new Promise(resolve => {
      if(data){
        commit('SET_PROP', data)
      }else{
        commit('SET_PROP', window.localStorage.getItem('prop'))
      }
      resolve()
    })
  },

  //清除物业ID
  removeProp({ commit }) {
    return new Promise(resolve => {
      window.localStorage.removeItem('prop')
      commit('SET_PROP', '')
      resolve()
    })
  },

  //设置合同ID
  setContract({ commit }, data) {
    return new Promise(resolve => {
      if(data){
        commit('SET_CONTRACT', data)
      }else{
        commit('SET_CONTRACT', window.localStorage.getItem('contract'))
      }
      resolve()
    })
  },

  //设置房间信息
  setRoom({ commit }, data) {
    return new Promise(resolve => {
      if(data){
        commit('SET_PROPERTYNAME', data)
        commit('SET_ROOMNAME', data)
      }else{
        commit('SET_PROPERTYNAME', window.localStorage.getItem('propertyName'))
        commit('SET_ROOMNAME', window.localStorage.getItem('roomName'))
      }
      resolve()
    })
  },

  //清除合同ID
  removeContract({ commit }) {
    return new Promise(resolve => {
        commit('SET_CONTRACT', '')
        window.localStorage.removeItem('contract')
      resolve()
    })
  },

  //获取用户入驻信息
  propName({ commit }, data) {
    return new Promise((resolve, reject) => {
      propName(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  //获取用户入驻信息
  getPropInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      getPropInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //获取用户信息
  userInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      userInfo(data).then(response => {
        if(response.returnCode == 0){
          commit('SET_PROPERTYNAME', response.data.propName)
          localStorage.setItem('propertyName',response.data.propName)
          let address = response.data.buildName+'-'+response.data.floorName+'-'+response.data.roomName
          commit('SET_ROOMNAME', address)
          localStorage.setItem('roomName',address)
          resolve(response)
        }else{
          resolve(response)
        }
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  //获取本日告警总数与最新1条数据
  electAlarmToday({ commit }, data) {
    return new Promise((resolve, reject) => {
      electAlarmToday(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //获取客户本月账单类型缴金额汇总
  billMonthPay({ commit }, data) {
    return new Promise((resolve, reject) => {
      billMonthPay(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //获取合同后付费/预付费电表当天数据
  electToday({ commit }, data) {
    return new Promise((resolve, reject) => {
      electToday(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //账单缴费列表
  billPayList({ commit }, data) {
    return new Promise((resolve, reject) => {
      billPayList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //获取充值电表列表
  electPrepayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      electPrepayment(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //实时获取充值电表数据
  currentPrepayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      currentPrepayment(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //告警查询
  electAlarmList({ commit }, data) {
    return new Promise((resolve, reject) => {
      electAlarmList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //我的账单
  billList({ commit }, data) {
    return new Promise((resolve, reject) => {
      billList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //我的服务
  contractInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      contractInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //拉/合闸
  updateStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateStatus(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //闸查询
  switchstatusQuery({ commit }, data) {
    return new Promise((resolve, reject) => {
      switchstatusQuery(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //
  //获取合同全部园区
  getAreaList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAreaList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //获取合同园区下的建筑
  getBuildingList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getBuildingList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //获取合同园区下的单元
  getRoomList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getRoomList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //获取客户租客列表
  listTenant({ commit }, data) {
    return new Promise((resolve, reject) => {
      listTenant(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //删除租户账号
  deleteTenant({ commit }, data) {
    return new Promise((resolve, reject) => {
      deleteTenant(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //增加租户账号
  addTenant({ commit }, data) {
    return new Promise((resolve, reject) => {
      addTenant(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
   //旧手机号发送验证码
   oldMobileSend({ commit }, data) {
    return new Promise((resolve, reject) => {
      oldMobileSend(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //发送手机验证码
  newMobileSend({ commit }, data) {
    return new Promise((resolve, reject) => {
      newMobileSend(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //验证手机短信验证码
  verifyCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      verifyCode(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //改绑手机号
  editMobile({ commit }, data) {
    return new Promise((resolve, reject) => {
      editMobile(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //修改邮箱
  editEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      editEmail(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //修改用户名
  editName({ commit }, data) {
    return new Promise((resolve, reject) => {
      editName(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //修改用户名
  computeAmount({ commit }, data) {
    return new Promise((resolve, reject) => {
      computeAmount(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //电费充值
  electricRecharge({ commit }, data) {
    return new Promise((resolve, reject) => {
      electricRecharge(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //账单缴费
  billPay({ commit }, data) {
    return new Promise((resolve, reject) => {
      billPay(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //账单缴费
  payMerList({ commit }, data) {
    return new Promise((resolve, reject) => {
      payMerList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //线下支付登记
  offlinePay({ commit }, data) {
    return new Promise((resolve, reject) => {
      offlinePay(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  //线下支付查询
  offlinePayInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      offlinePayInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //电表年数据
  getYearData({ commit }, data) {
    return new Promise((resolve, reject) => {
      getYearData(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //电表月数据
  getMonthData({ commit }, data) {
    return new Promise((resolve, reject) => {
      getMonthData(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //账单列表
  getAllBill({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAllBill({}).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //账单详情
  getBillInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      getBillInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //账单支付
  billPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      billPayment(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //获取历史告警列表
  getAlarmHistory({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAlarmHistory(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //获取当前告警列表
  getCurrentAlarm({ commit }, data) {
    return new Promise((resolve, reject) => {
      getCurrentAlarm(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //充值记录
  rechargeRecord({ commit }, data) {
    return new Promise((resolve, reject) => {
      rechargeRecord(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //账单明细
  billDetaileds({ commit }, data) {
    return new Promise((resolve, reject) => {
      billDetaileds(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

