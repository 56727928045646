var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          style: { width: "75%", "max-width": "374px" },
          attrs: { round: "", "close-on-click-overlay": false },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c("div", { staticClass: "dialog-head" }),
          _c("van-field", {
            attrs: { label: "终端号", disabled: "" },
            model: {
              value: _vm.terminalNum,
              callback: function($$v) {
                _vm.terminalNum = $$v
              },
              expression: "terminalNum"
            }
          }),
          _c("van-field", {
            attrs: { label: "电表号", placeholder: "请输入电表号" },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function() {
                  return [
                    _c("van-icon", {
                      attrs: { name: "scan" },
                      on: { click: _vm.scanHandle }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.formData.fid,
              callback: function($$v) {
                _vm.$set(_vm.formData, "fid", $$v)
              },
              expression: "formData.fid"
            }
          }),
          _c(
            "div",
            [
              _c(
                "van-button",
                {
                  staticStyle: { width: "50%" },
                  on: { click: _vm.closeDialog }
                },
                [_vm._v("取消")]
              ),
              _c(
                "van-button",
                {
                  staticStyle: { width: "50%" },
                  attrs: { type: "info" },
                  on: { click: _vm.saveData }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }