import * as test from '@/api/test'

const actions = {
  // 登录
  pay ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      test.pay(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  sendCmcbPayOrder ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      test.sendCmcbPayOrder(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  payO ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      test.payO(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  generateAllinpayOrder ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      test.generateAllinpayOrder(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  tgPosp ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      test.tgPosp(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,

  actions
}

