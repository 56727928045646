var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messagePackage", attrs: { id: "messagePackage" } },
    [
      _c("div", { staticClass: "cardTypeBox" }, [
        _c("div", { staticClass: "cardType" }, [
          _vm._m(0),
          _c("div", { staticClass: "cardEffectTime" }, [
            _c("span", { staticClass: "timer" }),
            _vm._v(" "),
            _c("span", { staticClass: "record", on: { click: _vm.recordEv } }, [
              _vm._v("购买记录")
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "cardSelect" },
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "cardUnit",
              class: { active: _vm.orderMode.fid == item.fid },
              on: {
                click: function($event) {
                  return _vm.selectEv(item)
                }
              }
            },
            [
              _c("div", { staticClass: "discount" }, [
                _c("span", [_vm._v(_vm._s(item.discount) + "折")])
              ]),
              _c("div", { staticClass: "cardNamer" }, [
                _vm._v(_vm._s(item.name))
              ]),
              _c("div", { staticClass: "priceS" }, [
                _c("span", [_vm._v("￥")]),
                _vm._v(_vm._s(item.amount) + " ")
              ]),
              _c("div", { staticClass: "priceOriginal" }, [
                _vm._v("￥" + _vm._s(item.totalAmount))
              ]),
              _c("div", { staticClass: "priceSave" }, [
                _vm._v("立省" + _vm._s(item.discountAmount) + "元")
              ])
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "agreement" },
        [
          _c(
            "van-checkbox",
            {
              attrs: { "checked-color": "#0893ea" },
              model: {
                value: _vm.checked,
                callback: function($$v) {
                  _vm.checked = $$v
                },
                expression: "checked"
              }
            },
            [_vm._v("已阅读并同意")]
          ),
          _c(
            "span",
            { staticClass: "deal", on: { click: _vm.scanAgreementEv } },
            [_vm._v("《无忧短信卡服务协议》")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "btnArea" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: {
              disabled:
                _vm.orderMode.fid == null ||
                _vm.orderMode.fid === "" ||
                !_vm.checked
            },
            on: { click: _vm.buyEv }
          },
          [_vm._v("立即购买")]
        )
      ]),
      _c("div", {
        staticClass: "goIndex",
        on: {
          click: function($event) {
            return _vm.$router.push("/index")
          }
        }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cardName" }, [
      _c("span", { staticClass: "imgBox" }, [
        _c("img", {
          attrs: { src: require("../../assets/images/card_name.png") }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }