var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [_vm._v("您好，")]),
        _c("div", { staticClass: "text" }, [
          _vm._v("欢迎使用" + _vm._s(_vm.propName))
        ]),
        _c("div", { staticClass: "phoneBox" }, [
          _c("div", { staticClass: "inputBox userName" }, [
            _c("div", { staticClass: "inputer" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.submitMode.mobile,
                    expression: "submitMode.mobile"
                  }
                ],
                attrs: {
                  type: "tel",
                  placeholder: "请输入在物业登记的手机号码"
                },
                domProps: { value: _vm.submitMode.mobile },
                on: {
                  blur: _vm.inputBlur,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.submitMode, "mobile", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "formUnit" }, [
            _c("div", { staticClass: "inputBox" }, [
              _c("div", { staticClass: "inputer " }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.submitMode.verifyCode,
                      expression: "submitMode.verifyCode"
                    }
                  ],
                  staticClass: "gray",
                  attrs: { type: "tel", placeholder: "请输入短信验证码" },
                  domProps: { value: _vm.submitMode.verifyCode },
                  on: {
                    blur: _vm.inputBlur,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.submitMode,
                        "verifyCode",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "coder" }, [
                !_vm.codeSecondVis
                  ? _c(
                      "button",
                      {
                        staticClass: "getCodeBtn",
                        attrs: { disabled: _vm.codeBtnVis },
                        on: { click: _vm.getCode }
                      },
                      [_vm._v("获取验证码")]
                    )
                  : _c("button", { attrs: { disabled: "" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.seconds) + "秒后重发")])
                    ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "getCode" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              attrs: { disabled: _vm.loginBtnIs },
              on: {
                click: function($event) {
                  return _vm.login()
                }
              }
            },
            [_vm._v("登录")]
          )
        ])
      ]),
      _c("view-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }