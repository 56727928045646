<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-04-25 15:21:24
 -->
<template>
    <div class="recharge" id="rechargeBill">
            <div class="billContent">
                <!-- 账单支付     -->
                <div class="billInfo bgNo" v-if="!billNoData">
                    <h2>合并支付账单</h2>
                    <p>根据物业公司的收款商户，以下为可合并支付的账单归总：</p>
                </div>

                <!-- 账单缴费列表 -->
                <div class="listerShow" v-if="!billNoData">
                    <div class="billPayLister" v-for="(items,indexs) in billPayList" :key="indexs">
                        <div class="billDetailBoxes" v-for="(item,index) in items.billList" :key='index'>
                            <div class="titleInfos">{{index | formatDate}}</div>
                            <div class="billList" v-for="(itemRoot,indexer) in item" :key='indexer'>
                                <billDetail :dataMode='itemRoot' @paySubmiter="paySubmiter"></billDetail>
                            </div>
                        </div>
                        <div class="btnArea">
                                <button class="btn" @click="billpayLister(items.billList)">合并支付 ￥{{items.amount | formatMoneyThousand}}</button>
                            </div>
                    </div>
                </div>


                <div class="noData" v-if="billNoData">
                    <img src="../../assets/images/noDataPic.png"/>
                    <p>暂无数据</p>
                </div>

            </div>
        

        

        <!-- 账单缴费 -->
        <van-popup v-model="billChargeVis" position="bottom" class="popupBox">
            <div class="title">支付小结</div>
            <div class="content">
                <div class="boxUnit m10">
                    <div class="labeler">支付账单数</div>
                    <div class="texter">{{payShowMode.orderNum}} 张</div>
                </div>
                <div class="boxUnit m10">
                    <div class="labeler">账单金额合计</div>
                    <div class="texter red" >￥{{payShowMode.amount | formatMoneyThousand}}</div>
                </div>
                <div class="boxUnit m10">
                    <div class="labeler">支付服务费</div>
                    <div class="texter red" >￥{{payShowMode.serviceAmount | formatMoneyThousand}}</div>
                </div>
            </div>
            <div class="footer">
                <div class="boxUniter">
                    <div class="labeler">总支付金额</div>
                    <div class="texter red"><button v-if="payShowMode.payAmount !== null" @click="billChargeSubmit()">￥{{payShowMode.payAmount | formatMoneyThousand}}</button></div>
                </div>
            </div>
        </van-popup>

        

    </div>
</template>
<script>
import billDetail from './components/billDetail'
export default {
    name: "rechargeBill",
    components: {billDetail},
    data() {
        return {
           tabActive:true,
           billDetaiVis:false,
           paymentList:null,
           billPayList:null,
           chargeVis:false,
           chargeList:null,
           chargeChecked:'',
           serviceCharge:null,
           chargePayAmount:null,
           billNoData:false,
           billListers:[],
            billChargeVis:false,
            billChargeMode:{
                "list": [],
                "type": 1
            },
            payShowMode:{
                orderNum:null,
                serviceAmount:null,
                amount:null,
                payAmount:null
            },
            billChargeSubmitMode:{
                bills:[] ,
                "redirectUrl": window.location.origin+'/#/rechargeBill'
            }

        };
    },
    mounted() {

    },
    created(){
        this.getBillPayList()
        
    },
    methods: {
        //单张账单支付
        paySubmiter(item){
            console.log(item)
            if(item.merFid == null || item.merFid === ''){
                this.$confirm({
                        mesg: '此账单已被物业设置不支持手机支付,请联系物业咨询其他支付方式',
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
            }else{
                this.billChargeMode.list.splice(0)
                this.billChargeSubmitMode.bills.splice(0)
                this.billChargeMode.list.push(item.fid)
                this.billChargeSubmitMode.bills.push(item.fid)
                this.computeAmount()
                // this.billChargeVis = true   
            }
            
        },
        // 账单缴费合并支付
        mergePay(){
            this.billChargeMode.list.splice(0)
            this.billChargeSubmitMode.bills.splice(0)
            this.billChargeMode.list = [...this.billListers] 
            this.billChargeSubmitMode.bills = [...this.billListers]
            this.computeAmount()
            
        },
        //账单合并提交
        billChargeSubmit(){
            this.$store.dispatch('property/billPay',this.billChargeSubmitMode).then((result) => {
                if (result.returnCode == 0) {
                    // window.location.href = result.data
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        //获取同商户账单表计集合
        billpayLister(obj){
            this.billListers.splice(0)
            console.log(obj)
            Object.keys(obj).forEach((key)=>{
                for(let value of obj[key]){
                    this.billListers.push(value.fid) 
                }
            })

            this.mergePay()
        },
        //获取服务费
        computeAmount(){
            this.load.$emit('loading', true,'计算中···')
            this.$store.dispatch('property/computeAmount',this.billChargeMode).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                        this.billChargeVis = true
                        this.payShowMode.serviceAmount = result.data.serviceAmount
                        this.payShowMode.amount = result.data.amount
                        this.payShowMode.orderNum = result.data.orderNum
                        this.payShowMode.payAmount = result.data.payAmount
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        //获取账单缴费列表
        getBillPayList(){
            this.$store.dispatch('property/payMerList',{}).then((result) => {
                if (result.returnCode == 0) {
                    this.billPayList = result.data
                    if(result.data.length > 0){
                        this.billNoData = false
                    }else{
                        this.billNoData = true
                    }
                    
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        }
    }
};
</script>
