<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-04-29 08:58:49
 -->
<template>
    <div class="changePhone" id="changeName">
        <div class="formBox">

            <div class="formUnit">
                <div class="label">用户名</div>
                <div class="inputBox">
                    <div class="inputer "><input type="text" class="gray"  @blur="trims" placeholder="请输入客户姓名" v-model="submitMode.name" value="" /></div>
                </div>
            </div>

            <div class="btnAreaBox">
                <div class="btnDiv"><button class="btn" @click="submitEvent()">保存</button></div>
            </div>


        </div>




        <view-footer></view-footer>
    </div>
</template>
<script>
export default {
    name: "changeName",
    data() {
        return {
            submitMode:{
                "name": ""
            },
        };
    },
    mounted() {  
    },
    created(){
        this.getMainList()
    },
    methods: {
        //获取主列表
        getMainList(){
            this.load.$emit('loading', true)
            this.$store.dispatch('property/userInfo',{}).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.submitMode.name = result.data.name
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        },
        //提交
        submitEvent(){
            if(this.submitMode.name === '' || this.submitMode.name == null){
                this.$confirm({
                    mesg: "请输入客户姓名",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }
            this.load.$emit('loading', true)
            this.$store.dispatch('property/editName',this.submitMode).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.$toast.success('用户名更改成功')
                    this.$router.push('/account')
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        }
    }
};
</script>
