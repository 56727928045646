<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2020-09-09 11:41:06
 * @LastEditors: yql
 * @LastEditTime: 2020-09-11 16:19:58
-->

<template>
    <div class="orderRecord" id="orderRecord">
        <div class="listBox">
            <div class="listUnit" v-for="(item,index) in list" :key="index">

                <div class="unitName">
                    <div class="namer">{{item.name}}<sup> <img src="../../assets/images/icon_yesM.png" /></sup></div>
                    <div class="price"><span>￥</span>{{item.amount}}</div>
                </div>

                <div class="uniter">
                    <span>交易时间：</span>
                    <span class="text">{{item.payTime}}</span>
                </div>

                <div class="uniter">
                    <span>购买时长：</span>
                    <span class="text">{{item.time}}个月</span>
                </div>

                <div class="uniter">
                    <span>有效时间：</span>
                    <span class="text">{{item.startTime}}-{{item.endTime}}</span>
                </div>

                <div class="uniter">
                    <span>支付单号：</span>
                    <span class="text">{{item.orderNo}}</span>
                </div>

            </div>

            <div class="noData" v-if="list.length == 0">
                <img src="../../assets/images/noDataPic.png"/>
                <p>暂无数据</p>
            </div>




        </div>

        
        <div class="goIndex" @click="$router.push('/index')"></div>
        
    </div>
</template>
<script>
export default {
    name: "orderRecord",
    data() {
        return {
            list:[]
        };
    },
    mounted() {  
    },
    created(){
        this.getList()
    },
    methods: {
        getList(){
            this.load.$emit('loading', true)
            this.$store.dispatch('property/getUserSmsOrder',{}).then((result) => {
                this.list = result.data
                this.load.$emit('loading', false)
            }).catch(error => {
                this.load.$emit('loading', false)
                if(error.message.includes('timeout')){
                    this.$confirm({
                        mesg: '数据请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });

        }
    }
};
</script>
<style  scoped>
    /deep/ .van-checkbox__icon .van-icon{
        width:25px;
        height:25px;
    }
    /deep/ .van-checkbox__label{
        line-height: 30px;
    }
</style>
