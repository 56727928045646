<template>
<div class="fs14">
  <van-nav-bar
    left-text="返回"
    left-arrow
    @click-left="$router.go(-1)"
    @click-right="addClient"
  >
  <template #right>
    <van-icon name="plus" />
  </template>
  </van-nav-bar>
  <van-cell-group inset>
    <van-cell title="终端"  label="222022212">
      <template #default>
        <van-tag type="danger" plain >离线</van-tag>
      </template>
    </van-cell>
  </van-cell-group>
  <div><strong class="f-title">电表列表</strong></div>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <van-cell v-for="item in list" :key="item" :title="item" >
      <template #title>
        <span>{{item}}</span>  <van-tag type="danger" plain >离线</van-tag>
      </template>
      <template #default>
        <van-button type="danger" size="mini" icon="delete-o" ></van-button>
      </template>
    </van-cell>
  </van-list>
  <ClientAdd ref="ClientAdd"></ClientAdd>
</div>
</template>

<script>
   import ClientAdd from "./components/ClientAdd";
    export default {
        name: "index",
      components:{
        ClientAdd
      },
      data(){
          return {
            list: [],
            loading: false,
            finished: false,
          }
      },
        methods:{
          addClient(){
            this.$refs.ClientAdd.showDialog=true
          },
          onLoad() {
            setTimeout(() => {
              for (let i = 0; i < 10; i++) {
                this.list.push(this.list.length + 1);
              }

              // 加载状态结束
              this.loading = false;

              // 数据全部加载完成
              if (this.list.length >= 40) {
                this.finished = true;
              }
            }, 1000);
          },
        }
    }
</script>

<style scoped>
.f-title{
  padding-left: 12px;
  border-left: #1a97e9 4px solid;
  font-size: 18px;
  line-height: 2;
  margin-left: 16px;
}
.fs14{
  font-size: 14px;
}
</style>
