var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accountBox" },
    [
      _vm._m(0),
      _c("div", { staticClass: "block" }, [
        _c(
          "div",
          { staticClass: "unit", on: { click: _vm.channelTypeEvent } },
          [
            _vm._v("支付方式"),
            _c("span", { staticClass: "right" }, [
              _vm._v(_vm._s(_vm.channelTypeName))
            ])
          ]
        ),
        _c("div", { staticClass: "unit" }, [
          _vm._v("流水号后4位"),
          _c("span", { staticClass: "right" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.subMode.remark,
                  expression: "subMode.remark"
                }
              ],
              staticStyle: { "text-align": "right" },
              attrs: { type: "text", value: "" },
              domProps: { value: _vm.subMode.remark },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.subMode, "remark", $event.target.value)
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "unit" }, [
          _vm._v("支付金额"),
          _c("span", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.billRow.amount))
          ])
        ])
      ]),
      _c("div", { staticStyle: { "margin-top": "98px" } }, [
        _c(
          "button",
          { staticClass: "btn", on: { click: _vm.offlinePayEvent } },
          [_vm._v("提交")]
        )
      ]),
      _c(
        "van-popup",
        {
          staticClass: "popupBox",
          attrs: { position: "bottom" },
          model: {
            value: _vm.channelTypeVis,
            callback: function($$v) {
              _vm.channelTypeVis = $$v
            },
            expression: "channelTypeVis"
          }
        },
        [
          _c("div", { staticClass: "title" }, [
            _c(
              "span",
              { staticClass: "lefter", on: { click: _vm.channelTypeCancle } },
              [_vm._v("取消")]
            ),
            _vm._v("选择支付方式 "),
            _c(
              "span",
              {
                staticClass: "righter",
                on: { click: _vm.channelTypePickSure }
              },
              [_vm._v("确定")]
            )
          ]),
          _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.channelTypeSel, function(item, index) {
              return _c(
                "span",
                {
                  key: index,
                  class: { active: item.id === _vm.channelChecked },
                  on: {
                    click: function($event) {
                      return _vm.channelTypePick(item)
                    }
                  }
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "offline-title" }, [
      _c("div", { staticClass: "top" }, [_vm._v("此账单暂不支持线上支付")]),
      _c("div", { staticClass: "bottom" }, [
        _vm._v(
          "物业已设置此账单不能通过线上支付。您可以通过物业提供的支付方式，支付完成后在这里登记支付信息。物业核实后即完成确认支付账单。"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }