<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2019-12-13 13:45:13
 -->
<template>
    <div class="accountTransfer" id="accountTransfer">
        <div class="infoBox">
            <h2>主账号转移</h2>
            <p>转移后，当前手机号码将被注销退出。</p>
        </div>
        <div class="formBox">
            <div class="formUnit">
                <div class="label">当前手机号</div>
                <div class="inputBox">
                    <div class="inputer"><input type="number"  @blur="trims" placeholder="请输入手机号码" /></div>
                </div>
            </div>

            <div class="formUnit">
                <div class="label">输入验证码</div>
                <div class="inputBox">
                    <div class="inputer "><input type="number" class="gray"  @blur="trims" placeholder="请输入验证码" /></div>
                    <div class="coder"><button :disabled="true">45秒后重发</button></div>
                </div>
            </div>

            <div class="formUnit">
                <div class="label">输入新号码</div>
                <div class="inputBox">
                    <div class="inputer "><input type="number" class="gray"  @blur="trims" placeholder="请输入新号码" /></div>
                </div>
            </div>

            <div class="formUnit">
                <div class="label">账号名称</div>
                <div class="inputBox">
                    <div class="inputer "><input type="number" class="gray"  @blur="trims" placeholder="请输入账号名称" /></div>
                </div>
            </div>

            <div class="btnAreaBox">
                <div class="btnDiv"><button class="btn" @click="tranferSubmit">保存</button></div>
            </div>


        </div>




    </div>
</template>
<script>
export default {
    name: "accountTransfer",
    data() {
        return {
        };
    },
    mounted() {  
    },
    methods: {
        tranferSubmit(){
            this.$confirm({
                mesg: "确认迁移此物业主账号到以下手机号码吗？迁移后您将自动退出。",
                cancel:false,
                cntMsg:'<div class="phoneShow"><span>新主账号：</span> 18876251114 </div>',
                btn: {
                    confirmVal: "确 定"
                }
            })
            .then(() => {
                console.log("yes");
                })
            .catch(() => {
                console.log("no");
            });
        }
    }
};
</script>
