/*
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-13 18:49:21
 * @LastEditors: yql
 * @LastEditTime: 2021-01-27 11:01:31
 */

import {
  getMeterList,
  unpaidBill,
  getYearData,
  getMonthData,
  refreshBalance,
  rechargeMeters,
  electricRechargeInfo,
  monthReport,
  billPdf

} from '@/api/meter'


const state = {
  prop: '',
  contract: ''
}

const mutations = {
  SET_PROP: (state, data) => {
    state.prop = data
  },
  SET_CONTRACT: (state, data) => {
    state.contract = data
  }
}

const actions = {

  //获取电表表计列表
  getMeterList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getMeterList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })

    })
  },
  //首页未缴账单信息
  unpaidBill({ commit }, data) {
    return new Promise((resolve, reject) => {
      unpaidBill(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })

    })
  },
  //获取水表年数据
  getYearData({ commit }, data) {
    return new Promise((resolve, reject) => {
      getYearData(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })

    })
  },
  //获取水表月数据
  getMonthData({ commit }, data) {
    return new Promise((resolve, reject) => {
      getMonthData(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })

    })
  },
  //刷新电表余额
  refreshBalance({ commit }, data) {
    return new Promise((resolve, reject) => {
      refreshBalance(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })

    })
  },
    //电量报表
    monthReport({ commit }, data) {
        return new Promise((resolve, reject) => {
            monthReport(data).then(response => {
            resolve(response)
          }).catch(error => {
            reject(error)
          })

        })
      },
  //电表充值列表
  rechargeMeters({ commit }, data) {
    return new Promise((resolve, reject) => {
      rechargeMeters(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })

    })
  },
  //电表充值页信息
  electricRechargeInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      electricRechargeInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })

    })
  },
  //查看账单pdf
  billPdf({ commit }, data) {
    return new Promise((resolve, reject) => {
      billPdf(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })

    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

