var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accountBox", attrs: { id: "account" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "headImg" }, [
          _vm.userInfo.head == null || _vm.userInfo.head == ""
            ? _c("img", {
                attrs: { src: require("../../assets/images/img_default2.png") }
              })
            : _c("img", { attrs: { src: _vm.userInfo.head } })
        ]),
        _c("div", { staticClass: "textInfo" }, [
          _c("h4", [_vm._v(_vm._s(_vm.userInfo.name))]),
          _c("div", { staticClass: "phone" }, [
            _vm._v(_vm._s(_vm.userInfo.mobile))
          ])
        ])
      ]),
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "unit", on: { click: _vm.changeNameEvent } }, [
          _vm._v("更改用户名"),
          _c("span", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.userInfo.name))
          ])
        ]),
        _c(
          "div",
          { staticClass: "unit", on: { click: _vm.changePhoneEvent } },
          [
            _vm._v("资料迁移"),
            _c("span", { staticClass: "right" }, [
              _vm._v(_vm._s(_vm.userInfo.mobile))
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "unit", on: { click: _vm.changeEmailEvent } },
          [
            _vm._v("更改绑定电子邮箱"),
            _c("span", { staticClass: "right" }, [
              _vm._v(_vm._s(_vm.userInfo.email))
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "block" }, [
        _c(
          "div",
          { staticClass: "unit", on: { click: _vm.changeAccountEvent } },
          [_vm._v("账号管理")]
        )
      ]),
      _c("div", { staticClass: "btnAreaBox" }, [
        _c("div", { staticClass: "btnDiv" }, [
          _c("button", { staticClass: "btn", on: { click: _vm.logout } }, [
            _vm._v("注销登录")
          ])
        ])
      ]),
      _c("verifyCode", {
        ref: "verifyCode",
        attrs: {
          verifyCodeDialog: _vm.verifyCodeDialog,
          submitMode: _vm.submitMode
        }
      }),
      _c("div", {
        staticClass: "goIndex",
        on: {
          click: function($event) {
            return _vm.$router.push("/index")
          }
        }
      }),
      _c("view-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }