<template>
  <div class="fs14">
    <van-search
      v-model="searchMode"
      show-action
      label="终端"
      placeholder="请输入搜索关键词"
      @search="onSearch"
    >
      <template #label>
        <div class="f-scan" @click="scanHandle">
          <van-icon name="scan" style="vertical-align: middle"/>&nbsp;
          <span>终端</span>
        </div>
      </template>
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div v-show="!showList">
      <div class="fmg12"  v-show="showHistory">
        <div class="f-search--title cgrey">
          <span >搜索历史：</span>
        </div>
        <div class="f-search--main">
          <van-tag v-for="(item,index) in historyList" plain :closeable="editHistory" size="large" @close="historySearch(item,index)" @click="historySearch(item,index)">
            {{item}}</van-tag>
        </div>
        <div class="f-search--footer">
          <van-button class="fmg12"  size="small" @click="clearHistory">清除历史</van-button>
          <van-button  class="fmg12" v-show="!editHistory"  size="small" @click="editHistory=true">编辑历史</van-button>
          <van-button  class="fmg12" v-show="editHistory"  plain type="info" size="small" @click="editHistory=false">取消编辑</van-button>
        </div>
      </div>
      <van-empty v-show="!showHistory" description="暂无历史查询" />
    </div>
    <div v-if="showList">
      <van-cell-group inset>
        <van-cell  >
          <template #title>
            <span>终端:</span> <span>{{searchTmp}} </span>
            <van-tag type="danger" plain v-show="!dataTable.online">离线</van-tag>
            <van-tag  plain v-show="dataTable.online">在线</van-tag>
          </template>
          <template #default>
            <van-button type="primary" size="mini" @click="getData(true)">终端刷新</van-button>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="f-head">
        <div class="f-head--title">
          <strong class="f-title">关联电表</strong>
        </div>
        <div>
          <van-button type="info" icon="plus" size="mini" @click="addClient">添加</van-button>
        </div>
      </div>
      <van-dropdown-menu>
        <van-dropdown-item v-model="stateTmp" :options="stateList" @change="filterMeter"/>
        <van-dropdown-item ref="item" >
          <template #title>
            <van-icon name="filter-o" v-show="ifSearchMeter" style="vertical-align: middle"/><span>筛选</span>
          </template>
          <van-field v-model="searchMeterTmp" label="电表" placeholder="请输入电表号" clearable/>
          <div style="padding: 5px 16px;">
            <van-button type="primary" block round @click="searchMeter" size="small">
              查询
            </van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
      <van-cell-group >
        <van-cell v-for="item in listTmp">
          <template #title>
            <div class="f-title">
              <span>{{item.meterAddr}} </span>
              <van-tag type="danger" plain v-show="!item.online">离线</van-tag>
              <van-tag  plain v-show="item.online">在线</van-tag>
              <van-icon name="replay" style="vertical-align: middle" @click="refreshMeter(item)"/>
            </div>
          </template>
          <template #default>
            <van-button type="danger" size="mini" icon="delete-o" @click="delClient(item)"></van-button>
          </template>
          <template #label>
            <span>tn:</span> <span>{{item.tn}}</span>
          </template>
        </van-cell>
      </van-cell-group>
      <van-empty v-show="listTmp.length==0" description="暂无数据" />
    </div>
    <ClientAdd ref="ClientAdd" :terminalNum="searchTmp" @update="getData"></ClientAdd>
  </div>
</template>

<script>
  import { Toast,Dialog,Notify } from 'vant'
  import ClientAdd from "./client/components/ClientAdd";
  import _ from 'lodash'

    export default {
        name: "index",
      components:{
        ClientAdd
      },
      data(){
          return {
            searchMode:'',
            searchTmp:'',
            editHistory:false,
            showHistory:false,
            showList:false,
            historyList:[],
            dataTable:{},
            listTmp:[],
            stateTmp: 0,
            stateList:[
              { text: '全部', value: 0 },
              { text: '在线', value: 1 },
              { text: '离线', value: 2 },
            ],
            searchMeterTmp:'',
            ifSearchMeter:false
          }
      },
      watch:{
        editHistory(val){
          if(!val){
            if(this.historyList.length==0){
              this.showHistory=false
            }
          }
        }
      },
      created() {
        this.getHistoryList()
        this.getConfig((res)=>{
          this.setConfig(res.data)
        })
      },
      methods:{
        getData(ifRefresh=false){
          let self=this
          this.load.$emit('loading', true)
          if (!ifRefresh){
            self.searchTmp=self.searchMode.slice()
          }
          let tmp=ifRefresh?self.searchTmp:self.searchMode
          this.$store
            .dispatch('terminal/getMeterList',{
              "fid": tmp,
              "refresh": ifRefresh
            })
            .then(res => {
              this.load.$emit('loading', false)
              if(res.returnCode==0){

                self.showList=true
                self.dataTable=res.data
                if(self.dataTable.list){
                  self.listTmp=self.dataTable.list.slice(0)
                }else {
                  self.dataTable.list=[]
                  self.listTmp=[]
                }
                return
              }
              Toast.fail('请求异常！');
            })
            .catch(res=>{
            this.load.$emit('loading', false)
            Toast.fail('网络异常！');
          })
        },
        onSearch(){
          if(!this.searchMode){
            return
          }
          this.saveSearchLocal(this.searchMode)
          this.getData()
        },
        saveSearchLocal(val){
          let searchLocalTmp=localStorage.getItem('searchLocal') ||''
          let searchLocal=searchLocalTmp==''?[]:searchLocalTmp.split(',')
          searchLocal.unshift(val)
          searchLocal= _.uniq(searchLocal)
          localStorage.setItem('searchLocal',searchLocal.toString())
        },
        getHistoryList(){
          let searchLocalTmp=localStorage.getItem('searchLocal') ||''
          if(!searchLocalTmp){
            this.showHistory=false
            return
          }
          this.showHistory=true
          this.historyList=searchLocalTmp.split(',')
        },
        historySearch(val,index){
          if(this.editHistory){
            let searchLocalTmp=localStorage.getItem('searchLocal').split(',')
            searchLocalTmp.splice(index,1)
            localStorage.setItem('searchLocal',searchLocalTmp.toString())
            this.historyList=searchLocalTmp
            return
          }
          this.searchMode=val
          this.getData()
        },
        clearHistory(){
          localStorage.removeItem('searchLocal')
          this.showHistory=false
        },
        addClient(){
          this.$refs.ClientAdd.showDialog=true
        },
        delClient(val){
          let self=this
          Dialog.confirm({
            title: '操作提示',
            message: '是否确定要删除关联电表？',
          })
            .then(() => {
              this.delData(val)
            })
        },
        delData(item){
          let self=this
          this.load.$emit('loading', true)
          this.$store
            .dispatch('terminal/deleteElectric',{
              "fid": item.meterAddr,
            })
            .then(res => {
              this.load.$emit('loading', false)
              if(res.returnCode==0){
                self.getData()
                return
              }
              Notify({ type: 'warning', message: `${res.returnMsg}`})
            })
            .catch(res=>{
              this.load.$emit('loading', false)
              Toast.fail('网络异常！')
            })
        },
        refreshMeter(item){
          let self=this
          this.load.$emit('loading', true)
          this.$store
            .dispatch('terminal/refreshElectric',{
              "fid": item.meterAddr,
            })
            .then(res => {
              this.load.$emit('loading', false)
              if(res.returnCode==0){
                item.online=true
                Notify({ type: 'success', message: `${item.meterAddr} 在线 ` })

              }else{
                item.online=false
                Notify({ type: 'warning', message: `${item.meterAddr} 离线 `})
              }
            }).catch(res=>{
            this.load.$emit('loading', false)
            Toast.fail('网络异常！');
          })
        },
        filterMeter(){
           let self=this
           let val=self.stateTmp
           let searchMeter=self.searchMeterTmp
           if(val==0){
             self.listTmp = self.dataTable.list.slice(0)
           }else{
             this.listTmp=this.dataTable.list.filter((item)=>{
               if(val==1){
                 return item.online
               }else{
                 return !item.online
               }
             })
           }
          this.listTmp=this.listTmp.filter((item)=>{
             return item.meterAddr.indexOf(searchMeter)!=-1
          })
        },
        searchMeter(){
          if(this.searchMeterTmp){
            this.ifSearchMeter=true
          }else{
            this.ifSearchMeter=false
          }
          this.filterMeter()
          this.$refs.item.toggle();
        },
        scanHandle(){
          let self=this
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              let tmp=res.resultStr.split(',')[1]
              self.searchMode= tmp.substr(-8)
            }
          });
        },
        getConfig(func){
          this.$store
            .dispatch('terminal/getWxJsapi',{
              fid:window.location.origin
            })
            .then(res => {
              func(res)
            })
        },
        setConfig(data){
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature:  data.signature,// 必填，签名
            jsApiList: [
              'scanQRCode'
            ] // 必填，需要使用的JS接口列表
          });
        },


        }
    }
</script>

<style scoped>
  .f-search--title{
    font-size: 36px;
    line-height: 2;
  }
  .f-search--main{
    padding: 12px;
  }
  .f-search--main>span{
    margin-right: 6px;
  }
  .f-search--footer{
    text-align: center;
  }
  .f-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .f-head--title{
    padding-left: 12px;
    border-left: #1a97e9 4px solid;
    font-size: 36px;
    margin: 12px;
  }
  .f-head--content{

  }
  .f-title{
    display: flex;
    align-items: center;
  }
  .f-title>*{
    margin-left: 6px;
  }

  .cgrey{
    color: #999999;
  }
  .fmg12{
    margin: 12px;
  }

  .fs14{
    font-size: 14px;
  }

</style>
