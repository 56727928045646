/*
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-11-30 17:02:56
 * @LastEditors: yql
 * @LastEditTime: 2020-05-29 15:41:17
 */

export default{
    install(Vue,options){
      
      //原账单类型 
      Vue.prototype.originalBillKindEnums=function(){
        let originalBillKindEnum={
                          1:'电表',
                          2:'水表',
                          3:'租金',
                          4:'管理费',
                          5:'分摊费用',
                          98:'调整账单',
                          99:'其他费用'
                          }
        return originalBillKindEnum;
      },
      //账单类型
      Vue.prototype.billKindEnums=function(){
        let billKindEnum={
                          1:'电表',
                          2:'水表',
                          3:'租金',
                          4:'管理费',
                          5:'分摊费用',
                          98:'调整账单',
                          99:'其他费用'
                          }
        return billKindEnum;
      },
      //账单类型
      Vue.prototype.billKindLists=function(){
        let billKindList=[
                          {id:1,label:'电表'},
                          {id:2,label:'水表'},
                          {id:3,label:'租金'},
                          {id:4,label:'管理费'},
                          {id:5,label:'分摊费用'},
                          {id:98,label:'调整账单'},
                          {id:99,label:'其他费用'}
                        ]
        return billKindList;
      },
      //账单状态
      Vue.prototype.billStatusEnums=function(){
        let billStatusEnum={
                          1:'已逾期',
                          2:'未支付',
                          3:'已支付'
                          }
        return billStatusEnum;
      },
      //账单状态 0:未知,1:无需收款,2:待收款,3:待退款,4:已收款,5:已退款
      Vue.prototype.payStatusEnums=function(){
        let payStatusEnum={
                          0:'未知',
                          1:'无需缴费',
                          2:'待缴费',
                          3:'待退款',
                          4:'已缴费',
                          5:'已退款'
                          }
        return payStatusEnum;
      },
      //合同状态
      Vue.prototype.contractStatusEnums=function(){
        let contractStatusEnum={
                            1:'待生效',
                            2:'生效中',
                            3:'已逾期',
                            4:'已解除',
                            5:'已删除'
                          }
        return contractStatusEnum;
      },
      //表计类型
      Vue.prototype.meterKindEnums=function(){
        let meterKindEnum={
                            1:'待生效',
                            2:'生效中',
                            3:'已逾期',
                            4:'已解除',
                            5:'已删除'
                          }
        return meterKindEnum;
      },
      //告警类型 1:余额不足 2:超负荷告警 3:拉闸告警 4:合闸告警 5:其他告警
      Vue.prototype.alarmTypeEnums=function(){
        let alarmTypeEnum={
                            1:'余额不足',
                            2:'超负荷告警',
                            3:'拉闸告警',
                            4:'合闸告警',
                            5:'其他告警'
                          }
        return alarmTypeEnum;
      },
       //告警类型 
      Vue.prototype.alarmTypeLists=function(){
        let alarmTypeList=[
                            {id:1,label:'余额不足'},
                            {id:2,label:'超负荷告警'},
                            {id:3,label:'拉闸告警'},
                            {id:4,label:'合闸告警'},
                            {id:5,label:'其他告警'}
                          ]
        return alarmTypeList;
      },
      //重置金额 
      Vue.prototype.chargeLists=function(){
        let chargeList=[
                            {id:100,label:'100'},
                            {id:200,label:'200'},
                            {id:300,label:'300'},
                            {id:400,label:'400'},
                            {id:500,label:'500'}
                          ]
        return chargeList;
      },
      //表计类型  
      Vue.prototype.meterKindEnumers=function(){
        let meterKindEnumer={
                      1:'电表',
                      2:'水表'
        }
        return meterKindEnumer;
      }
      //支付方式 
      Vue.prototype.channelTypeList=function(){
        let channelType=[
          { id: 1, label: '现金' },
          { id: 2, label: '转账' },
          { id: 3, label: '刷卡' },
          { id: 4, label: '支付宝扫码' },
          { id: 5, label: '微信扫码' },
          { id: 6, label: '其他扫码' },
          { id: 99, label: '其他方式' },
          { id: 50, label: '银联支付' },
          { id: 51, label: '支付宝支付' },
          { id: 52, label: '微信支付' }
        ]
        return channelType;
      }

      Vue.prototype.channelTypeEmnus=function(){
        let channelType={
          1: '现金' ,
          2: '转账' ,
          3: '刷卡' ,
          4: '支付宝扫码' ,
          5: '微信扫码' ,
          6: '其他扫码' ,
          99: '其他方式' ,
          50: '银联支付' ,
          51: '支付宝支付' ,
          52: '微信支付' 
        }
        return channelType;
      }

      // 1：单一计价、2：分时计价、3：阶梯计价、4：分时阶梯计价
      Vue.prototype.valuationElectEnums=function(){
        let valuationElectEnum={
          1: '单一计价',
          2: '分时计价',
          3: '阶梯计价',
          4: '分时阶梯计价'
        }
        return valuationElectEnum;
      }

      //电费计价 1：尖 2:峰 3：平 2：谷
      Vue.prototype.electTypeLists=function(){
        let electTypeList={
          1: '尖',
          2: '峰',
          3: '平',
          4: '谷'
        }
        return electTypeList;
      }

      //水费计价 1：单一计价、2：阶梯计价
      Vue.prototype.valuationWaterEnums=function(){
        let valuationwaterEnum={
          1: '单一计价',
          2: '阶梯计价'
        }
        return valuationwaterEnum;
      }

  
  }
}