<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-05-28 09:49:40
 -->
<template>
    <div class="payRecord" id="payRecord">
        <!-- <div class="tabBox">
            <div class="tabUnit">
                <span class="date active">
                    <button class="btner" @click="timePickEvent">{{searchMode.period}}</button>
                </span>
            </div>
            <div class="tabUnit"></div>
        </div> -->
        

        <div class="payListBox">
            <div class="billTop">
                <div class="name">{{userInfos.propName}}</div>
                <div class="address">{{userInfos | installAdressFormat}}</div>
            </div>
            <div v-if="list.length>0">
            <div class="payerList" v-for="(item,key) in list" :key="key">
                <div class="rechargeTime">
                    <span>充值时间：{{item.rechargeTime}}</span>
                </div>
                <div class="detailBox">
                    <div class="statusShow"> 
                        <img v-if="item.enabled" src="../../assets/images/pay_success.png"/>
                        <img v-else src="../../assets/images/pay_fail.png"/>
                    </div>
                    <div class="detailUnit">
                        <div class="label">电表号</div>
                        <div class="text">{{item.meterAddr}}</div>
                    </div>

                    <div class="detailUnit">
                        <div class="label">支付流水号</div>
                        <div class="text">{{item.orderNo}}</div>
                    </div>

                    <div class="detailUnit">
                        <div class="label">电表充值状态</div>
                        <div class="text"><span v-if="item.rechargeStatus==2">已充值到电表</span><span v-else>充值中</span></div>
                    </div>

                    <div class="detailUnit">
                        <div class="label">支付方式</div>
                        <div class="text">{{channelType[item.channelType]}}</div>
                    </div>

                    <div class="detailUnit">
                        <div class="label">支付服务费</div>
                        <div class="text">￥{{item.serviceAmount}}</div>
                    </div>

                    <div class="detailUnit">
                        <div class="label">支付金额</div>
                        <div class="text red bold">￥{{item.payAmount}}</div>
                    </div>
                    <div class="detailUnit" v-if="item.rechargeStatus !==1">
                        <div class="label">充值前余额</div>
                        <div class="text  bold">￥{{item.beforeBalance}}</div>
                    </div>
                    <div class="detailUnit" v-if="item.rechargeStatus !==1">
                        <div class="label">充值后余额</div>
                        <div class="text red bold">￥{{item.afterBalance}}</div>
                    </div>
                </div>
            </div>
            </div>
            <div class="noData" v-else>
                    <img src="../../assets/images/noDataPic.png"/>
                    <p>暂无数据</p>
                </div>

        </div>

        <div class="goIndex" @click="$router.push('/index')"></div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "payRecord",
    data() {
        return {
            list:[],
            channelType:'',
            userInfos:{}
        };
    },
    computed: {
            ...mapGetters([
                'propertyName',
                'roomName'
            ]),
        },
    mounted() {  
    },
    created(){
        this.getMainList()
        this.channelType = this.channelTypeEmnus()

        this.getUserInfo()
    },
    methods: {
        //获取用户信息
        getUserInfo(){
            this.$store.dispatch('property/userInfo',{}).then((result) => {
                if (result.returnCode == 0) {
                    // this.userInfos = result.data;
                    this.$set(this,'userInfos',result.data)
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                if(error.message.includes('timeout')){
                    this.$confirm({
                        mesg: '用户信息请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        //获取主列表
        getMainList(){
            this.load.$emit('loading', true)
            this.$store.dispatch('property/rechargeRecord',{}).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.list = result.data
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                this.load.$emit('loading', false)
                if(error.message.includes('timeout')){
                    this.$confirm({
                        mesg: '数据请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        
    }
};
</script>
