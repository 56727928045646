<template>
  <div>
    <!--
    <iframe src="http://192.168.0.100:15003/gs/test/payO" frameborder="0" style="height: 100vh;width: 100%"></iframe>
    -->
    <input type="button" value="测试" @click="getGenerateAllinpayOrder" style="width: 100px;height: 50px">
    <div ref="form"></div>
  </div>

</template>

<script>
  import axios from "axios"

    export default {
        name: "index",
      data(){
          return {
            from:``
          }
      },
      created() {
       // this.getData()
      },
      methods:{
          getFromString(){
            let self=this
            this.$store
              .dispatch('test/payO')
              .then(res => {
                let eleScript=document.createElement("script")
                eleScript.innerHTML=res
                self.$refs.form.append(eleScript)

              })
          },
        getData(){
          axios.post('https://61.142.204.26:10086/h5/busiTest01').then((res)=>{
            let data=res.data.data
            data.package=data.pkg
            this.testWxpay(data)
          })
        },
          getSendCmcbPayOrder(){
            let self=this
            let query={
              merId:'CM2002074909',
              payNo:'aaadddbb2020',
              amount:'25',
              name:'电费',
              code:'9XzG1Ux6ztdSweagzUxnPEp6CCJEatwq',
              prop:'2019111816364511700000099AH33A6Q'
            }
            this.$store
              .dispatch('test/sendCmcbPayOrder', query)
              .then(res => {
                debugger
                let data=JSON.parse(res.data.body.payinfo)
                this.setConfig(data)
                wx.ready(function(){
                  self.chooseWXPay(data)
                });
              })
          },
        testWxpay(data){
            let self =this
          this.setConfig(data)
          wx.ready(function(){
            self.chooseWXPay(data)
          });
        },
        getTgPosp(){
            let self=this
            let query={}
          this.$store
            .dispatch('test/tgPosp', query)
            .then(res => {
              let data=JSON.parse(res.data)
              this.setConfig(data)
              wx.ready(function(){
                self.chooseWXPay(data)
              });
            })
        },
        getGenerateAllinpayOrder(){
          let self=this
          let query={
            cusid:"990440148166000",
            payNo:'aaadddbb2020',
            amount:1,
            redirect:'https://npmjs.com/'
          }
          this.$store
            .dispatch('test/generateAllinpayOrder', this.handleData(query))
            .then(res => {
              debugger
              console.log(res)
              document.write(res.data)

            })
        },
        handleData(obj){
          const arr = []
          for(let key in obj){
            // 核心，要对key和val进行encodeURIComponent编码，不能直接传给后台，不如会报错。 我踩的坑就在这里，没有进行编码就往后传了，就出现了文章开头的问题。
            // 可以用qs.js这个库，来完成这一步的操作。
            // 这个参数的处理，是这个类型请求的核心。在这种数据格式要求下，后端对数据的接收处理，类似于url上的query参数。
            arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
          }
          return arr.join('&')
        },
          setConfig(data){
            wx.config({
              debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: data.appId, // 必填，公众号的唯一标识
              timestamp: data.timeStamp, // 必填，生成签名的时间戳
              nonceStr: data.nonceStr, // 必填，生成签名的随机串
              signature:  data.paySign,// 必填，签名
              jsApiList: [
                'chooseWXPay'
              ] // 必填，需要使用的JS接口列表
            });
          },
        chooseWXPay(data){
          wx.chooseWXPay({
            timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
            package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign:  data.paySign, // 支付签名
            success: function (res) {
              // 支付成功后的回调函数
              console.log(res)
            }
          })
        }
      }
    }
</script>

<style scoped>

</style>
