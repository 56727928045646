<!--
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-05-28 16:04:37
 -->
<template>
  <div class="recharge" id="unPayBill">
    <!-- 账单缴费 -->
    <div class="billContent">
      <div class="billTop">
        <div class="name">{{ propertyName }}</div>
        <div class="address">{{ roomName }}</div>
      </div>
      <div class="billTitle">
        <span style="flex:1">未缴账单</span>
        <span @click="goAllBill" class="seesee">全部账单</span
        ><span class="rightArrow"></span>
      </div>
      <div v-if="billList.length > 0">
        <div v-for="(item, key) in billList" :key="key">
          <div class="billBox">
            <div class="mainBox" :class="{ radius: item.showDetail }">
              <div class="chargeArea paid" @click="payEvent(item)">缴费</div>
              <div class="name" @click="payEvent(item)">
                <span class="month"
                  >{{
                    item.period.substring(item.period.length - 2)
                  }}月账单</span
                >
                <span class="time"
                  >{{ item.startDate }}-{{ item.endDate }}</span
                >
                <!-- <span>单位：元</span> -->
              </div>
              <div class="amount" @click="payEvent(item)">
                <span>账单欠费 ￥{{ item.arrearageAmount }}</span>
                <button class="singleBtn" @click.stop="getPdf(item)">
                  查看结算单
                </button>
              </div>
              <div class="amountDetail">
                <div>
                  <p class="tit">账单金额</p>
                  <p class="money">￥{{ item.actualAmount }}</p>
                </div>
                <div class="bborder"></div>
                <div>
                  <p class="tit">
                    已缴金额
                    <!-- <span v-if="item.serviceAmount && item.serviceAmount !== 0 && item.serviceAmount !== null">(含服务费:￥{{item.serviceAmount}})</span> -->
                  </p>
                  <p class="money">￥{{ item.paidAmount }}</p>
                </div>
                <div
                  class="downIcon"
                  @click="item.showDetail = !item.showDetail"
                >
                  更多详情
                  <span :class="{ up: item.showDetail }"></span>
                </div>
              </div>
            </div>

            <div v-if="item.showDetail" class="detailBoxs">
              <div class="billDetail" v-for="(bill, k) in item.bills" :key="k">
                <div
                  @click="payEvent(item)"
                  :class="{ noArrow: bill.payStatus !== 2 }"
                >
                  <span
                    >{{ bill.name
                    }}<span v-if="bill.meterAddr"
                      >-表号:{{ bill.meterAddr }}</span
                    >
                    <span
                      class="status"
                      :class="{ green: bill.payStatus !== 2 }"
                      >{{ payStatusEnum[bill.payStatus] }}</span
                    ></span
                  >
                  <span>￥{{ bill.amount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="noData" v-else>
        <img src="../../assets/images/noDataPic.png" />
        <p>无未缴账单</p>
      </div>

      <!-- <button class="billBtn">立即缴费</button> -->
    </div>

    <div class="goIndex" @click="$router.push('/index')"></div>
    <van-popup
      v-model="showPop"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <div ref="printContent" @click="showPdfImg">
        <template v-if="showPdf">
          <pdf
            ref="pdf"
            v-for="i in pdfPageNums"
            :key="i"
            :src="pdfSrc"
            :page="i"
          ></pdf>
        </template>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import pdf from "vue-pdf";
import html2canvas from "html2canvas";
import { ImagePreview } from "vant";
export default {
  name: "unPayBill",
  components: {
    pdf
  },
  data() {
    return {
      billList: [],
      userInfos: {},
      payStatusEnum: null,
      showPdf: false,
      showPop: false,
      pdfSrc: "",
      pdfPageNums: "",
      selPdf: ""
    };
  },
  computed: {
    ...mapGetters(["propertyName", "roomName"])
  },
  mounted() {},
  created() {
    this.getMainData();
    this.getUserInfo();
    this.payStatusEnum = this.payStatusEnums();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$store
        .dispatch("property/userInfo", {})
        .then(result => {
          if (result.returnCode == 0) {
            this.$set(this, "userInfos", result.data);
          } else {
            this.$confirm({
              mesg: result.returnMsg,
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        })
        .catch(error => {
          if (error.message.includes("timeout")) {
            this.$confirm({
              mesg: "用户信息请求超时",
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        });
    },
    getMainData() {
      this.load.$emit("loading", true);
      this.$store
        .dispatch("property/getBillInfo", {})
        .then(result => {
          this.load.$emit("loading", false);
          if (result.returnCode == 0) {
            let list = result.data;
            this.billList = list.map(v => {
              this.$set(v, "showDetail", false);
              return v;
            });
          } else {
            this.$confirm({
              mesg: result.returnMsg,
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        })
        .catch(error => {
          this.load.$emit("loading", false);
          if (error.message.includes("timeout")) {
            this.$confirm({
              mesg: "数据请求超时",
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        });
    },
    goAllBill() {
      this.$router.push("/allBill");
    },
    payEvent(item) {
      this.$router.push({
        path: "/notPayBills",
        query: {
          fid: item.fid
        }
      });

      // let mode = {
      //     fid: '',
      //     redirectUrl:''
      // }
      // mode.fid= item.fid
      // mode.redirectUrl =window.location.origin + '/#/unPayBill'
      // this.load.$emit('loading', true)
      // this.$store.dispatch('property/billPayment',mode).then((result) => {
      //     this.load.$emit('loading', false)
      //     if (result.returnCode == 0) {
      //         window.location.href = result.data
      //     } else {
      //         this.$confirm({
      //             mesg: result.returnMsg,
      //             cancel:false,
      //             btn: {
      //                 confirmVal: "确 定"
      //             }
      //         })
      //     }
      // }).catch(error => {
      //     this.load.$emit('loading', false)
      //     if(error.message.includes('timeout')){
      //         this.$confirm({
      //             mesg: '数据请求超时',
      //             cancel: false,
      //             btn: {
      //                 confirmVal: "确 定"
      //             }
      //         })
      //     }
      // });
    },
    getPdf(item) {
      if (this.selPdf == item.fid) {
        this.showPop = true;
        return;
      }
      this.showPdf = false;
      this.load.$emit("loading", true);
      this.$store
        .dispatch("meter/billPdf", { fid: item.fid })
        .then(result => {
          this.load.$emit("loading", false);
          this.selPdf = item.fid;

          // this.pdfSrc = pdf.createLoadingTask({
          //   url: URL.createObjectURL(result),
          //   cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/",
          //   cMapPacked: true
          // });
          this.pdfSrc = pdf.createLoadingTask({
            url: URL.createObjectURL(result),
            cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/",
            cMapPacked: true
          });
          this.pdfSrc.promise.then(pdf => {
            // 获取pdf的页数
            this.pdfPageNums = pdf.numPages;
          });

          this.$nextTick(() => {
            this.showPdf = true;
            this.showPop = true;
          });
        })
        .catch(e => {
          this.load.$emit("loading", false);
          this.showPop = false;
          this.$confirm({
            mesg: "单据异常",
            cancel: false,
            btn: {
              confirmVal: "确 定"
            }
          });
          console.log(e);
        });
    },
    showPdfImg() {
      let self = this;
      html2canvas(this.$refs.printContent, {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then(canvas => {
        const url = canvas.toDataURL();
        self.showImg(url);
      });
    },
    downloadPdfImg() {
      this.load.$emit("loading", true);
      let self = this;
      html2canvas(this.$refs.printContent, {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then(canvas => {
        // canvas为转换后的Canvas对象
        this.load.$emit("loading", false);
        let oImg = new Image();
        oImg = canvas.toDataURL("image/png"); // 导出图片
        console.log(oImg);

        var oA = document.createElement("a");
        oA.download = ""; // 设置下载的文件名，默认是'下载'
        oA.href = oImg;
        document.body.appendChild(oA);
        oA.click();
        oA.remove(); // 下载之后把创建的元素删除
      });
    },
    showImg(url) {
      let self = this;
      ImagePreview({
        images: [url],
        showIndex: false,
        closeable: true,
        onClose() {
          // self.showPop = false;
        }
      });
    }
  }
};
</script>
