/*
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-04 17:27:36
 * @LastEditors: yql
 * @LastEditTime: 2021-01-28 11:06:07
 * @LastEditors: zengfq
 * @LastEditTime: 2020-04-25 09:25:39
 */
import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index/index"),
    meta: {
      title: "登录"
    },
    hidden: false
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login"),
    meta: {
      title: "登录"
    },
    hidden: true
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index/index"),
    meta: {
      title: "物业项目名称"
    }
  },
  {
    path: "/zfrecharge",
    name: "zfrecharge",
    component: () => import("@/views/zfrecharge/index"),
    meta: {
      title: "充值"
    }
  },
  {
    path: "/recharge",
    name: "recharge",
    component: () => import("@/views/recharge/recharge"),
    meta: {
      title: "充值缴费"
    }
  },
  {
    path: "/rechargeBill",
    name: "rechargeBill",
    component: () => import("@/views/recharge/rechargeBill"),
    meta: {
      title: "充值缴费"
    }
  },
  {
    path: "/electRecharge",
    name: "electRecharge",
    component: () => import("@/views/elect/electRecharge"),
    meta: {
      title: "电费充值"
    }
  },
  {
    path: "/electList",
    name: "electList",
    component: () => import("@/views/elect/electList"),
    meta: {
      title: "电表列表"
    }
  },
  {
    path: "/property",
    name: "property",
    component: () => import("@/views/property/property"),
    meta: {
      title: "请选择房号"
    }
  },
  {
    path: "/alarm",
    name: "alarm",
    component: () => import("@/views/alarm/alarm"),
    meta: {
      title: "告警记录"
    }
  },
  {
    path: "/service",
    name: "service",
    component: () => import("@/views/service/service"),
    meta: {
      title: "我的服务"
    }
  },
  {
    path: "/historyBill",
    name: "historyBill",
    component: () => import("@/views/historyBill/historyBill"),
    meta: {
      title: "我的账单"
    }
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/views/account/account"),
    meta: {
      title: "我的账号"
    }
  },
  {
    path: "/changePhone",
    name: "changePhone",
    component: () => import("@/views/account/changePhone"),
    meta: {
      title: "更改绑定手机号码"
    }
  },
  {
    path: "/changeEmail",
    name: "changeEmail",
    component: () => import("@/views/account/changeEmail"),
    meta: {
      title: "更改绑定电子邮件"
    }
  },
  {
    path: "/changeAccount",
    name: "changeAccount",
    component: () => import("@/views/account/changeAccount"),
    meta: {
      title: "账号管理"
    }
  },
  {
    path: "/changeName",
    name: "changeName",
    component: () => import("@/views/account/changeName"),
    meta: {
      title: "修改用户名"
    }
  },
  {
    path: "/accountTransfer",
    name: "accountTransfer",
    component: () => import("@/views/account/accountTransfer"),
    meta: {
      title: "主账号转移"
    }
  },
  {
    path: "/accountBuild",
    name: "accountBuild",
    component: () => import("@/views/account/accountBuild"),
    meta: {
      title: "新建用户账号"
    }
  },
  {
    path: "/payRecord",
    name: "payRecord",
    component: () => import("@/views/account/payRecord"),
    meta: {
      title: "充值记录"
    }
  },
  {
    path: "/chart",
    name: "chart",
    component: () => import("@/views/chart/chart"),
    meta: {
      title: "用电情况"
    }
  },
  {
    path: "/chartReport",
    name: "chartReport",
    component: () => import("@/views/chart/chartReport"),
    meta: {
      title: "电量报表"
    }
  },
  {
    path: "/chartWater",
    name: "chartWater",
    component: () => import("@/views/chart/chartWater"),
    meta: {
      title: "用水情况"
    }
  },
  {
    path: "/offlinePay",
    name: "offlinePay",
    component: () => import("@/views/recharge/offlinePay"),
    meta: {
      title: "线下支付登记"
    }
  },
  {
    path: "/unPayBill",
    name: "unPayBill",
    component: () => import("@/views/bill/unPayBill"),
    meta: {
      title: "未缴账单"
    }
  },
  {
    path: "/allBill",
    name: "allBill",
    component: () => import("@/views/bill/allBill"),
    meta: {
      title: "全部账单"
    }
  },
  {
    path: "/notPayBills",
    name: "notPayBills",
    component: () => import("@/views/bill/notPayBills"),
    meta: {
      title: "账单详情"
    }
  },
  {
    path: "/billDetail",
    name: "billDetail",
    component: () => import("@/views/bill/billDetail"),
    meta: {
      title: "账单详情"
    }
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/message/message"),
    meta: {
      title: "短信设置"
    }
  },
  {
    path: "/messagePackage",
    name: "messagePackage",
    component: () => import("@/views/message/messagePackage"),
    meta: {
      title: "购买套餐"
    }
  },
  {
    path: "/orderRecord",
    name: "orderRecord",
    component: () => import("@/views/message/orderRecord"),
    meta: {
      title: "购买记录"
    }
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/message/agreement"),
    meta: {
      title: "用户服务协议"
    }
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/test/index"),
    meta: {
      title: "测试"
    }
  },
  {
    path: "/terminal",
    name: "terminal",
    component: () => import("@/views/terminal"),
    meta: {
      title: "终端"
    }
  },
  {
    path: "/terminal/client",
    name: "client",
    component: () => import("@/views/terminal/client"),
    meta: {
      title: "电表列表"
    }
  }
];

let router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

export function resetRouter() {
  router = new VueRouter({
    base: process.env.BASE_URL,
    routes
  });
}

export default router;
