var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "servicer", attrs: { id: "service" } }, [
    _c("div", { staticClass: "header" }, [
      _vm._m(0),
      _c("div", { staticClass: "textInfo" }, [
        _vm._v(_vm._s(_vm.dataMode.name))
      ])
    ]),
    _c("div", { staticClass: "titleInfo" }, [_vm._v(" 物业服务合同信息 ")]),
    _c("div", { staticClass: "block" }, [
      _c("div", { staticClass: "unit" }, [
        _vm._v("客户状态"),
        _c("span", { staticClass: "right" }, [
          _vm._v(_vm._s(_vm.contractStatusEnum[_vm.dataMode.status]))
        ])
      ]),
      _c("div", { staticClass: "unit" }, [
        _vm._v("合同结束时间"),
        _c("span", { staticClass: "right" }, [_vm._v(_vm._s(_vm.dataMode.end))])
      ]),
      _c("div", { staticClass: "unit" }, [
        _vm._v("已交押金"),
        _c("span", { staticClass: "right" }, [
          _vm._v(
            "￥" + _vm._s(_vm._f("formatMoneyThousand")(_vm.dataMode.deposit))
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "propertyBox" }, [
      _c("div", { staticClass: "propertyInfo" }, [_vm._v("使用物业")]),
      _c("div", { staticClass: "propertySwiper" }, [
        _c(
          "div",
          { staticClass: "swiper-container", attrs: { id: "propertySwiper" } },
          [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.customerList, function(item, index) {
                return _c("div", { key: index, staticClass: "swiper-slide" }, [
                  _c("div", { staticClass: "areaUnit" }, [
                    _c("p", { staticClass: "areas" }, [
                      _vm._v(
                        _vm._s(_vm._f("areasFormat")(item.areaName)) +
                          "-" +
                          _vm._s(item.buildName)
                      )
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(item.floor) + "楼-" + _vm._s(item.roomName))
                    ])
                  ])
                ])
              }),
              0
            )
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "meterBox" }, [
      _c("div", { staticClass: "meterInfo" }, [_vm._v("表计清单")]),
      _c("div", { staticClass: "meterSwiper" }, [
        _c(
          "div",
          { staticClass: "swiper-container", attrs: { id: "meterSwiper" } },
          [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.electricLists, function(item, index) {
                return _c("div", { key: index, staticClass: "swiper-slide" }, [
                  _c("div", { staticClass: "slideBox" }, [
                    _c("div", { staticClass: "slideInfo" }, [
                      _vm._v(_vm._s(_vm.meterKindEnumer[item.meterKind]) + " "),
                      item.onlineStatus
                        ? _c("span", { staticClass: "right" }, [_vm._v("正常")])
                        : _c("span", { staticClass: "right" }, [_vm._v("离线")])
                    ]),
                    _c("div", { staticClass: "slideText" }, [
                      _c("div", { staticClass: "infos" }, [
                        _c("p", { staticClass: "number" }, [
                          _vm._v(_vm._s(item.meterAddr))
                        ])
                      ]),
                      item.meterKind == 1
                        ? _c(
                            "div",
                            { staticClass: "searchBtnArea" },
                            [
                              _c("searchSwitch", {
                                attrs: { fid: item.meterAddr }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "addressBox" }, [
                      _vm._v(
                        "安装地址：" +
                          _vm._s(_vm._f("installAdressFormat2")(item))
                      )
                    ]),
                    _c("div", { staticClass: "opeartArea" }, [
                      item.meterKind == 1
                        ? _c("div", { staticClass: "off" }, [
                            _c(
                              "button",
                              {
                                attrs: { disabled: _vm.switchBtnVis },
                                on: {
                                  click: function($event) {
                                    return _vm.updateStatus(
                                      item.meterAddr,
                                      false
                                    )
                                  }
                                }
                              },
                              [_vm._v("拉闸")]
                            )
                          ])
                        : _vm._e(),
                      item.meterKind == 1
                        ? _c("div", { staticClass: "on" }, [
                            _c(
                              "button",
                              {
                                attrs: { disabled: _vm.switchBtnVis },
                                on: {
                                  click: function($event) {
                                    return _vm.updateStatus(
                                      item.meterAddr,
                                      true
                                    )
                                  }
                                }
                              },
                              [_vm._v("合闸")]
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              }),
              0
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headImg" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/icon_property.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }