var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "searchSwitch" } }, [
    _vm.btnVis
      ? _c(
          "span",
          { staticClass: "ls", on: { click: _vm.switchstatusEvent } },
          [_vm._v("查询")]
        )
      : _vm._e(),
    _vm.searchingVis
      ? _c("span", { staticClass: "ls" }, [
          _c(
            "span",
            {
              staticClass: "van-loading__spinner van-loading__spinner--spinner"
            },
            [
              _c(
                "svg",
                {
                  staticClass: "van-loading__circular",
                  attrs: { viewBox: "25 25 50 50" }
                },
                [
                  _c("circle", {
                    attrs: { cx: "50", cy: "50", r: "20", fill: "none" }
                  })
                ]
              )
            ]
          )
        ])
      : _vm._e(),
    _vm.searchresultVis
      ? _c("span", { staticClass: "ls borderNone" }, [
          _vm._v(_vm._s(_vm.switchStatus))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }