<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: zengfq
 * @LastEditTime: 2020-04-25 15:30:40
 -->
<template>
    <div class="recharge" id="recharge">
        <div class="billContent">
            <div class="billTop">
                <div class="name">广东浩迪创新科技有限公司</div>
                <div class="address">浩迪总部-A3栋-研发部-E10</div>
            </div>
            <div class="rechargeCard">
                <div class="rechargeTime">
                    <span>充值时间：2020-04-25 18:25:25</span>
                </div>
                <div class="text">
                    <span class="name">表计表号</span>
                    <span>12132455666</span>
                </div>
                <div class="text">
                    <span class="name">支付金额</span>
                    <span class="money">￥12</span>
                </div>
                <div class="text">
                    <span class="name">支付状态</span>
                    <span>已收款</span>
                </div>
                <div class="text">
                    <span class="name">服务费</span>
                    <span class="money">￥6</span>
                </div>
                <div class="text">
                    <span class="name">支付方式</span>
                    <span>微信扫码</span>
                </div>
                <div class="text">
                    <span class="name">充值状态</span>
                    <span>已完成</span>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
    export default {
        name: "recharge",
        data() {
            return {
                showDetail: false


            };
        },
        mounted() {

        },
        created() {


        },
        methods: {



        }
    };
</script>