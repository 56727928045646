var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fs14" },
    [
      _c("van-nav-bar", {
        attrs: { "left-text": "返回", "left-arrow": "" },
        on: {
          "click-left": function($event) {
            return _vm.$router.go(-1)
          },
          "click-right": _vm.addClient
        },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function() {
              return [_c("van-icon", { attrs: { name: "plus" } })]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "van-cell-group",
        { attrs: { inset: "" } },
        [
          _c("van-cell", {
            attrs: { title: "终端", label: "222022212" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("van-tag", { attrs: { type: "danger", plain: "" } }, [
                      _vm._v("离线")
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._m(0),
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        _vm._l(_vm.list, function(item) {
          return _c("van-cell", {
            key: item,
            attrs: { title: item },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("span", [_vm._v(_vm._s(item))]),
                      _vm._v(" "),
                      _c("van-tag", { attrs: { type: "danger", plain: "" } }, [
                        _vm._v("离线")
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("van-button", {
                        attrs: {
                          type: "danger",
                          size: "mini",
                          icon: "delete-o"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        }),
        1
      ),
      _c("ClientAdd", { ref: "ClientAdd" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("strong", { staticClass: "f-title" }, [_vm._v("电表列表")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }