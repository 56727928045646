<!--
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2020-04-25 11:10:48
 * @LastEditors: yql
 * @LastEditTime: 2020-11-23 17:12:44
 -->

<template>
    <div class="electRecharge" id="electRecharge">
        <div class="electInfoHead">
            <div class="switchElect" @click="electSelectIs = true" v-if="metersList.length>1">切换电表</div>
            <h4>{{userInfos.propName}}</h4>
            <p class="electAdress">{{userInfos | installAdressFormat}}</p>
            <p class="electNum" v-if="metersList.length>0">电表：{{chargesubmitMode.meterAddr}}</p>
        </div>
        <div v-if="metersList.length>0">
            <div class="electBillInfo">
                <div class="unitL"> 电表余额 <span class="refresh" @click="refresh">
                        <img src="../../assets/images/icon_refresher.png" alt />
                    </span>
                    <span class="blue bold">￥{{balance}}</span>
                </div>
                <div class="unitL"> 抄读时间 <span>{{currentReadTime}}</span>
                </div>
                <div class="moneyChoice">
                    <!-- <span>￥100</span><span>￥200</span><span>￥300</span><span>￥400</span><span>￥500</span> -->
                    <span v-for="(item,index) in chargeList" :class="{'active':item.id == chargeChecked}" :key="index" @click="chargePick(item)">￥{{item.label}}</span>
                    <span class="value">
                        <input type="number" class="chargeInput" @keyup="numInitKeyOn($event)" @afterpaste="numInitOnafterpaste($event)" v-model="chargeMode.rechargeAmount" :placeholder="placeholder" @input="chargeInput" />
                        <!-- 10-1000的整数 -->
                    </span>
                </div>
                <div class="moneyShow"> 充值金额: <span class="valueS">￥{{chargePayAmount | formatMoneyThousand}}</span>
                    <span class="rateS" v-if="serviceCharge !== null">(含服务费￥{{serviceCharge | formatMoneyThousand}})</span>
                    <span class="right" v-if="this.chargeMode.rechargeAmount !== null"> 到账金额: <span class="valueS">￥{{this.chargeMode.rechargeAmount}}</span>
                    </span>
                </div>
            </div>
            <div class="rechargeBtn">
                <button class @click="chargeSubmit()">确认充值</button>
            </div>
        </div>
        <div class="noData" v-else>
            <img src="../../assets/images/noDataPic.png" />
            <p>当前房间没有可充值电表</p>
        </div>
        <div class="goIndex" @click="$router.push('/index')"></div>
        <van-overlay :show="electSelectIs" @click="electSelectIs = false">
            <div class="electSelectBox">
                <div class="electLister" @click.stop>
                    <h3> 请选择电表 <i @click="electSelectIs = false" class="van-icon van-icon-cross van-action-sheet__close">
                            <!---->
                        </i>
                    </h3>
                    <div class="unitBox">
                        <div class="electUnit" :class="{'selected':item.meterAddr ==  chargeMode.fid}" v-for="(item,index) in metersList" :key="index" @click="changeElect(item.meterAddr)">
                            <h5> 表号：{{item.meterAddr}} <span v-if="item.balance !== null"> (余额： <i :class="{reder:item.balance<item.balanceNotEnough}">{{item.balance}}</i>) </span>
                            </h5>
                            <p>{{item.roomName}}</p>
                            <i class="van-icon van-icon-success van-selecteder"></i>
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>
        <div class="goIndex" @click="$router.push('/index')"></div>
      <div ref="form"></div>
    </div>
</template>
<script>
  import request from '@/utils/request'

export default {
    name: "recharge",
    components: {},
    data() {
        return {
            userInfos: {},
            rechargeInfos: null,
            chargeList: null,
            chargesubmitMode: {
                "meterAddr": "",
                "rechargeAmount": 0,
                "redirectUrl": window.location.origin + '/#/index'
            },
            chargeMode: {
                "fids": [],
                "rechargeAmount": null,
                "type": 2
            },
            chargeChecked: null,
            chargePayAmount: null,
            serviceCharge: null,
            minRecharge: null,
            maxRecharge: null,
            placeholder: null,
            balance: null,
            currentReadTime: null,
            electSelectIs: false,
            metersList: [],
            form:''
        };
    },
    mounted() {

    },
    created() {
        this.load.$emit('loading', true)
        this.chargeList = this.chargeLists()
        this.getUserInfo()
        this.getMeterList()

        // this.chargeMode.fid.splice(0)
        // this.chargeMode.fid.push(this.$route.query.meterAddr)
        // this.chargesubmitMode.meterAddr = this.$route.query.meterAddr
        // console.log(this.chargeMode)
        // console.log(this.$route.query.meterAddr)
    },
    methods: {
        numInitKeyOn(e) {
            if (e.target.value.length == 1) { e.target.value = e.target.value.replace(/[^1-9]/g, '') } else { e.target.value = e.target.value.replace(/\D/g, '') }
            this.chargeMode.rechargeAmount = e.target.value
        },
        numInitOnafterpaste(e) {
            if (e.target.value.length == 1) { e.target.value = e.target.value.replace(/[^1-9]/g, '') } else { e.target.value = e.target.value.replace(/\D/g, '') }
            this.chargeMode.rechargeAmount = e.target.value
        },
        //切换电表
        changeElect(fid) {
            this.chargeMode.fids.splice(0)
            this.chargeMode.fids.push(fid)
            this.chargesubmitMode.meterAddr = fid
            this.getElectricInfo()
            this.chargeMode.rechargeAmount = null
            this.chargesubmitMode.rechargeAmount = null
            this.chargeChecked = null
            this.chargePayAmount = null
            this.serviceCharge = null
            this.electSelectIs = false
        },
        //获取电表列表
        getMeterList() {
            this.load.$emit('loading', true)
            this.$store.dispatch('meter/rechargeMeters', {}).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.metersList = result.data.prepaymentList
                    if (result.data.prepaymentList.length > 0) {
                        if (this.$route.query.meterAddr) {
                            this.chargeMode.fids.splice(0)
                            this.chargeMode.fids.push(this.$route.query.meterAddr)
                            this.chargesubmitMode.meterAddr = this.$route.query.meterAddr
                        } else {
                            this.chargeMode.fids.splice(0)
                            this.chargeMode.fids.push(result.data.prepaymentList[0].meterAddr)
                            this.chargesubmitMode.meterAddr = result.data.prepaymentList[0].meterAddr
                        }
                        this.getElectricInfo()

                    }
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                if (error.message.includes('timeout')) {
                    this.$confirm({
                        mesg: '表计列表请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
      setConfig(data){
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timeStamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature:  data.paySign,// 必填，签名
          jsApiList: [
            'chooseWXPay'
          ] // 必填，需要使用的JS接口列表
        });
      },
      chooseWXPay(data){
        wx.chooseWXPay({
          timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
          package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign:  data.paySign, // 支付签名
          success: function (res) {
            // 支付成功后的回调函数
            console.log(res)
          }
        })
      },
        //电表充值页信息
        getElectricInfo() {
            this.$store.dispatch('meter/electricRechargeInfo', { fid: this.chargesubmitMode.meterAddr }).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.minRecharge = result.data.minRecharge + ''
                    this.maxRecharge = result.data.maxRecharge + ''
                    this.currentReadTime = result.data.currentReadTime
                    this.balance = result.data.balance
                    if (result.data.maxRecharge == 0) {
                        this.placeholder = "大于" + result.data.minRecharge + "的整数";
                    } else {
                        this.placeholder = result.data.minRecharge + "-" + result.data.maxRecharge + "的整数";
                    }
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                if (error.message.includes('timeout')) {
                    this.$confirm({
                        mesg: '用户信息请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        //获取用户信息
        getUserInfo() {
            this.$store.dispatch('property/userInfo', {}).then((result) => {
                if (result.returnCode == 0) {
                    // this.userInfos = result.data;
                    this.$set(this, 'userInfos', result.data)
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                if (error.message.includes('timeout')) {
                    this.$confirm({
                        mesg: '用户信息请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        refresh() {
            this.load.$emit('loading', true)
            this.$store.dispatch('meter/refreshBalance', { fid: this.chargesubmitMode.meterAddr }).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.currentReadTime = result.data.currentReadTime
                    this.balance = result.data.balance
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                if (error.message.includes('timeout')) {
                    this.$confirm({
                        mesg: '数据请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        // 输入金额change
        chargeInput() {
            if (this.chargeMode.rechargeAmount.toString().length > 14) {
                this.$confirm({
                    mesg: '充值金额必须小于14位',
                    cancel: false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }
            // else if(this.chargeMode.rechargeAmount.toString() === '' || this.chargeMode.rechargeAmount.toString() == null){

            // }
            this.chargeChecked = this.chargeMode.rechargeAmount
            if (this.chargeMode.rechargeAmount == null || this.chargeMode.rechargeAmount == '' || this.chargeMode.rechargeAmount == 0) {
                this.chargePayAmount = 0
                this.serviceCharge = 0
                this.chargeMode.rechargeAmount = 0
                return
            }
            this.computeAmount(this.chargeMode)
        },
        //充值Event
        chargeEvent(meterAddr) {
            this.chargeVis = true
            this.chargesubmitMode.meterAddr = meterAddr
            this.chargeMode.fids.push(meterAddr)
        },
        //点击选中输入金额
        chargePick(item) {
            this.chargeChecked = item.id
            this.chargeMode.rechargeAmount = item.label
            this.computeAmount(this.chargeMode)
        },
        // 充值提交
        chargeSubmit() {
           let self=this
            this.load.$emit('loading', true)
            this.$store.dispatch('property/electricRecharge', this.chargesubmitMode).then((result) => {
                this.load.$emit('loading', false)
              debugger
                if (result.returnCode == 0) {
                  if (result.data.value0 == 53) {
                    this.load.$emit('loading', true)
                    request({
                      url: result.data.value2,
                      method: 'get'
                    }).then(res => {
                      this.load.$emit('loading', false)
                      if (res.returnCode != 0) {
                        this.$confirm({
                          mesg: res.returnMsg,
                          cancel: false,
                          btn: {
                            confirmVal: "确 定"
                          }
                        })
                        return
                      }
                      let data = JSON.parse(res.data.body.payinfo)
                      self.setConfig(data)
                      wx.ready(function () {
                        self.chooseWXPay(data)
                      });
                    })
                    return
                  }
                  else if (result.data.value0 == 50) {
                    window.location.href = result.data.value2
                  }
                  else if (result.data.value0 == 54) {
                    request({
                      url: result.data.value2,
                      method: 'get'
                    }).then(res => {
                      let eleScript = document.createElement("script")
                      eleScript.innerHTML = res
                      self.$refs.form.append(eleScript)
                    })
                  }
                  else if (result.data.value0 == 56) {

                    request({
                      url: result.data.value2,
                      method: 'get'
                    }).then(res => {
                      if (res.returnCode != 0) {
                        this.$confirm({
                          mesg: res.returnMsg,
                          cancel: false,
                          btn: {
                            confirmVal: "确 定"
                          }
                        })
                        return
                      }
                      window.location.href = res.data
                    })
                  }
                  else if (result.data.value0 == 57) {
                    this.load.$emit('loading', true)
                    request({
                      url: result.data.value2,
                      method: 'get'
                    }).then(res => {
                      this.load.$emit('loading', false)
                      if (res.returnCode != 0) {
                        this.$confirm({
                          mesg: res.returnMsg,
                          cancel: false,
                          btn: {
                            confirmVal: "确 定"
                          }
                        })
                        return
                      }
                      let data = res.data
                      data.package = data.pkg
                      self.setConfig(data)
                      wx.ready(function () {
                        self.chooseWXPay(data)
                      })
                    })
                  }
                  else if (result.data.value0 == 92) {
                    console.log(result.data.value2)
                    this.load.$emit('loading', true)
                    request({
                      url: result.data.value2,
                      method: 'get'
                    }).then(res => {
                      this.load.$emit('loading', false)
                      if (res.returnCode == 0) {
                        document.write(res.data)
                      }
                    })


                  }

                }
                else {
                    this.$confirm({
                      mesg: result.returnMsg,
                      cancel: false,
                      btn: {
                        confirmVal: "确 定"
                      }
                    })
                  }
            }).catch(error => {
                this.load.$emit('loading', false)
                if (error.message.includes('timeout')) {
                    this.$confirm({
                        mesg: '请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        //获取服务费
        computeAmount(mode) {
            this.load.$emit('loading', true)
            this.$store.dispatch('property/computeAmount', mode).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.chargesubmitMode.rechargeAmount = result.data.amount
                    this.serviceCharge = result.data.serviceAmount
                    this.chargePayAmount = result.data.payAmount

                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                this.load.$emit('loading', false)
                if (error.message.includes('timeout')) {
                    this.$confirm({
                        mesg: '数据请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        //获取表计地址信息
        getMainList() {
            this.load.$emit('loading', true)
            this.$store.dispatch('property/userInfo', {}).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.userInfos = result.data;
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
    }
};
</script>
<style lang="scss" scoped>
.electSelectBox {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 100%;

    .electLister {
        background-color: #fff;
        width: 80%;
        overflow: hidden;
        border-radius: 10px;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
        h3 {
            font-size: 30px;
            line-height: 90px;
            border-bottom: 1px solid #e2e2e2;
            padding-left: 30px;
            background-color: #078ee8;
            color: #fff;
            font-weight: normal;
            position: relative;
            i {
                top: 0;
                color: #fff;
            }
        }
        .unitBox {
            padding: 0 15px;

            .electUnit {
                padding: 20px;
                border-bottom: 1px solid #e2e2e2;
                position: relative;
                color: #333;
                h5 {
                    font-weight: normal;
                    font-size: 30px;
                    span {
                        font-size: 25px;
                        color: #666;
                        i {
                            font-style: normal;
                            &.reder {
                                color: #ee0a24;
                            }
                        }
                    }
                }
                p {
                    padding-top: 5px;
                    font-size: 26px;
                    color: #c3c1c1;
                }
                i.van-selecteder {
                    position: absolute;
                    right: 15px;
                    top: 30%;
                    font-size: 45px;
                    color: #078ee8;
                    display: none;
                }
                &.selected {
                    h5 {
                        color: #ee0a24;
                    }

                    i.van-selecteder {
                        color: #ee0a24;
                        display: inline-block;
                        // padding:5px;
                        // border:3px solid #ee0a24;
                        // border-radius: 20px;
                    }
                }
            }
        }
    }
}
.electRecharge {
    background: url("~@/assets/images/bg_3.png") no-repeat top center;
    background-size: 100% auto;
    padding: 50px 21px 112px 21px;
    .rechargeBtn {
        padding-top: 40px;
        button {
            height: 80px;
            line-height: 80px;
            letter-spacing: 9px;
            display: inline-block;
            width: 100%;
            background-color: #0893ea;
            border-radius: 10px;
            -webkit-appearance: none;
            border: none;
            outline: none;
            font-size: 30px;
            color: #fff;
        }
    }
    .electInfoHead {
        padding: 29px 36px;
        box-shadow: 0 0px 12px 2px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        color: #333;
        border-radius: 20px;
        position: relative;
        .switchElect {
            position: absolute;
            background-color: #078ee8;
            color: #fff;
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            border-radius: 8px;
            bottom: 20px;
            right: 30px;
            font-size: 28px;
        }
        h4 {
            font-size: 34px;
            padding-bottom: 16px;
            font-weight: normal;
            line-height: 34px;
        }
        p {
            &.electAdress {
                font-size: 26px;
                padding-bottom: 16px;
                line-height: 26px;
            }
            &.electNum {
                font-size: 25px;
                color: #078ee8;
                line-height: 30px;
            }
            span {
                background-color: #0893ea;
                color: #fff;
                text-align: center;
                display: inline-block;
                float: right;
                font-size: 20px;
                line-height: 30px;
                height: 30px;
                // width: 105px;
                padding: 0 14px;
                border-radius: 10px;
            }
        }
    }
    .electBillInfo {
        padding-top: 35px;
        .moneyShow {
            padding-top: 7px;
            font-size: 26px;
            white-space: nowrap;
            span {
                display: inline-block;
                font-size: 26px;
                // line-height:26px;
                color: #333;
                &.valueS {
                    color: #0893ea;
                }
                &.rateS {
                    font-size: 20px;
                    color: #999;
                    padding-right: 44px;
                }
                &.right {
                    float: right;
                }
            }
        }
        .moneyChoice {
            padding-top: 35px;
            text-align: center;
            span {
                display: inline-block;
                width: 216px;
                height: 74px;
                border: 2px solid #0893ea;
                border-radius: 7px;
                font-size: 30px;
                line-height: 74px;
                text-align: center;
                color: #0893ea;
                margin-right: 13px;
                margin-bottom: 23px;
                // overflow:hidden;
                margin-left: 3px;
                &.editMoney {
                    font-size: 24px;
                    color: #999;
                }
                &:empty:before {
                    content: attr(placeholder);
                }
                &:focus:before {
                    content: none;
                }
                &.value {
                    background: url("~@/assets/images/icon_gou.png") no-repeat
                        bottom right;
                    background-size: 33px 33px;
                }
                input.chargeInput {
                    width: 100%;
                    height: 70px;
                    vertical-align: top;
                    border-radius: 10px;
                    text-align: center;
                    font-size: 24px;
                    background-color: transparent;
                }
                &.active {
                    background-color: #0893ea;
                    color: #fff;
                }
            }
        }
        .unitL {
            // text-align: justify;
            font-size: 26px;
            color: #333;
            height: 72px;
            line-height: 72px;
            border-bottom: 1px solid #eaeaea;
            span {
                display: inline-block;
                float: right;
                vertical-align: middle;
                font-size: 24px;
                color: #666;
                &.blue {
                    font-size: 30px;
                    color: #0893ea;
                }
                &.refresh {
                    padding-left: 22px;
                    // width: 55px;
                    //  height:30px;
                    //  background: url('~@/assets/images/icon_refresher.png') no-repeat right center;
                    //  background-size: 30px;
                    img {
                        width: 30px;
                        height: 30px;
                        vertical-align: middle;
                    }
                }
            }
            // &:after {
            //     content: " ";
            //     display: inline-block;
            //     width: 100%;
            // }
        }
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
</style>
