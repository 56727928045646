<!--
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-05-29 16:43:32
 -->
<template>
  <div
    class="recharge"
    id="billDetail"
    v-wechat-title="($route.meta.title = metaTitle)"
  >
    <!-- 账单缴费 -->
    <div class="billContent">
      <div class="billTop">
        <div class="name">{{ propertyName }}</div>
        <div class="address">{{ roomName }}</div>
      </div>
      <div v-for="(item, key) in billDetail" :key="key">
        <div class="billBox" style="margin-top: 10px;">
          <div class="mainBox" :class="{ radius: item.showDetail }">
            <div
              class="chargeArea paid"
              v-if="item.payStatus == 2"
              @click="payEvent(item)"
            >
              缴费
            </div>
            <div class="chargeArea" v-else @click="payEvent(item)">
              <span>本月<br />已结清</span>
            </div>
            <div class="name" @click="payEvent(item)">
              <span class="month">{{ month }}月账单</span>
              <span class="time">{{ item.startDate }}-{{ item.endDate }}</span>
              <!-- <span>单位：元</span> -->
            </div>
            <div class="amount justify" @click="payEvent(item)">
              账单金额 ￥{{ item.actualAmount }}
              <!-- <span class="serviceFees" v-if="item.status==3 && item.serviceAmount && item.serviceAmount !== 0 && item.serviceAmount !== null">(含服务费:￥{{item.serviceAmount}})</span> -->
              <!-- <span class="payStatus" :class="{'reder':item.payStatus == 2}">{{payStatusEnum[item.payStatus]}}</span> -->
              <button class="singleBtn" @click.stop="getPdf(item)">
                查看结算单
              </button>
              <div
                class="downIcon"
                v-if="item.status !== 2"
                @click.stop="item.showDetail = !item.showDetail"
              >
                更多详情
                <span :class="{ up: item.showDetail }"></span>
              </div>
            </div>
            <div class="amountDetail" v-if="item.status == 2">
              <div>
                <p class="tit">已缴金额</p>
                <p class="money">￥{{ item.paidAmount }}</p>
              </div>
              <div class="bborder"></div>
              <div>
                <p class="tit">未缴金额</p>
                <p class="money">￥{{ item.arrearageAmount }}</p>
              </div>
              <div
                class="downIcon"
                @click.stop="item.showDetail = !item.showDetail"
              >
                更多详情
                <span :class="{ up: item.showDetail }"></span>
              </div>
            </div>
          </div>
          <div v-if="item.showDetail" class="detailBoxs">
            <div class="billDetail" v-for="bill in item.bills" :key="bill.fid">
              <div @click="payEvent(item)">
                <span
                  >{{ bill.name
                  }}<span v-if="bill.meterAddr"
                    >-表计表号:{{ bill.meterAddr }}</span
                  ><span
                    class="status"
                    :class="{ green: bill.payStatus !== 2 }"
                    >{{ payStatusEnum[bill.payStatus] }}</span
                  ></span
                >
                <span>￥{{ bill.amount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="noData" v-if="billDetail.length == 0">
        <img src="../../assets/images/noDataPic.png" />
        <p>暂无数据</p>
      </div>
    </div>

    <div class="goIndex" @click="$router.push('/index')"></div>
    <van-popup
      v-model="showPop"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <div ref="printContent" @click="showPdfImg">
        <template v-if="showPdf">
          <pdf
            ref="pdf"
            v-for="i in pdfPageNums"
            :key="i"
            :src="pdfSrc"
            :page="i"
          ></pdf>
        </template>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import pdf from "vue-pdf";
import html2canvas from "html2canvas";
import { ImagePreview } from "vant";
export default {
  name: "billDetail",
  components: {
    pdf
  },
  data() {
    return {
      billDetail: [],
      month: this.$route.query.month,
      payStatusEnum: null,
      userInfos: {},
      metaTitle: "",
      showPdf: false,
      showPop: false,
      pdfSrc: "",
      pdfPageNums: "",
      selPdf: ""
    };
  },
  mounted() {
    this.metaTitle =
      this.$route.query.year + "年" + this.$route.query.month + "月账单";
  },
  computed: {
    ...mapGetters(["propertyName", "roomName"])
  },
  created() {
    this.getBillDetail();
    this.payStatusEnum = this.payStatusEnums();
    this.getUserInfo();
  },
  methods: {
    getPdf(item) {
      if (this.selPdf == item.fid) {
        this.showPop = true;
        return;
      }
      this.showPdf = false;
      this.load.$emit("loading", true);
      this.$store
        .dispatch("meter/billPdf", { fid: item.fid })
        .then(result => {
          this.load.$emit("loading", false);
          this.selPdf = item.fid;

          // this.pdfSrc = pdf.createLoadingTask({
          //   url: URL.createObjectURL(result),
          //   cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/",
          //   cMapPacked: true
          // });
          this.pdfSrc = pdf.createLoadingTask({
            url: URL.createObjectURL(result),
            cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/",
            cMapPacked: true
          });
          this.pdfSrc.promise.then(pdf => {
            // 获取pdf的页数
            this.pdfPageNums = pdf.numPages;
          });

          this.$nextTick(() => {
            this.showPdf = true;
            this.showPop = true;
          });
        })
        .catch(e => {
          this.load.$emit("loading", false);
          this.showPop = false;
          this.$confirm({
            mesg: "单据异常",
            cancel: false,
            btn: {
              confirmVal: "确 定"
            }
          });
          console.log(e);
        });
    },
    showPdfImg() {
      let self = this;
      html2canvas(this.$refs.printContent, {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then(canvas => {
        const url = canvas.toDataURL();
        self.showImg(url);
      });
    },
    downloadPdfImg() {
      this.load.$emit("loading", true);
      let self = this;
      html2canvas(this.$refs.printContent, {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then(canvas => {
        // canvas为转换后的Canvas对象
        this.load.$emit("loading", false);
        let oImg = new Image();
        oImg = canvas.toDataURL("image/png"); // 导出图片
        console.log(oImg);

        var oA = document.createElement("a");
        oA.download = ""; // 设置下载的文件名，默认是'下载'
        oA.href = oImg;
        document.body.appendChild(oA);
        oA.click();
        oA.remove(); // 下载之后把创建的元素删除
      });
    },
    showImg(url) {
      let self = this;
      ImagePreview({
        images: [url],
        showIndex: false,
        closeable: true,
        onClose() {
          // self.showPop = false;
        }
      });
    },
    // 获取用户信息
    getUserInfo() {
      this.$store
        .dispatch("property/userInfo", {})
        .then(result => {
          if (result.returnCode == 0) {
            this.$set(this, "userInfos", result.data);
          } else {
            this.$confirm({
              mesg: result.returnMsg,
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        })
        .catch(error => {
          if (error.message.includes("timeout")) {
            this.$confirm({
              mesg: "用户信息请求超时",
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        });
    },
    getBillDetail() {
      this.load.$emit("loading", true);
      this.$store
        .dispatch("property/getBillInfo", { period: this.$route.query.period })
        .then(result => {
          this.load.$emit("loading", false);
          if (result.returnCode == 0) {
            let bill = result.data;
            this.billDetail = bill.map(v => {
              this.$set(v, "showDetail", true);
              return v;
            });
          } else {
            this.$confirm({
              mesg: result.returnMsg,
              cancel: false,
              btn: {
                confirmVal: "确 定"
              }
            });
          }
        });
    },

    payEvent(item) {
      this.$router.push({
        path: "/notPayBills",
        query: {
          fid: item.fid,
          flag: true
        }
      });
      // let mode = {
      //     fid: '',
      //     redirectUrl:''
      // }
      // mode.fid= item.fid
      // mode.redirectUrl =window.location.origin + '/#/billDetail'
      // this.load.$emit('loading', true)
      // this.$store.dispatch('property/billPayment',mode).then((result) => {
      //     this.load.$emit('loading', false)
      //     if (result.returnCode == 0) {
      //         window.location.href = result.data
      //     } else {
      //         this.$confirm({
      //             mesg: result.returnMsg,
      //             cancel:false,
      //             btn: {
      //                 confirmVal: "确 定"
      //             }
      //         })
      //     }
      // })
    }
  }
};
</script>
