var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      staticStyle: { width: "100px", height: "50px" },
      attrs: { type: "button", value: "测试" },
      on: { click: _vm.getGenerateAllinpayOrder }
    }),
    _c("div", { ref: "form" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }