<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-10-12 11:02:41
 -->
<template>
    <div class="accountBox" id="account">
        <div class="header">
            <div class="headImg"><img src="../../assets/images/img_default2.png" v-if="userInfo.head ==null || userInfo.head == ''"><img :src="userInfo.head" v-else></div>
            <div class="textInfo">
                <h4>{{userInfo.name}}</h4>
                <div class="phone">{{userInfo.mobile}}</div>
            </div>
        </div>

        <div class="block">
            <div class="unit" @click="changeNameEvent">更改用户名<span class="right">{{userInfo.name}}</span></div>
            <div class="unit" @click="changePhoneEvent">资料迁移<span class="right">{{userInfo.mobile}}</span></div>
            <div class="unit" @click="changeEmailEvent">更改绑定电子邮箱<span class="right">{{userInfo.email}}</span></div>
        </div>

        <div class="block">
            <div class="unit" @click="changeAccountEvent">账号管理</div>
        </div>

        <!-- <div class="block">
            <div class="unit" @click="payRecordtEvent">支付记录</div>
        </div> -->

        <div class="btnAreaBox">
            <!-- <div class="btnDiv"><button class="btn" @click="switchProperty">切换物业</button></div> -->
            <div class="btnDiv"><button class="btn" @click="logout">注销登录</button></div>
        </div>

        <verifyCode ref="verifyCode" :verifyCodeDialog="verifyCodeDialog" :submitMode="submitMode"></verifyCode>

        <div class="goIndex" @click="$router.push('/index')"></div>
        <view-footer></view-footer>

    </div>
</template>
<script>
import verifyCode from './components/verifyCode'
export default {
    name: "account",
    components: {verifyCode},
    data() {
        return {
            userInfo:{
                "areaName": "",
                "buildName": "",
                "email": "",
                "floorName": "",
                "head": "",
                "mobile": "",
                "name": "",
                "propName": "",
                "roomName": "",
                "tenantFid": "",
                
            },
            verifyCodeDialog:{
                dialogVis:false
            },
            submitMode:{
                'mobile':'',
                'verifyCode':''
            }
        };
    },
    mounted() {  
    },
    created(){
        this.getMainList()
    },
    methods: {
        //获取主列表
        getMainList(){
            this.load.$emit('loading', true)
            this.$store.dispatch('property/userInfo',{}).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.userInfo = result.data;
                    this.submitMode.mobile = result.data.mobile
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                this.load.$emit('loading', false)
                if(error.message.includes('timeout')){
                    this.$confirm({
                        mesg: '数据请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        // 更改用户名
        changeNameEvent(){
            this.$router.push('/changeName')
        },
        // 更改手机号
        changePhoneEvent(){
            let self = this
            let phoneFormat = this.userInfo.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
            this.$confirm({
                title:'更改绑定手机',
                cntMsg: "为了保护您的账号安全,需要对现在绑定的手机号进行短信验证<br/>(" + phoneFormat+")",
                cancel:false,
                btn: {
                    confirmVal: "确 定"
                }
            })
            .then(() => {
                this.load.$emit('loading', true)
                self.$store.dispatch('property/oldMobileSend', {'mobile':this.userInfo.mobile}).then((result) => {
                    this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    self.$refs.verifyCode.countDown()
                    self.verifyCodeDialog.dialogVis = true
                    self.$refs.verifyCode.inputFocus()
                } else {
                    self.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                    }
                }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
                
            })    
        },
        //更改邮箱
        changeEmailEvent(){
            this.$router.push('/changeEmail')
        },
        //账户管理
        changeAccountEvent(){
            this.$router.push('/changeAccount')
        },
        //切换物业
        switchProperty(){
            this.$router.push('/property')
        },
        //支付记录
        payRecordtEvent(){
            this.$router.push('/payRecord')
        },
        //注销登录
        logout(){

            this.$confirm({
                mesg: "确定注销登录吗？",
                btn: {
                    confirmVal: "确 定",
                    cancelVal:"取 消"
                }
            }).then(() => {
                this.$store.dispatch('user/logout')
                this.$store.dispatch('property/removeContract')
            })
            .catch(() => {
                console.log("no");
            });
            
        }
    }
};
</script>
