<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-06-03 10:13:02
 -->
<template>
    <div class="alarmer" id="alarm">

        <!-- 选择搜索结果 -->
        <div class="result">

            <div class="titleA white"><span>当前告警</span></div>
            <div class="boxers" v-if="currentAlarmList.length>0">
                <div class="resultUnit" v-for="(item,index) in currentAlarmList" :key="index">
                        <h3 class="title" :class="{'green':item.relieve,'red':!item.relieve}"><span>{{item.alarmName}}</span></h3>
                        <div class="texter">
                            <div class="info"><p>产生原因：<span>{{item.happenContent}}</span></p></div>
                            <div class="textShow">
                                <div class="detailUnit">
                                    <div class="label">表计表号</div>
                                    <div class="text">{{item.meterAddr}}</div>
                                </div>
                                <div class="detailUnit">
                                    <div class="label">告警时间</div>
                                    <div class="text">{{item.happenTime}}</div>
                                </div>
                                <div class="detailUnit" v-if="item.relieve">
                                    <div class="label">解除时间</div>
                                    <div class="text">{{item.relieveTime}}</div>
                                </div>
                                
                            </div>
                        
                        </div>
                    </div>
            </div>
            <div class="noMeter" v-else>
                当前房间电表运行正常，无告警信息!
            </div>

            <div class="titleA top"><span>历史告警</span></div>
            <div class="boxers" v-if="alarmLists.length>0">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad">
                    <div class="resultUnit" v-for="(item,index) in alarmLists" :key="index">
                        <h3 class="title" :class="{'green':item.relieve,'red':!item.relieve}"><span>{{item.alarmName}}</span></h3>
                        <div class="texter">
                            <div class="info">
                                <p>产生原因：<span>{{item.happenContent}}</span></p>
                                <p>解除原因：<span>{{item.relieveContent}}</span></p>
                                </div>
                            <div class="textShow">
                                <div class="detailUnit">
                                    <div class="label">表计表号</div>
                                    <div class="text">{{item.meterAddr}}</div>
                                </div>
                                <div class="detailUnit">
                                    <div class="label">告警时间</div>
                                    <div class="text">{{item.happenTime}}</div>
                                </div>
                                <div class="detailUnit" v-if="item.relieve">
                                    <div class="label">解除时间</div>
                                    <div class="text">{{item.relieveTime}}</div>
                                </div>
                                
                            </div>
                        
                        </div>
                    </div>
                </van-list>
                
            </div>

            <div class="noData" v-else>
                <img src="../../assets/images/noDataPic.png"/>
                <p>无历史告警记录</p>
            </div>

        </div>  

        <div class="goIndex" @click="$router.push('/index')"></div>


    </div>
</template>
<script>
export default {
    name: "alarm",
    data() {
        return {
            searchMode:{
                pageNo: 1,
	            pageSize: 10
            },
            NoDataVis:false,
            alarmLists:[],
            loading: false,
            finished: false,
            currentAlarmList:[]

        };
    },
    mounted() {
    },
    created(){
        this.getMainList(this.searchMode)
        this.getCurrentAlarm()
    },
    watch:{
    },
    methods: {
        //获取当前告警列表
        getCurrentAlarm(){
            this.$store.dispatch('property/getCurrentAlarm',{}).then((result) => {
                if (result.returnCode == 0) {
                    this.$set(this,'currentAlarmList',result.data)
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                if(error.message.includes('timeout')){
                    this.$confirm({
                        mesg: '数据请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });
        },
        //获取告警记录
        getMainList(param){
            this.load.$emit('loading', true)
            this.$store.dispatch('property/getAlarmHistory',param).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.alarmLists.push(...result.data.data)
                    this.loading = false
                    if (this.alarmLists.length >= result.data.totalCount) {
                        this.finished = true
                    }
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                this.load.$emit('loading', false)
                if(error.message.includes('timeout')){
                    this.$confirm({
                        mesg: '数据请求超时',
                        cancel: false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            });

        },
        onLoad() {
            this.searchMode.pageNo+=1
            //console.log(this.searchMode)
            this.getMainList(this.searchMode)
        },
    }
};
</script>
<style>
    .van-list__loading, .van-list__finished-text, .van-list__error-text {
        color: #969799;
        font-size: 28px;
        line-height: 50px;
        text-align: center;
    }
</style>