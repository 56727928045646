/*
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-10 15:47:23
 * @LastEditors: yql
 * @LastEditTime: 2021-01-23 17:45:23
 */
import Vue from 'vue'
import confirmDom from './message.vue'

// console.log(confirmDom)

let dialogContainer = document.body;

let confirmConstructor = Vue.extend(confirmDom);

let confirmer =  (option) => {
    return new Promise((res, rej) => { 
      let confirmDom = new confirmConstructor({   
        el: document.createElement('div')
      })
      
      document.body.appendChild(confirmDom.$el);  
      confirmDom.options = Object.assign( confirmDom.options,option);   
      confirmDom.ok = () => {
        res()
        confirmDom.isShow = false
      }
      confirmDom.close = () => {
        rej()
        confirmDom.isShow = false
      }

    })
	
    // return {options,vm}
  }

//删除组件vm（从文本流中移除，并在vue中销毁）
function dialogClose(vm){
	dialogContainer.removeChild(vm.$el);
	vm.$destroy();
}

export default confirmer;  