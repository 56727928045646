var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer", attrs: { id: "footers" } }, [
    _vm._v(" 技术支持：广东浩迪创新科技有限公司 "),
    _c("br"),
    _vm._v("@" + _vm._s(_vm.dataTime) + " ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }