<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-04-30 10:33:24
 -->
<template>
    <div class="accountBuild" id="accountBuild">
        <div class="addTitle">账号信息录入</div>
        <div class="blocker">
            <div class="unit">
                <div class="labeler">姓<span>名</span></div>
                <div class="texter"><input type="text" @blur="trims" placeholder="请输入姓名" v-model="submitMode.name"/></div>
            </div>

            <div class="unit ">
                <div class="labeler">联<span>系</span><span>电</span><span>话</span></div>
                <div class="texter"><input type="number" @blur="trims" placeholder="请输入手机号码" v-model="submitMode.mobile"/></div>
            </div>

            <!-- <div class="unit ">
                <div class="labeler">身份证号码</div>
                <div class="texter"><input type="text" @blur="trims" placeholder="请输入身份证号码" /></div>
            </div>

            <div class="unit arrower">
                <div class="labeler">性别</div>
                <div class="texter" @click="sexPickEvent">男</div>
            </div>

            <div class="unit arrower">
                <div class="labeler">出生日期</div>
                <div class="texter" @click='timePickEvent'>2019-12-15</div>
            </div> -->

            

        </div>

        <div class="addTitle">选择身份信息</div>

        <div class="blocker">
            <div class="unit ">
                <div class="labeler w200">是否主账号</div>
                <div class="texter alignRight">
                    <van-switch v-model="submitMode.master" />
                </div>
            </div>
        </div>

        <div class="btnAreaBox">
            <div class="btnDiv"><button class="btn" @click="submit">保存</button></div>
        </div>

        <div class="goIndex" @click="$router.push('/index')"></div>

        <!-- <van-popup v-model="sexPickVis" position="bottom" class="popupBox">
            <div class="title"><span class="lefter">取消</span>设置性别<span class="righter">取消</span></div>
            <div class="content">
                <span class="active">男</span>
                <span class="right">女</span>
            </div>
        </van-popup> -->

        <!-- 选择日期 -->
        <van-popup position="bottom" v-model="timePickVis" :overlay="true" >
            <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate"  @cancel="timePickVis = false" @confirm="timeConfirm"/>
        </van-popup>


    </div>
</template>
<script>
export default {
    name: "accountBuild",
    data() {
        return {
            checked:true,
            sexPickVis:false,
            timePickVis:false,
            minDate: new Date(1999, 10, 1),
            currentDate: new Date(),
            maxDate: new Date(2049, 10, 1),
            submitMode:{
                "master": true,
                "mobile": "",
                "name": ""
            }
        };
    },
    mounted() {  
    },
    methods: {
        //添加租户
        submit(){
            if(this.submitMode.name === ''){
                this.$confirm({
                    mesg: "请输入姓名",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }else if(this.submitMode.mobile === ''){
                this.$confirm({
                    mesg: "请输入手机号",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }else if(!this.validMobile(this.submitMode.mobile)){
                this.$confirm({
                    mesg: "手机号格式不正确",
                    cancel:false,
                    btn: {
                        confirmVal: "确 定"
                    }
                })
                return false
            }
            this.load.$emit('loading', true)
            this.$store.dispatch('property/addTenant',this.submitMode).then((result) => {
                this.load.$emit('loading', false)
                if (result.returnCode == 0) {
                    this.$toast.success('添加成功')
                    this.$router.push('/changeAccount')
                } else {
                    this.$confirm({
                        mesg: result.returnMsg,
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                }
            }).catch(error => {
                    this.load.$emit('loading', false)
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
        },
        timePickEvent(){
            this.timePickVis = true;
        },
        sexPickEvent(){
            this.sexPickVis = true;
        },
        TimeChange(picker, value, index){
            console.log(value,'============',index)
        },
        timeConfirm(){
            
        }
    }
};
</script>
<style lang="scss" scoped>
#accountBuild{
    background-color: #fff;
    .blocker{
        .unit{
            .labeler{
                width: 115px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 26px;
                color:#333;
                span{
                    display: inline-block;
                    vertical-align: middle;
                }
                &.w200{
                    width: 200px;
                }
            }
            .texter{
                padding-left:17px;
            }
        }
    }
}
.addTitle{
    padding-top:50px;
    padding-left:22px;
    font-weight: bold;
    font-size: 30px;
    color:#333;
    line-height: 30px;
    padding-bottom: 16px;
}

</style>
