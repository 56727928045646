/*
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-13 18:49:21
 * @LastEditors: yql
 * @LastEditTime: 2020-04-29 13:52:58
 */

import { login,sendVerifyCode,isWechatClient } from '@/api/user'
import router,{ resetRouter } from '@/router'


const state = {
  token: ''

}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  }
}

const actions = {

  // remove token
  setToken({ commit },data) {
    console.log('data')
    return new Promise(resolve => {
      if(data){
        commit('SET_TOKEN', data)
        // commit('SET_TOKEN', window.localStorage.getItem('hodiToken'))
        // window.localStorage.setItem('hodiToken',data)
      }else{
        commit('SET_TOKEN', window.localStorage.getItem('hodiToken'))
      }
      resolve()
    })
  },
  
  //发送登录验证码
  sendVerifyCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendVerifyCode(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  //登录
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      login(data).then(response => {
        if (response.returnCode === 0) {
          window.localStorage.setItem('hodiToken', response.data)
          commit('SET_TOKEN', response.data)
        }
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  //微信授权
  isWechatClient({ commit }, data) {
    return new Promise((resolve, reject) => {
      isWechatClient(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },



  //登出
  logout({commit}) {
    return new Promise((resolve, reject) => {
      window.localStorage.removeItem('hodiToken')
      window.localStorage.removeItem('contract')
      window.localStorage.removeItem('prop')
      window.localStorage.removeItem('propName')
      window.localStorage.removeItem('roomName')
      commit('SET_TOKEN', '')
      
      router.push('/login')
      resetRouter()
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      window.localStorage.removeItem('hodiToken')
      commit('SET_TOKEN', '')
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

