var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "recharge", attrs: { id: "recharge" } }, [
      _c("div", { staticClass: "billContent" }, [
        _c("div", { staticClass: "billTop" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v("广东浩迪创新科技有限公司")
          ]),
          _c("div", { staticClass: "address" }, [
            _vm._v("浩迪总部-A3栋-研发部-E10")
          ])
        ]),
        _c("div", { staticClass: "rechargeCard" }, [
          _c("div", { staticClass: "rechargeTime" }, [
            _c("span", [_vm._v("充值时间：2020-04-25 18:25:25")])
          ]),
          _c("div", { staticClass: "text" }, [
            _c("span", { staticClass: "name" }, [_vm._v("表计表号")]),
            _c("span", [_vm._v("12132455666")])
          ]),
          _c("div", { staticClass: "text" }, [
            _c("span", { staticClass: "name" }, [_vm._v("支付金额")]),
            _c("span", { staticClass: "money" }, [_vm._v("￥12")])
          ]),
          _c("div", { staticClass: "text" }, [
            _c("span", { staticClass: "name" }, [_vm._v("支付状态")]),
            _c("span", [_vm._v("已收款")])
          ]),
          _c("div", { staticClass: "text" }, [
            _c("span", { staticClass: "name" }, [_vm._v("服务费")]),
            _c("span", { staticClass: "money" }, [_vm._v("￥6")])
          ]),
          _c("div", { staticClass: "text" }, [
            _c("span", { staticClass: "name" }, [_vm._v("支付方式")]),
            _c("span", [_vm._v("微信扫码")])
          ]),
          _c("div", { staticClass: "text" }, [
            _c("span", { staticClass: "name" }, [_vm._v("充值状态")]),
            _c("span", [_vm._v("已完成")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }