<!--
 * @Descripttion:
 * @version:
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2021-02-05 12:00:33
 -->
<template>
  <div class="charter" id="charter">
    <div class="noData" v-if="option2.length == 0">
      <img src="../../assets/images/noDataPic.png"/>
      <p>当前房间无电表</p>
    </div>
    <div class="charAreaBox" v-if="option2.length>0">
      <div class="chartShowBox" style="height: 50%;">
        <div class="chart-head">
          <div @click="showYearPicker = true"><strong>{{yearData}}年用电量 </strong>
            <img src="../../assets/images/triangle.svg" class="img-triangle">
          </div>
          <div>
            <div class="meterList" @click="showMeterListPicker = true">电表：{{value2}}
              <van-icon name="arrow-down"/>
            </div>
          </div>
        </div>
        <div class="chartShow" ref="chartShow" style="height: 85%;">
        </div>
      </div>
      <div class="chartShowBox" style="height: 50%;">
        <div class="chart-head">
          <div @click="showYearPicker = true"><strong>{{mothData}}月用电量 </strong>
            <img src="../../assets/images/triangle.svg" class="img-triangle">
          </div>
          <div>
              <div @click="$router.push(`chartReport?datetime=${now.getTime()}&meterAddr=${value2}`)"><span class="sub-title">电量报表</span>
                  <van-icon name="arrow" style="vertical-align: middle" />
              </div>
          </div>
        </div>
        <div class="chartShow" ref="chartShow2" style="height: 95%;"></div>
      </div>
    </div>
    <div class="goIndex" @click="$router.push('/index')"></div>
    <van-popup v-model="showYearPicker" round position="bottom">
      <van-datetime-picker v-model="now" type="year-month" @confirm="onYearConfirm" @cancel="showYearPicker = false"  :max-date="maxDate"/>
    </van-popup>
    <van-popup v-model="showMeterListPicker" round position="bottom">
      <van-picker title="选择电表" show-toolbar :columns="option2" @cancel="showMeterListPicker = false"
                  @confirm="onMeterConfirm"/>
    </van-popup>
  </div>
</template>
<script>
  import echarts from 'echarts'
  import 'vant/lib/icon/local.css'
  import m from 'moment'

  export default {
    name: 'charter',
    data() {
      return {
        yearData: new Date().getFullYear(),
        mothData: new Date().getMonth() + 1,
        value2: '',
        option2: [],
        echarts1: '',
        echarts2: '',
        now: new Date(),
        clickTime: '',
        unitIs: false,
        showYearPicker: false,
        showMeterListPicker: false,
        maxDate:m().toDate()
      }
    },
    mounted() {
      this.getMeterList()
    },
    created() {
      if (this.$route.query.meterAddr) {
        this.value2 = this.$route.query.meterAddr
      }
    },
    computed: {
      nowStr: function () {
        return this.timestampToTime(this.now)
      }
    },
    methods: {
      onYearConfirm(value) {
        let d = new Date(value)
        this.yearData = d.getFullYear()
        this.mothData = d.getMonth() + 1
        this.showYearPicker = false
        this.drawPie()
        this.drawPie2(this.nowStr)
      },
      onMeterConfirm(value) {
        this.value2 = value
        this.showMeterListPicker = false
        this.drawPie()
        this.drawPie2(this.nowStr)
      },

      //表计列表
      getMeterList() {
        this.$store
          .dispatch('meter/getMeterList', {})
          .then(result => {
            if (result.returnCode == 0) {
              if (result.data.electricMeters.length > 0) {
                if (!this.$route.query.meterAddr) {
                  this.value2 = result.data.electricMeters[0].meterAddr
                }
                for (let val of result.data.electricMeters) {
                  this.option2.push(val.meterAddr)
                }
                this.$nextTick(() => {
                  this.drawPie()
                  this.drawPie2(this.nowStr)
                })
              }
            } else {
              this.$confirm({
                mesg: result.returnMsg,
                cancel: false,
                btn: {
                  confirmVal: '确 定'
                }
              })
            }
          })
          .catch(error => {
            if (error.message.includes('timeout')) {
              this.$confirm({
                mesg: '数据请求超时',
                cancel: false,
                btn: {
                  confirmVal: '确 定'
                }
              })
            }
          })
      },
      timestampToTime(val) {
        var date = new Date(val)
        var Y = date.getFullYear() + '-'
        var M =
          (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) + '-'
        var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
        var mm =
          date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        var ss =
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        return Y + M + D + ' ' + hour + ':' + mm + ':' + ss
      },
      drawPie() {
        this.echarts1 = echarts.init(this.$refs.chartShow)
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        this.$store
          .dispatch('property/getYearData', {
            fid: this.value2,
            time: this.nowStr
          })
          .then(result => {
            this.$toast.clear()
            if (result.returnCode == 0) {
              if (result.data.currentList.length > 0) this.unitIs = true
              this.yearData = result.data.currentList[0].time.substring(0, 4)
              this.echarts1.setOption({
                color: '#078ee8',

                dataZoom: [
                  {
                    show: true,
                    realtime: true,
                    start: 0,
                    end: 60,
                    height: 8
                  },
                  {
                    type: 'inside',
                    realtime: true,
                    start: 0,
                    end: 60
                  }
                ],
                xAxis: {
                  type: 'category',
                  data: result.data.times
                },
                yAxis: {
                  type: 'value',
                  name: '单位:度',
                  axisLine: {
                    show: false
                  }
                },
                grid: {
                  top: '15%'
                },
                series: [
                  {
                    data: result.data.currentList,
                    type: 'bar',
                    itemStyle: {
                      normal: {
                        label: {
                          show: true, //开启显示
                          position: 'top', //在上方显示
                          textStyle: {
                            //数值样式
                            color: 'black',
                            fontSize: 8
                          }
                        }
                      }
                    }
                  }
                ]
              })
            } else {
              this.$confirm({
                mesg: result.returnMsg,
                cancel: false,
                btn: {
                  confirmVal: '确 定'
                }
              })
            }
          })
          .catch(error => {
            this.$toast.clear()
            if (error.message.includes('timeout')) {
              this.$confirm({
                mesg: '数据请求超时',
                cancel: false,
                btn: {
                  confirmVal: '确 定'
                }
              })
            }
          })
        var that = this
        this.echarts1.on('click', function (params) {
          that.clickTime = params.data.time
          setTimeout(function () {
            that.drawPie2(params.data.time)
          }, 100)
        })
      },
      drawPie2(param) {
        this.echarts2 = echarts.init(this.$refs.chartShow2)
        this.$store
          .dispatch('property/getMonthData', {fid: this.value2, time: param})
          .then(result => {
            this.mothData = new Date(param.replace(/-/g, '/')).getMonth() + 1
            this.echarts2.setOption({
              color: '#078ee8',
              dataZoom: [
                {
                  show: true,
                  realtime: true,
                  start: 0,
                  end: 60,
                  height: 8
                },
                {
                  type: 'inside',
                  realtime: true,
                  start: 0,
                  end: 60
                }
              ],
              grid: {
                top: '15%'
              },
              xAxis: {
                type: 'category',
                data: result.data.times
              },
              yAxis: {
                type: 'value',
                name: '单位:度',
                axisLine: {
                  show: false
                }
              },
              series: [
                {
                  data: result.data.currentList,
                  type: 'bar',
                  itemStyle: {
                    normal: {
                      label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: {
                          //数值样式
                          color: 'black',
                          fontSize: 8
                        }
                      }
                    }
                  }
                }
              ]
            })
          })
          .catch(error => {
            if (error.message.includes('timeout')) {
              this.$confirm({
                mesg: '数据请求超时',
                cancel: false,
                btn: {
                  confirmVal: '确 定'
                }
              })
            }
          })
      },
      meterChange(val) {
        this.unitIs = false
        this.drawPie()
        this.drawPie2(this.now)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .chart-head {
    display: flex;
    justify-content: space-between;
    font-size: 32px;
  }

  .meterList {
    border: 4px solid #078ee8;
    border-radius: 20px;
    padding: 10px 20px;
    color: #0893ea;
    font-size: 24px;
  }

  .sub-title {
    font-size: 24px;
  }

  .img-triangle {
    width: 16px;
    height: 16px;
  }
</style>

