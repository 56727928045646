/*
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-13 18:55:27
 * @LastEditors: yql
 * @LastEditTime: 2020-05-08 12:12:53
 */

import axios from 'axios'
import store from '@/store'
import router from '@/router'
// import loading from '@/components/loading/loading'
import message from '@/components/message/message'
import { Loading } from 'vant';
// const loading= {
//   loadingInstance:null,
//   open:function(){
//     if(this.loadingInstance === null){
//       this.loadingInstance = Loading.service({
//         text:'拼命加载中···',
//         background:'#fff'
//       })
//     }
//   },
//   close:function(){
//     if(this.loadingInstance !== null){
//       this.loadingInstance.close()
//     }
//     this.loadingInstance = null
//   }
  
// }


// create an axios instance
const service = axios.create({
  timeout: 30000 // request timeout
})

const hasToken = window.localStorage.getItem('hodiToken')
const hasProp = window.localStorage.getItem('prop')
const hasContract = window.localStorage.getItem('contract')

// request interceptor
service.interceptors.request.use(
  config => {
    if (hasToken || window.localStorage.getItem('hodiToken') !== null) {
      config.headers['token'] = window.localStorage.getItem('hodiToken');
      
      if(store.getters.contract || hasContract){
        config.headers['contract'] = window.localStorage.getItem('contract');
      }
      config.headers['Content-Type'] = 'application/json';
    }
    if(store.getters.prop || hasProp){
      config.headers['prop'] = window.localStorage.getItem('prop');
    }
    config.baseURL = process.env.VUE_APP_URL
    // loading.open()
    return config
  },
  error => {
    console.log(error)
    
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.returnCode == -2) {
      store.dispatch('user/logout')
      store.dispatch('property/removeProp')
      store.dispatch('property/removeContract')
      return res
    } else {
      return res
    }
  },
  error => {
    // console.log('err==' + error) // for debug
    // let errors = error
    // console.log('errors---'+errors)
    // console.log(error)
    // if(errors.indexOf('timeout')>-1){
    //   console.log(message)
    // }
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
