<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-04 17:27:36
 * @LastEditTime: 2020-02-28 21:54:42
 -->
<template>
    <div class="verifyCode" id="billDetail">
        <div class="billInfos">
            <div class="iconImg tan" :class="{'dian':dataMode.billKind == 1,'shui':dataMode.billKind == 2,'shui':dataMode.billKind == 2,'zu':dataMode.billKind == 3,'guan':dataMode.billKind == 4,'tan':dataMode.billKind == 5,'tiao':dataMode.billKind == 98,'zhang':dataMode.billKind == 99}"></div>
                <div class="text">
                    <h4>{{billKindEnum[dataMode.billKind]}}</h4>
                    <p>￥{{dataMode.actualAmount | formatMoneyThousand}}</p>
                </div>
                <div class="opeart">
                    <span @click="paySubmiter(dataMode)">支付</span>
                    <span @click="detaiEvent"><b v-if="billDetaiVis">收起</b><b v-else>明细</b> </span>
                </div>
            </div> 
            <!-- 明细 -->
            <div class="detailBox" v-if="billDetaiVis">
                <div class="detailUnit">
                    <div class="label">账单编号</div>
                    <div class="text">{{dataMode.billNo}}</div>
                </div>
                <div class="detailUnit">
                    <div class="label">账单类型</div>
                    <div class="text">{{billKindEnum[dataMode.billKind]}}</div>
                </div>
                <div class="detailUnit">
                    <div class="label">缴费状态</div>
                    <div class="text " v-if="dataMode.payStatus === 0"></div>
                    <div class="text " v-else-if="dataMode.payStatus === 1">无需收款</div>
                    <div class="text yellow" v-else-if="dataMode.payStatus === 2">待支付</div>
                    <div class="text yellow" v-else-if="dataMode.payStatus === 3">待退款</div>
                    <div class="text green" v-else-if="dataMode.payStatus === 4">已支付</div>
                    <div class="text green" v-else-if="dataMode.payStatus === 5">已退款</div>

                </div>
                <div class="detailUnit">
                    <div class="label">逾期状态</div>
                    <div class="text red" v-if="dataMode.deadline">已逾期</div>
                    <div class="text green" v-else>未逾期</div>
                </div>
                <div class="detailUnit">
                    <div class="label">表计表号</div>
                    <div class="text">{{dataMode.meterAddr}}</div>
                </div>
                <div class="detailUnit">
                    <div class="label">结算月份</div>
                    <div class="text">{{dataMode.period}}</div>
                </div>
                <div class="detailUnit">
                    <div class="label">结算周期</div>
                    <div class="text">{{dataMode.startDate}}至{{dataMode.endDate}}</div>
                </div>


                <div v-if="dataMode.billKind == 1">
                    <div class="detailUnit">
                        <div class="label">期初行度</div>
                        <div class="text">{{dataMode.startValue}}</div>
                    </div>
                    <div class="detailUnit">
                        <div class="label">期末行度</div>
                        <div class="text">{{dataMode.endValue}}</div>
                    </div>
                    <div class="detailUnit">
                        <div class="label">用电量</div>
                        <div class="text">{{dataMode.useValue}}</div>
                    </div>
                    <div class="detailUnit">
                        <div class="label">电费</div>
                        <div class="text">￥{{dataMode.amount | formatMoneyThousand}}</div>
                    </div>
                    
                </div>
                <div v-else-if="dataMode.billKind == 2">
                    <div class="detailUnit">
                        <div class="label">期初行度</div>
                        <div class="text">{{dataMode.startValue}}</div>
                    </div>
                    <div class="detailUnit">
                        <div class="label">期末行度</div>
                        <div class="text">{{dataMode.endValue}}</div>
                    </div>
                    <div class="detailUnit">
                        <div class="label">用水量</div>
                        <div class="text">{{dataMode.useValue}}</div>
                    </div>
                    <div class="detailUnit">
                        <div class="label">水费</div>
                        <div class="text">￥{{dataMode.amount | formatMoneyThousand}}</div>
                    </div>
                </div>
                <div v-else-if="dataMode.billKind == 3">
                    <div class="detailUnit">
                        <div class="label">租金金额</div>
                        <div class="text">￥{{dataMode.amount | formatMoneyThousand}}</div>
                    </div>
                </div>
                <div v-else-if="dataMode.billKind == 4">
                    <div class="detailUnit">
                        <div class="label">管理费</div>
                        <div class="text">￥{{dataMode.amount | formatMoneyThousand}}</div>
                    </div>
                </div>
                <div v-else-if="dataMode.billKind == 5">
                    <div class="detailUnit">
                        <div class="label">账单金额</div>
                        <div class="text">￥{{dataMode.amount | formatMoneyThousand}}</div>
                    </div>
                    <div class="detailUnit">
                        <div class="label">备注</div>
                        <div class="text">{{dataMode.remark}}</div>
                    </div>
                </div>
                <div v-else-if="dataMode.billKind == 98">

                    <div class="detailUnit">
                        <div class="label">原账单编号</div>
                        <div class="text">{{dataMode.originalBillNo}}</div>
                    </div>
                    <div class="detailUnit">
                        <div class="label">原账单类型</div>
                        <div class="text">{{billKindEnum[dataMode.originalBillKind]}}</div>
                    </div>
                    
                </div>
                <div v-else>
                    <div class="detailUnit">
                        <div class="label">账单金额</div>
                        <div class="text">￥{{dataMode.amount | formatMoneyThousand}}</div>
                    </div>
                    <div class="detailUnit">
                        <div class="label">备注</div>
                        <div class="text">{{dataMode.remark}}</div>
                    </div>
                </div>

                <div class="detailUnit">
                    <div class="label">调整金额</div>
                    <div class="text">￥{{dataMode.adjustAmount| formatMoneyThousand}}</div>
                </div>
                
                <div class="detailUnit">
                    <div class="label">调整原因</div>
                    <div class="text">{{dataMode.adjustRemark}}</div>
                </div>
                <div class="detailUnit" v-if="dataMode.billKind == 1 || dataMode.billKind == 2">
                    <div class="label">安装地址</div>
                    <div class="text">{{dataMode | installAdressFormat2}}</div>
                </div>


                <div class="detailUnit" v-if="dataMode.billKind == 1">
                    <div class="label">应付电费</div>
                    <div class="text bold red">￥{{dataMode.actualAmount| formatMoneyThousand}}</div>
                </div>
                <div class="detailUnit" v-else-if="dataMode.billKind == 2">
                    <div class="label">应付水费</div>
                    <div class="text bold red">￥{{dataMode.actualAmount| formatMoneyThousand}}</div>
                </div>
                <div class="detailUnit" v-else>
                    <div class="label">应付金额</div>
                    <div class="text bold red">￥{{dataMode.actualAmount| formatMoneyThousand}}</div>
                </div>


            </div>
    </div>
</template>

<script>

export default {
    name: "billDetail",
    components: {},
    data(){
        return{
            billDetaiVis:false,
            billKindEnum:null,
            originalBillKindEnum:null,
            payStatusEnum:null
        }
    },
    props:{
        dataMode:Object
    },
    watch:{
    },
    mounted(){
    },
    created(){
        this.billKindEnum = this.billKindEnums()
        this.originalBillKindEnum = this.originalBillKindEnums()
        this.payStatusEnum = this.payStatusEnums()
    },
    methods:{
        detaiEvent(){
            this.billDetaiVis = !!!this.billDetaiVis
        },
        paySubmiter(value){
            console.log(value)
            if(value.merFid){
                this.$emit('paySubmiter',value)              
            }else{
                //console.log('线下支付')
                this.$router.push({ path: '/offlinePay', query: { value: value } })
            }
            
        }
    }
}
</script>
