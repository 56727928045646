<!--
 * @Descripttion: 
 * @version: 
 * @Author: zengfq
 * @Date: 2019-10-29 11:19:49
 * @LastEditors: yql
 * @LastEditTime: 2020-04-29 08:52:32
 -->
<template>
    <div class="mask" v-show="loading" id="loading">
        <div class="box">
            <img src="../../assets/images/loading2.gif" />
            <div v-html="infos" class="infosBox"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "loading",
    data() {
        return {
            loading: false,
            infos: ""
        };
    },
    created() {
        var that = this
        this.load.$on("loading", function(data, info) {
            that.loading = !!data;
            if (info) {
                that.infos = info;
            }
        });
    }
};
</script>
<style lang="scss" scoped>
.mask {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    position: fixed!important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2500;
    background-color: rgba(0, 0, 0, 0.1);
    .box{
      text-align: center;
    }
    img {
        width: 80px;
    }
    .infosBox {
        font-size: 34px;
        color: #fff;
        line-height: 60px;
    }
}
</style>