var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "changePhone", attrs: { id: "changePhone" } },
    [
      _c("div", { staticClass: "formBox" }, [
        _c("div", { staticClass: "formUnit" }, [
          _c("div", { staticClass: "label" }, [_vm._v("手机号码")]),
          _c("div", { staticClass: "inputBox" }, [
            _c("div", { staticClass: "inputer" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.submitMode.mobile,
                    expression: "submitMode.mobile"
                  }
                ],
                attrs: { type: "number", placeholder: "请输入手机号码" },
                domProps: { value: _vm.submitMode.mobile },
                on: {
                  blur: _vm.trims,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.submitMode, "mobile", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "coder" }, [
              !_vm.codeSecondVis
                ? _c(
                    "button",
                    {
                      attrs: { disabled: _vm.codeBtnVis },
                      on: { click: _vm.getCode }
                    },
                    [_vm._v("获取验证码")]
                  )
                : _c("button", { attrs: { disabled: "" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.seconds) + "秒后重发")])
                  ])
            ])
          ])
        ]),
        _c("div", { staticClass: "formUnit" }, [
          _c("div", { staticClass: "label" }, [_vm._v("短信验证码")]),
          _c("div", { staticClass: "inputBox" }, [
            _c("div", { staticClass: "inputer " }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.submitMode.verifyCode,
                    expression: "submitMode.verifyCode"
                  }
                ],
                staticClass: "gray",
                attrs: { type: "number", placeholder: "请输入短信验证码" },
                domProps: { value: _vm.submitMode.verifyCode },
                on: {
                  blur: _vm.trims,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.submitMode, "verifyCode", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "btnAreaBox" }, [
          _c("div", { staticClass: "btnDiv" }, [
            _c(
              "button",
              { staticClass: "btn", on: { click: _vm.bindPhoneEvent } },
              [_vm._v("绑定")]
            )
          ])
        ])
      ]),
      _c("view-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }