<!--
 * @Descripttion: 
 * @version: 
 * @Author: zengq
 * @Date: 2020-02-20 11:17:24
 * @LastEditors: zengfq
 * @LastEditTime: 2020-02-29 10:21:03
 -->
<template>
    <div class="accountBox">
        <div class="offline-title">
            <div class="top">此账单暂不支持线上支付</div>
            <div class="bottom">物业已设置此账单不能通过线上支付。您可以通过物业提供的支付方式，支付完成后在这里登记支付信息。物业核实后即完成确认支付账单。</div>
        </div>
        <div class="block">
            <div class="unit" @click="channelTypeEvent">支付方式<span class="right">{{channelTypeName}}</span></div>
            <div class="unit">流水号后4位<span class="right"><input type="text" style="text-align: right;"
                        value="" v-model="subMode.remark"/></span></div>
            <div class="unit">支付金额<span class="right">{{billRow.amount}}</span></div>
        </div>
        <div style="margin-top:98px">
            <button class="btn" @click="offlinePayEvent">提交</button>
        </div>

        <van-popup v-model="channelTypeVis" position="bottom" class="popupBox">
            <div class="title"><span class="lefter" @click="channelTypeCancle">取消</span>选择支付方式 <span class="righter"
                    @click="channelTypePickSure">确定</span></div>
            <div class="content">
                <span v-for="(item,index) in channelTypeSel" :class="{'active':item.id === channelChecked}" :key="index"
                    @click="channelTypePick(item)">{{item.label}}</span>
            </div>
        </van-popup>
    </div>
</template>
<script>
    export default {
        name: "offlinePay",
        data() {
            return {
                billRow: '',
                channelTypeSel: [],
                channelTypeVis: false,
                channelChecked: null,
                channelTypeName:'',
                subMode: {
                    billId: '',
                    channelType: '',
                    remark: ''
                },
                channelType:''

            };
        },
        mounted() {

        },
        created() {
            this.billRow = this.$route.query.value
            this.channelTypeSel = this.channelTypeList()
            this.channelType  = this.channelTypeEmnus()
            this.getOfflinePayInfo()
        },
        methods: {
            channelTypeEvent() {
                this.channelTypeVis = true
            },
            channelTypePick(item) {
                if (item) {
                    this.channelChecked = item.id
                    this.channelTypeName = item.label
                } else {
                    this.channelChecked = null
                }
            },
            channelTypeCancle() {
                this.channelTypeVis = false
            },
            channelTypePickSure() {
                this.subMode.channelType = this.channelChecked             
                this.channelTypeVis = false
            },
            //提交
            offlinePayEvent(){
                this.subMode.billId = this.billRow.fid
                this.subMode.amount = this.billRow.amount
                if(this.subMode.channelType=='' || this.subMode.channelType==null){
                    this.$confirm({
                        mesg: "请选择支付方式",
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                    return false
                }
                if(this.subMode.remark=='' || this.subMode.remark==null){
                    this.$confirm({
                        mesg: "请输入流水号",
                        cancel:false,
                        btn: {
                            confirmVal: "确 定"
                        }
                    })
                    return false 
                }

                this.$store.dispatch('property/offlinePay', this.subMode).then((result) => {
                    if (result.returnCode == 0) {
                        this.$toast.success('提交成功')
                        this.$router.push("/recharge")
                    }else{
                        this.$confirm({
                            mesg: result.returnMsg,
                            cancel:false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                })
            },
            getOfflinePayInfo(){             
                this.$store.dispatch('property/offlinePayInfo', {fid:this.billRow.fid}).then((result) => {
                    if(result.returnCode == 0){
                        this.subMode = result.data
                        this.channelTypeName = this.channelType[result.data.channelType]
                    }
                })
            }

        },

    };
</script>
<style>
    .offline-title {
        background-color: #f5f5f5;
        padding: 51px 30px;
    }

    .offline-title .top {
        font-size: 34px;
        color: #333;
        font-weight: bold;
        margin-bottom: 22px;
    }

    .offline-title .bottom {
        font-size: 24px;
        color: #666;
    }
</style>