import request from '@/utils/request'

export function getMeterList(data) {
  return request({
    url: '/gateway/prop/h5/terminal/getMeterList',
    timeout:5*60*1000,
    method: 'post',
    data
  })
}
export function addElectric(data) {
  return request({
    url: '/gateway/prop/h5/terminal/addElectric',
    method: 'post',
    data
  })
}
export function deleteElectric(data) {
  return request({
    url: '/gateway/prop/h5/terminal/deleteElectric',
    method: 'post',
    data
  })
}
export function refreshElectric(data) {
  return request({
    url: '/gateway/prop/h5/terminal/refreshElectric',
    timeout:5*60*1000,
    method: 'post',
    data
  })
}
export function getWxJsapi(data) {
  return request({
    url: '/gateway/prop/h5/terminal/getWxJsapi',
    method: 'post',
    data
  })
}
