/*
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-13 18:52:08
 * @LastEditors: yql
 * @LastEditTime: 2020-04-28 15:48:44
 */

const getters = {
  token: state => state.user.token,
  prop: state => state.property.prop,
  contract: state => state.property.contract,
  propertyName: state => state.property.propertyName,
  roomName: state => state.property.roomName
}
export default getters
