var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderRecord", attrs: { id: "orderRecord" } },
    [
      _c(
        "div",
        { staticClass: "listBox" },
        [
          _vm._l(_vm.list, function(item, index) {
            return _c("div", { key: index, staticClass: "listUnit" }, [
              _c("div", { staticClass: "unitName" }, [
                _c("div", { staticClass: "namer" }, [
                  _vm._v(_vm._s(item.name)),
                  _vm._m(0, true)
                ]),
                _c("div", { staticClass: "price" }, [
                  _c("span", [_vm._v("￥")]),
                  _vm._v(_vm._s(item.amount))
                ])
              ]),
              _c("div", { staticClass: "uniter" }, [
                _c("span", [_vm._v("交易时间：")]),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(item.payTime))
                ])
              ]),
              _c("div", { staticClass: "uniter" }, [
                _c("span", [_vm._v("购买时长：")]),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(item.time) + "个月")
                ])
              ]),
              _c("div", { staticClass: "uniter" }, [
                _c("span", [_vm._v("有效时间：")]),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(item.startTime) + "-" + _vm._s(item.endTime))
                ])
              ]),
              _c("div", { staticClass: "uniter" }, [
                _c("span", [_vm._v("支付单号：")]),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(item.orderNo))
                ])
              ])
            ])
          }),
          _vm.list.length == 0
            ? _c("div", { staticClass: "noData" }, [
                _c("img", {
                  attrs: { src: require("../../assets/images/noDataPic.png") }
                }),
                _c("p", [_vm._v("暂无数据")])
              ])
            : _vm._e()
        ],
        2
      ),
      _c("div", {
        staticClass: "goIndex",
        on: {
          click: function($event) {
            return _vm.$router.push("/index")
          }
        }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("sup", [
      _c("img", {
        attrs: { src: require("../../assets/images/icon_yesM.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }