import request from '@/utils/request'

export function pay(data) {
  return request({
    url: 'ms/cms/pay',
    method: 'post',
    data
  })
}

export function sendCmcbPayOrder(data) {
  return request({
    url: '/ms/sendCmcbPayOrder',
    method: 'get',
    params:data
  })
}

export function generateAllinpayOrder(data) {
  return request({
    url: '/allinpay/generateAllinpayOrder',
    method: 'post',
    hearts: {
      'Content-type':"application/x-www-form-urlencoded"
    },
    data
  })
}

export function payO(data) {
  return request({
    url: '/gs/test/payO',
    method: 'get',
    params:data
  })
}


export function tgPosp(data) {
  return request({
    url: 'http://61.142.204.26:10086/h5/tgPosp',
    method: 'get',
    params:data
  })
}
