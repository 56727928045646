<!--
 * @Descripttion: 
 * @version: 
 * @Author: yql
 * @Date: 2019-12-11 13:48:14
 * @LastEditors: yql
 * @LastEditTime: 2020-05-18 10:01:04
 -->
<template>
    <div class="charter" id="charter">


        <div class="searchArea">
            <div class="searchBox">

                <div class="label">选择水表</div>
                <!-- <span class="right"> -->
                <div class="dropdownMenu">
                    <van-dropdown-menu>
                        <van-dropdown-item v-model="value2" :options="option2" @change="meterChange"/>
                    </van-dropdown-menu>
                </div>
                <!-- </span> -->
            </div>

        </div>

        <div class="charAreaBox">
            <div class="chartShowBox">
                <div class="uniterBox" v-if="option2.length>0 && unitIs">单位:吨</div>
                <div class="chartShow" ref="chartShow" style="height: 300px;"></div>
            </div>
            <div class="chartShowBox">
                <div class="uniterBox" v-if="option2.length>0 && unitIs">单位:吨</div>
                <div class="chartShow" ref="chartShow2" style="height: 300px;"></div>
            </div>
        </div>

        <div class="goIndex" @click="$router.push('/index')"></div>

    </div>
</template>
<script>
    import echarts from 'echarts'
    export default {
        name: "charter",
        data() {
            return {
                value2: '',
                option2: [],
                echarts1: '',
                echarts2: '',
                now: '',
                clickTime: '',
                unitIs:false
            };
        },
        mounted() {
            this.now = this.timestampToTime(this.now)
            this.value2 = this.$route.query.meterAddr
            this.$nextTick(()=>{
                this.drawPie()         
                this.drawPie2(this.now)
            })
            
        },
        created() {
            this.now = new Date()
            this.getMeterList()
        },
        methods: {
            //表计列表
            getMeterList(){
                this.$store.dispatch('meter/getMeterList', {}).then((result) => {
                    if(result.returnCode == 0){
                        // this.value2 = result.data.waterMeters[0].meterAddr                      
                        for(let val of result.data.waterMeters){
                            let obj = {
                                text:'',
                                value:''
                            }
                            obj.text = val.meterAddr
                            obj.value = val.meterAddr
                            this.option2.push(obj)
                        }
                        
                    }
                }).catch(error => {
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
            },
            timestampToTime(val) {
                var date = new Date(val)
                var Y = date.getFullYear() + '-'
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
                var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
                var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
                var mm = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
                var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
                return Y + M + D + ' ' + hour + ':' + mm + ':' + ss
            },
            drawPie() {
                this.echarts1 = echarts.init(this.$refs.chartShow)
                this.load.$emit('loading', true)
                this.$store.dispatch('meter/getYearData', { fid: this.value2, time: this.now }).then((result) => {
                    this.load.$emit('loading', false)
                    if(result.returnCode == 0){
                        if(result.data.currentList.length>0)this.unitIs = true
                        this.echarts1.setOption({
                            title: {
                                text: result.data.currentList[0].time.substring(0,4)+'年用水量'
                            },
                            color:'#078ee8',
                            dataZoom: [
                                {
                                    show: true,
                                    realtime: true,
                                    start: 0,
                                    end: 60,
                                    height: 8,
                                },
                                {
                                    type: 'inside',
                                    realtime: true,
                                    start: 0,
                                    end: 60
                                }
                            ],
                            xAxis: {
                                type: 'category',
                                data: result.data.times
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: [{
                                data: result.data.currentList,
                                type: 'bar',
                                itemStyle: {
                                    normal: {
                                        label: {
                                            show: true, //开启显示
                                            position: 'top', //在上方显示
                                            textStyle: { //数值样式
                                                color: 'black',
                                                fontSize: 8
                                            }
                                        }
                                    }
                                },
                            }]
                        })
                    }else{
                        this.$confirm({
                            mesg: result.returnMsg,
                            cancel:false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                }).catch(error => {
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });
                var that = this
                this.echarts1.on('click', function (params) {
                    that.clickTime = params.data.time
                    setTimeout(function () {
                        that.drawPie2(params.data.time)
                    }, 100)


                });

            },
            drawPie2(param) {
                this.echarts2 = echarts.init(this.$refs.chartShow2)
                this.load.$emit('loading', true)
                this.$store.dispatch('meter/getMonthData', { fid: this.value2, time: param }).then((result) => {
                    this.load.$emit('loading', false)
                    if(result.returnCode == 0){
                        this.echarts2.setOption({
                            title: {
                                text: param.substring(5,7)+'月用水量'
                            },
                            color:'#078ee8',
                            dataZoom: [
                                {
                                    show: true,
                                    realtime: true,
                                    start: 0,
                                    end: 60,
                                    height: 8
                                },
                                {
                                    type: 'inside',
                                    realtime: true,
                                    start: 0,
                                    end: 60
                                }
                            ],
                            xAxis: {
                                type: 'category',
                                data: result.data.times
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: [{
                                data: result.data.currentList,
                                type: 'bar',
                                itemStyle: {
                                    normal: {
                                        label: {
                                            show: true, //开启显示
                                            position: 'top', //在上方显示
                                            textStyle: { //数值样式
                                                color: 'black',
                                                fontSize: 8
                                            }
                                        }
                                    }
                                },
                            }]
                        })
                    }else{
                        this.$confirm({
                            mesg: result.returnMsg,
                            cancel:false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                }).catch(error => {
                    if(error.message.includes('timeout')){
                        this.$confirm({
                            mesg: '数据请求超时',
                            cancel: false,
                            btn: {
                                confirmVal: "确 定"
                            }
                        })
                    }
                });

            },
            meterChange(val){
                this.unitIs = false
                this.drawPie()         
                this.drawPie2(this.now)
            }
        }
    };
</script>