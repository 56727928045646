var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "changePhone", attrs: { id: "changeEmail" } },
    [
      _c("div", { staticClass: "formBox" }, [
        _c("div", { staticClass: "formUnit" }, [
          _c("div", { staticClass: "label" }, [_vm._v("电子邮件")]),
          _c("div", { staticClass: "inputBox" }, [
            _c("div", { staticClass: "inputer " }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.submitMode.email,
                    expression: "submitMode.email"
                  }
                ],
                staticClass: "gray",
                attrs: {
                  type: "text",
                  placeholder: "请输入电子邮件地址",
                  value: ""
                },
                domProps: { value: _vm.submitMode.email },
                on: {
                  blur: _vm.trims,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.submitMode, "email", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "btnAreaBox" }, [
          _c("div", { staticClass: "btnDiv" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    return _vm.submitEvent()
                  }
                }
              },
              [_vm._v("保存")]
            )
          ])
        ])
      ]),
      _c("view-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }