var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarmer", attrs: { id: "alarm" } }, [
    _c("div", { staticClass: "result" }, [
      _vm._m(0),
      _vm.currentAlarmList.length > 0
        ? _c(
            "div",
            { staticClass: "boxers" },
            _vm._l(_vm.currentAlarmList, function(item, index) {
              return _c("div", { key: index, staticClass: "resultUnit" }, [
                _c(
                  "h3",
                  {
                    staticClass: "title",
                    class: { green: item.relieve, red: !item.relieve }
                  },
                  [_c("span", [_vm._v(_vm._s(item.alarmName))])]
                ),
                _c("div", { staticClass: "texter" }, [
                  _c("div", { staticClass: "info" }, [
                    _c("p", [
                      _vm._v("产生原因："),
                      _c("span", [_vm._v(_vm._s(item.happenContent))])
                    ])
                  ]),
                  _c("div", { staticClass: "textShow" }, [
                    _c("div", { staticClass: "detailUnit" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("表计表号")]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(item.meterAddr))
                      ])
                    ]),
                    _c("div", { staticClass: "detailUnit" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("告警时间")]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(item.happenTime))
                      ])
                    ]),
                    item.relieve
                      ? _c("div", { staticClass: "detailUnit" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("解除时间")
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(item.relieveTime))
                          ])
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            }),
            0
          )
        : _c("div", { staticClass: "noMeter" }, [
            _vm._v(" 当前房间电表运行正常，无告警信息! ")
          ]),
      _vm._m(1),
      _vm.alarmLists.length > 0
        ? _c(
            "div",
            { staticClass: "boxers" },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.alarmLists, function(item, index) {
                  return _c("div", { key: index, staticClass: "resultUnit" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "title",
                        class: { green: item.relieve, red: !item.relieve }
                      },
                      [_c("span", [_vm._v(_vm._s(item.alarmName))])]
                    ),
                    _c("div", { staticClass: "texter" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("p", [
                          _vm._v("产生原因："),
                          _c("span", [_vm._v(_vm._s(item.happenContent))])
                        ]),
                        _c("p", [
                          _vm._v("解除原因："),
                          _c("span", [_vm._v(_vm._s(item.relieveContent))])
                        ])
                      ]),
                      _c("div", { staticClass: "textShow" }, [
                        _c("div", { staticClass: "detailUnit" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("表计表号")
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(item.meterAddr))
                          ])
                        ]),
                        _c("div", { staticClass: "detailUnit" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("告警时间")
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(item.happenTime))
                          ])
                        ]),
                        item.relieve
                          ? _c("div", { staticClass: "detailUnit" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("解除时间")
                              ]),
                              _c("div", { staticClass: "text" }, [
                                _vm._v(_vm._s(item.relieveTime))
                              ])
                            ])
                          : _vm._e()
                      ])
                    ])
                  ])
                }),
                0
              )
            ],
            1
          )
        : _c("div", { staticClass: "noData" }, [
            _c("img", {
              attrs: { src: require("../../assets/images/noDataPic.png") }
            }),
            _c("p", [_vm._v("无历史告警记录")])
          ])
    ]),
    _c("div", {
      staticClass: "goIndex",
      on: {
        click: function($event) {
          return _vm.$router.push("/index")
        }
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleA white" }, [
      _c("span", [_vm._v("当前告警")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleA top" }, [
      _c("span", [_vm._v("历史告警")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }