var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "agreementBox", attrs: { id: "agreementBox" } },
      [
        _c("h2", [_vm._v("用户服务协议")]),
        _vm._v(
          " 欢迎你使用广东浩迪科技创新有限公司的短信通知套餐服务！ 为使用广东浩迪科技创新有限公司（以下简称“本公司”）服务，用户应当阅读并遵守《用户服务协议》（以下简称“本协议”）。请你务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。限制、免责条款可能以下划线并加粗形式提示你注意。 除非你已阅读并接受本协议所有条款，否则你无权下载、安装或使用本软件及相关服务。你的下载、安装、使用、登录等行为即视为你已阅读并同意接受本协议的约束，对你具有法律约束力。"
        ),
        _c("br"),
        _vm._v(" 一、协议的范围"),
        _c("br"),
        _vm._v(" 1.1 协议适用主体范围"),
        _c("br"),
        _vm._v(
          " 本协议是用户与本公司之间关于用户下载、安装、使用、复制本软件，以及使用广东浩迪科技创新有限公司短信套餐服务的协议。"
        ),
        _c("br"),
        _vm._v(" 1.2 协议关系及冲突条款"),
        _c("br"),
        _vm._v(
          " 本协议内容同时包括本公司可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，用户同样应当遵守。"
        ),
        _c("br"),
        _vm._v(
          " 1.3 本协议内容及本公司已经发布的或将来可能发布/更新的各类规则是不可分割的整体，具有同等法律效力。本协议可由本公司随时更新并通知用户。在本公司更新本协议条款后，如果你不接受更新后的条款，请立即停止使用本公司提供的本软件和接受相关服务。如你继续使用本公司提供的本软件和接受相关服务，将被视为已接受更新后的本协议条款。"
        ),
        _c("br"),
        _vm._v(" 二、关于本服务"),
        _c("br"),
        _vm._v(" 2.1 本服务的内容"),
        _c("br"),
        _vm._v(
          " 2.1.1本服务的具体内容由本公司根据实际情况提供。本公司可以对提供的服务予以变更，且本公司提供的服务内容可能随时变更，用户将会收到本公司关于服务变更的通知。"
        ),
        _c("br"),
        _vm._v(
          " 2.1.2 本公司提供的服务包含免费服务与收费服务。您可以通过付费方式购买收费服务，具体方式为：通过微信或其他本公司提供的付费途径支付一定数额的人民币，从而获得收费服务使用权限。对于收费服务，本公司会在您使用之前给予明确的提示，只有根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成确认支付已完成的确认通知为准。"
        ),
        _c("br"),
        _vm._v(
          " 2.1.3 您在享受收费服务的有效期内可以进行付费升级。本公司会在您使用之前给予明确的提示，只有根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，才能完成升级并享受更高级的收费服务。关于您在有效期内付费升级的收费和此前剩余权益的转换说明如下："
        ),
        _c("br"),
        _vm._v(" 三、用户个人信息保护"),
        _c("br"),
        _vm._v(
          " 3.1 保护用户个人信息是本公司的一项基本原则，本公司将会采取合理的措施保护用户的个人信息。除法律法规规定的情形外，未经用户许可本公司不会向第三方公开、透露用户个人信息。本公司对相关信息采用专业加密存储与传输方式，保障用户个人信息的安全。"
        ),
        _c("br"),
        _vm._v(
          " 3.2 用户在注册账号或使用本服务的过程中，可能需要搜集能识别用户身份的个人信息以便可以在必要时联系用户，或为用户提供更好的使用体验。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。"
        ),
        _c("br"),
        _vm._v(
          " 3.3 一般情况下，你可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，你可能无法修改注册时提供的初始注册信息及其他验证信息。"
        ),
        _c("br"),
        _vm._v(
          " 3.4 本公司将运用各种安全技术和程序建立完善的管理制度来保护你的个人信息，以免遭受未经授权的访问、使用或披露。"
        ),
        _c("br"),
        _vm._v(
          " 3.5 未经你的同意，本公司不会向本公司以外的任何公司、组织和个人披露你的个人信息，但以下特定情形除外："
        ),
        _c("br"),
        _vm._v(
          " 3.5.1 本公司根据法律法规规定或行政、司法等有权机关的指示提供用户的个人隐私信息；"
        ),
        _c("br"),
        _vm._v(
          " 3.5.2 由于用户将其用户密码告知他人或与他人共享注册账户与密码，由此导致的任何个人信息的泄漏，或其他非因本公司原因导致的个人隐私信息的泄露；"
        ),
        _c("br"),
        _vm._v(" 3.5.3 用户自行向第三方公开其个人隐私信息；"),
        _c("br"),
        _vm._v(
          " 3.5.4 用户与本公司及合作单位之间就用户个人隐私信息的使用公开达成约定，本公司因此向合作单位公开用户个人隐私信息；"
        ),
        _c("br"),
        _vm._v(
          " 3.5.5 任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露；"
        ),
        _c("br"),
        _vm._v(
          " 3.5.6 本公司为向用户提供产品、服务、信息而向第三方提供的，包括本公司通过第三方的技术及服务向用户提供产品、服务、信息的情况；"
        ),
        _c("br"),
        _vm._v(
          " 3.5.7 本公司为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；"
        ),
        _c("br"),
        _vm._v(" 3.5.8 本公司解决举报事件、提起诉讼而提交的；"),
        _c("br"),
        _vm._v(" 3.5.9 用户同意向第三方提供的。"),
        _c("br"),
        _vm._v(" 3.6 用户同意本公司可在以下事项中使用用户的个人隐私信息："),
        _c("br"),
        _vm._v(
          " 3.6.1 本公司向用户及时发送重要通知，如软件更新、本协议条款的变更；"
        ),
        _c("br"),
        _vm._v(
          " 3.6.2 为维护本公司的著作权、商标权、专利权及其他任何合法权利或权益；"
        ),
        _c("br"),
        _vm._v(
          " 3.6.3 将用户数据用于统计，以便向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述本公司的服务；"
        ),
        _c("br"),
        _vm._v(" 3.6.4 适用法律法规规定的其他事项。"),
        _c("br"),
        _vm._v(
          " 除上述事项外，如未取得用户事先同意，本公司不会将用户个人隐私信息使用于任何其他用途。"
        ),
        _c("br"),
        _vm._v(" 四、不可抗力与法律责任"),
        _c("br"),
        _vm._v(
          " 4.1 在使用本服务的过程中，可能发生本公司不可抗力的事由，导致本服务无法继续。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，本公司将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，本公司及合作单位在法律允许的范围内免责。"
        ),
        _c("br"),
        _vm._v(
          " 4.2 本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，本公司不承担任何责任。"
        ),
        _c("br"),
        _vm._v(
          " 4.4 本公司依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成本公司的义务或承诺，本公司不能保证及时发现违法违规或违约行为或进行相应处理。"
        ),
        _c("br"),
        _vm._v(
          " 4.5 因用户违反本协议或其他有效的服务条款的约定，而导致任何第三方主张任何索赔、要求或损失的，用户同意负担全部赔偿责任，并使本公司及/或其关联公司、合作伙伴免受任何赔偿责任。"
        ),
        _c("br"),
        _vm._v(
          " 4.6 用户理解并同意，自主选择下载和／或使用本软件和所有与本软件一同提供或通过本软件提供的第三方软件所产生的风险，由用户自行承担。对因下载或使用本软件和此类第三方软件而对计算机系统造成的损坏或数据的丢失，应由用户承担全部责任。"
        ),
        _c("br"),
        _vm._v(" 五、服务的变更、中断、终止"),
        _c("br"),
        _vm._v(
          " 5.1 鉴于网络服务的特殊性，用户同意本公司有权随时变更、中断或终止部分或全部的服务（包括收费服务）。本公司应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务。"
        ),
        _c("br"),
        _vm._v(
          " 5.2 如发生下列任何一种情形，本公司有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任："
        ),
        _c("br"),
        _vm._v(
          " 5.2.1 根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；"
        ),
        _c("br"),
        _vm._v(" 5.2.2 用户违反相关法律法规或本协议的约定；"),
        _c("br"),
        _vm._v(" 5.2.3 按照法律规定或有权机关的要求；"),
        _c("br"),
        _vm._v(" 5.2.4 出于安全的原因或其他必要的情形。"),
        _c("br"),
        _vm._v(
          " 5.3 本公司未行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单一或部分行使其在本协议项下的任何权利并不排斥其任何其它权利的行使。本公司随时有权要求您继续履行义务并承担相应的违约责任。"
        ),
        _c("br"),
        _vm._v(
          " 六、用户保证 6.1 用户承诺并保证自己是具有完全民事行为能力和完全民事权利能力的自然人、法人、实体和组织。用户在此保证所填写的用户信息是真实、准确、完整、及时的，并且没有任何引人误解或者虚假的陈述，且保证本公司可以通过用户所填写的联系方式与用户取得联系。限制行为能力人应在法定代理人陪同下阅读本协议并取得法定代理人对本协议全部内容，以及限制行为能力人依据本协议接受服务和支付费用的全部行为的同意；无行为能力人除非依法由法定代理人进行代理，否则不得接受本协议约定的任何服务。除非用户接受本协议所有条款，否则用户无权下载、安装或使用本软件及其相关服务。"
        ),
        _c("br"),
        _vm._v(
          " 6.2 用户应根据本公司对于本软件及相关服务的要求及时提供相应的身份证明等资料，否则本公司有权拒绝向该会员或用户提供相关服务。"
        ),
        _c("br"),
        _vm._v(" 6.3 用户承诺将及时更新其用户信息以维持该等信息的有效性。"),
        _c("br"),
        _vm._v(
          " 6.4 如果用户提供的资料或信息包含有不正确、不真实的信息，本公司保留取消用户会员资格并随时结束为该用户提供服务的权利。"
        ),
        _c("br"),
        _vm._v(
          " 6.5 以代理人身份代理其他自然人或者单位进行注册用户必须向本公司提供代理人和被代理人的详细资料和信息及授权书面文件，未向本公司提供上述资料信息及文件的，本公司将视注册者为会员。"
        ),
        _c("br"),
        _vm._v(
          " 6.6 用户充分理解本公司将通过电子邮件或手机短信的方式与注册会员保持联络及沟通，用户在此同意本公司通过电子邮件或手机短信方式向其发送包括会员信息、本软件及服务信息在内的相关商业及非商业联络信息。"
        ),
        _c("br"),
        _vm._v(" 七、知识产权声明"),
        _c("br"),
        _vm._v(
          " 7.1 本软件由本公司提供。本软件的一切版权、商标权、专利权、商业秘密等知识产权，以及与软件相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料、或电子文档等均受中华人民共和国的著作权法、商标法、专利法、反不正当竞争法和相关国际条约以及其他知识产权法律法规的保护，除涉及第三方授权的软件或技术外，本公司享有上述知识产权。"
        ),
        _c("br"),
        _vm._v(
          " 7.2 未经本公司事先明示的同意，用户不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识产权，本公司保留追究上述未经许可行为之法律责任的权利。"
        ),
        _c("br"),
        _vm._v(
          " 7.3 在遵守本协议及已经发布的或将来可能发布/更新的规则的前提下，用户可以在单一一台终端设备上安装、使用、显示、运行本软件的一份副本。用户不得对本软件或者本软件运行过程中释放到任何计算机终端内存中的数据及本软件运行过程中客户端与服务器端的交互数据进行复制、更改、修改、挂接运行或创作任何衍生作品，包括但不限于使用非本公司提供的插件、外挂或非经授权的第三方工具/服务接入本软件和相关系统。"
        ),
        _c("br"),
        _vm._v(
          " 7.4 保留权利：本协议中未明示授权给用户的其他一切权利，仍归本公司所有。"
        ),
        _c("br"),
        _vm._v(
          " 7.5 内容所有权：本软件及相应服务中的文字、软件、声音、照片、视频、图表，以及在广告中的全部内容，均属于本公司及相关权利人所有，并受法律的保护。用户仅能在本公司的授权下使用这些内容，而不得擅自复制或以任何其他形式进行使用。"
        ),
        _c("br"),
        _vm._v(" 八、用户的权利和义务"),
        _c("br"),
        _vm._v(
          " 8.1请您妥善保管好您的账户密码，切勿告知他人，同一账号多人在线可能会影响您的正常使用。因私自告诉他人密码造成的账户损失，用户需自行承担。"
        ),
        _c("br"),
        _vm._v(
          " 8.2 用户有权利享受本软件提供的互联网技术和信息服务，并有权利在接受本公司提供的服务时获得本公司的技术支持、咨询等服务，服务内容详见相关产品介绍。"
        ),
        _c("br"),
        _vm._v(
          " 8.3 用户保证不会利用技术或其他手段破坏及扰乱本公司网站以及其他合作客户的网站。"
        ),
        _c("br"),
        _vm._v(
          " 8.4 用户应尊重本公司及其他第三方的知识产权和其他合法权利，并保证在发生上述事件时尽力保护本公司及其雇员、合作伙伴等免于因该等事件受到影响或损失；本公司保留用户侵犯本软件知识产权时终止向该用户提供服务并不退还任何款项、追究赔偿的权利。"
        ),
        _c("br"),
        _vm._v(
          " 8.5 对由于用户向本公司提供的联络方式有误以及用户用于接受本公司邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，用户应自行承担责任，包括但不限于因用户未能及时收到本软件的相关通知而导致的后果和损失。"
        ),
        _c("br"),
        _vm._v(
          " 8.6 用户保证其使用本软件服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用本软件提供的服务进行存储、发布、传播如下信息和内容：违反国家法律法规政策的任何内容（信息）；违反国家规定的政治宣传和/或新闻信息；涉及国家秘密和/或安全的信息；封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息;博彩有奖、赌博游戏;违反国家民族和宗教政策的信息；防碍互联网运行安全的信息；侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容。用户同时承诺不得为他人发布上述不符合国家规定和/或本服务条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等。用户承认本公司有权在用户违反上述时有权终止向用户提供服务并不予退还任何款项，因用户上述行为给本公司造成损失的，用户应予赔偿。"
        ),
        _c("br"),
        _vm._v(
          " 8.7 用户在此明确同意，下列行为均为严重损害本公司权益的行为，如用户实施了下列行为中的一项或多项，本公司有权在不通知用户的情况下，终止向其提供付费会员服务，并有权限制、冻结或删除相应的软件帐号或清空其权益。就此本公司无须给予任何补偿或退费，由此产生的全部责任由用户自行承担。如因实施系列行为给本公司或第三方造成其他损失的，应由用户负责全额赔偿："
        ),
        _c("br"),
        _vm._v(" 8.7.1 以营利为目的，为自己或他人获得付费会员服务；"),
        _c("br"),
        _vm._v(
          " 8.7.2 将付费会员帐号以出租、出借、出售等任何形式提供给第三方使用；"
        ),
        _c("br"),
        _vm._v(
          " 8.7.3 将通过付费会员服务获得的任何内容用于个人学习、研究或欣赏之外的用途；"
        ),
        _c("br"),
        _vm._v(" 8.7.4 盗用他人帐号进行付费会员注册或使用；"),
        _c("br"),
        _vm._v(
          " 8.7.5 以任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件或其它非本公司认可的方式获得付费会员服务；"
        ),
        _c("br"),
        _vm._v(
          " 8.7.6 通过不正当手段或以违反诚实信用原则的行为获得付费会员服务。"
        ),
        _c("br"),
        _vm._v(
          " 8.8 用户在遵守法律及本协议的前提下可依本协议使用本软件。用户无权实施包括但不限于下列行为："
        ),
        _c("br"),
        _vm._v(
          " 8.8.1 通过非本公司开发、授权或认可的第三方兼容软件、系统登录或使用本公司软件及服务，或针对本公司的软件和服务使用非本公司开发、授权或认证的插件；"
        ),
        _c("br"),
        _vm._v(" 8.8.2 删除本软件及其他副本上任何关于版权的信息、内容；"),
        _c("br"),
        _vm._v(" 8.8.3 对本软件进行反向工程、反向汇编、反向编译等；"),
        _c("br"),
        _vm._v(
          " 8.8.4 对本软件相关信息，未经本公司事先明示同意，擅自实施包括但不限于下列行为：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助本软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等。"
        ),
        _c("br"),
        _vm._v(
          " 8.8.5 进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐号；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，实施或企图实施探查、扫描、测试本软件系统或网络的弱点或其它可能破坏网络安全的行为；实施或企图实施干涉、破坏本软件系统或网站的正常运行，传播恶意程序或病毒以及其他可能破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；"
        ),
        _c("br"),
        _vm._v(
          " 8.8.6 其他以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用本软件和本公司提供的其他服务；"
        ),
        _c("br"),
        _vm._v(
          " 用户若违反上述约定，本公司有权采取终止、完全或部分中止、完全或部分限制本软件使用功能的处理。"
        ),
        _c("br"),
        _vm._v(
          " 8.9 对于用户违法或违反本协议的约定使用而引起的一切责任，由用户负全部责任，一概与本公司及合作单位无关；如因此导致本公司及其关联公司、合作伙伴受到损失的，用户应当进行赔偿。同时本公司有权根据用户的行为性质，在不事先通知用户的情况下，采取包括但不限于中断使用许可、停止提供服务、限制使用、删除用户帐号、追究法律责任等措施。"
        ),
        _c("br"),
        _vm._v(" 九、争议解决及法律适用"),
        _c("br"),
        _vm._v(
          " 本协议的订立、履行、解释及争议的解决均适用中华人民共和国法律并排除其他一切的冲突法的适用。本协议签订于广东省佛山市南海区。如双方就本协议的内容或其执行发生任何争议（包括但不限于合同纠纷或者其他财产性权益纠纷），双方应友好协商解决；协商不成时，双方同意将争议交由本协议签订地有管辖权的人民法院管辖并处理。"
        ),
        _c("br"),
        _vm._v(" 十、附则"),
        _c("br"),
        _vm._v(
          " 10.1 本公司和/或其关联公司、合作伙伴将根据市场与技术的发展，向用户提供与本软件相关的各种互联网以及移动通信增值服务，包括免费和收费的增值服务。本公司和/或其关联公司、合作伙伴保留对相关增值服务收取费用及改变收费标准、方式的权利；如相关服务由免费变更为收费服务，本公司和/或其关联公司、合作伙伴将以适当的形式进行通知，用户可自主选择接受或拒绝该等收费服务。"
        ),
        _c("br"),
        _vm._v(
          " 10.2 本公司有权在服务中或通过服务投放各种广告和宣传信息，任何这类促销方法，包括运输货物、付款、服务、商业条件、担保及与广告有关的描述都只是在相应的用户和广告销售商之间发生。本公司不承担任何责任，本公司没有义务为这类广告销售负任何一部分的责任。"
        ),
        _c("br"),
        _vm._v(
          " 10.3 若用户对本软件或本软件相关服务有任何意见或改进的建议，均可向本公司提出。请注意，如果您这样做，还会授予本公司和第三方在本软件及本公司提供的其他软件中无偿使用和加入您的意见或建议的权利。"
        ),
        _c("br"),
        _vm._v(
          " 10.4 本注册条款中有关条款若被权威机构认定为无效，不应影响其他条款的效力，也不影响本服务条款的解释、违约责任及争议解决的有关约定的效力。"
        ),
        _c("br"),
        _vm._v(
          " 10.5 一方变更通知、通讯地址或其他联系方式，应自变更之日起及时将变更后的地址、联系方式通知另一方，否则变更方应对此造成的一切后果承担责任。用户同意，本公司的有关通知只需在本公司有关网页上发布即视为送达用户。"
        ),
        _c("br"),
        _vm._v(
          " 10.6因本公司上市、被收购、与第三方合并、名称变更等事由，用户同意本公司可以将其权利和/或义务转让给相应的本软件权利/义务的承受者。"
        ),
        _c("br"),
        _vm._v(
          " 10.7 本公司不行使、未能及时行使或者未充分行使本条款约定或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响本公司在将来行使该权利。"
        ),
        _c("br")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }